import { ChatEngine } from 'react-chat-engine';
import styled from 'styled-components/macro';

const StyledChat = styled.div`
  font-size: 1.6rem;
`;

const Chat = () => {
  return (
    <StyledChat>
      <ChatEngine height="80vh" projectID="e3a487f0-c4ff-4926-a252-7d0428954fe9" userName="User 1" userSecret="12345" />
    </StyledChat>
  );
};

export default Chat;
