import api from '../../../../../../utils/api';

const NOTIFICATIONS_LIST_SUCCESS = 'NOTIFICATIONS_LIST_SUCCESS';

export const loadNotifications = () => async (dispatch) => {
  try {
    const { data } = await api.get('/notifications');
    // setNotifications(data.notifications);

    dispatch({
      type: NOTIFICATIONS_LIST_SUCCESS,
      payload: {notifications: data},
    });
  } catch (err) {
    alert(err);
  }
};

export const clearNotifications = () => async (dispatch) => {
  try {
    const { data } = await api.delete('/notifications');

    dispatch({type:"NOTIFICATIONS_LIST_SUCCESS", payload:{notifications: []}});
  } catch (err) {
    alert(err);
  }
}
