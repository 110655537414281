import api from "../../../../../../../../utils/api"
import axios from "axios"

export const fetchMessages = async ({caseData, fromToken}) => {
    if(fromToken)
    {
        const {data} = await axios.get('api/messages/'+caseData._id,{headers:{"Authorization": fromToken}})
        return data
    }
    else
    {
        const {data} = await api.get('/messages/'+caseData._id)
        return data
    }
}

export const sendMessage = async ({message, caseId, fromToken}) => {
    if(fromToken)
    {
        const {data} = await axios.post('api/messages/'+caseId, {text: message},{headers:{"Authorization": fromToken}})
        return data.dateTime
    }
    else
    {
        const {data} = await api.post('/messages/'+caseId, {text: message})
        return data.dateTime
    }
}

export const loadProfileImage = async (handlerId) => {
    try
    {
        const {data} = await api.get('/users/'+handlerId+'/profile-image', {"responseType": "blob"})
        let urlCreator = window.URL || window.webkitURL
        return urlCreator.createObjectURL(data)
    }
    catch(err)
    {
        return null
    }
}