import api from '../../../../../../../../utils/api'
import axios from 'axios'

export const getHandlers = async ({caseId, fromToken}) => {
    if(fromToken)
    {
        let {data} = await axios.get('api/report-cases/'+caseId+'/handlers',{headers:{"Authorization": fromToken}})
        return data
    }
    else
    {
        let {data} = await api.get('/report-cases/'+caseId+'/handlers')
        return data
    }
}

export const updateAccessStatus = async({caseId, accessId, accessCode, accountId}) => {
    let {data} = await api.patch('/report-cases/handlerAccess',{caseId, accessId, accessCode, accountId})
    return data
}