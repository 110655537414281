import styled from 'styled-components/macro';

export const StyledDatePicker = styled.div`
  display: flex;
  flex-direction: row;
  /* background: #f4f5f9; */
  border-radius: 12px;
  left: 0;

  > * {
    cursor: pointer;
  }

  .DateRangePicker {
    display: flex;
    flex-direction: row;
  }

  .DateRangePicker_picker__directionLeft {
    left: -300% !important;
  }

  .buttonContainer {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    background: #fcfcfc;
    box-shadow: 0px 7px 21px rgba(81, 81, 81, 0.13);
    border-radius: 12px;

    svg,
    i {
      margin: 0.2rem 1.6rem;
    }

    /*svg.DateRangePickerInput_arrow_svg {
      display: none;
    }*/

    i {
      color: #d3d6e4;
    }

    .text-box {
      padding: 0.8rem 0;

      span {
        &:nth-child(1) {
          font-weight: 600;
          font-size: 1.5rem;
        }
        &:nth-child(2) {
          display: block;
          font-size: 1.2rem;
        }
      }
    }
  }

  .DateRangePickerInput {
    display: flex;
    align-items: center;
    border: none;

    .DateInput {
      background: transparent;
      width: fit-content;
      max-width: 70px;

      .DateInput_input {
        padding: 0rem !important;
      }

      .DateInput_input__focused {
        border: none;
      }

      input {
        font-size: 1.23rem;
        background: transparent;
        width: fit-content;
      }
    }
  }

  .DateRangePickerInput_arrow_1 {
    margin-right: 1rem;
  }

  .DayPickerNavigation_button__default {
    border: none;
  }

  .DayPicker__withBorder {
    border-radius: 23px;
  }

  .CalendarDay__default {
    border: none;
  }

  button.DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }

  .CalendarDay__selected {
    background: ${(props) => props.theme.colors.primary} !important;
    border-radius: 50%;
  }

  .CalendarDay__selected_span {
    background: ${(props) => props.theme.colors.primary} !important;
    opacity: 0.7;
    border: none !important;
  }

  /* // NOTE: the order of these styles DO matter */

  /* // Will edit everything selected including everything between a range of dates */
  .CalendarDay__selected_span {
    background: #82e0aa;
    /* color: white; */
    border: 1px solid pink;
  }

  /* // Will edit selected date or the endpoints of a range of dates */
  .CalendarDay__selected {
    position: relative;
    background: #ddecff;
    color: white;

    /* 
    &:before {
      position: absolute;
      top: 0;
      right: -50%;
      content: '';
      width: 100%;
      height: 100%;
      background: #ddecff;
      z-index: -1;
    } */
  }

  /* // Will edit when hovered over. _span style also has this property */
  .CalendarDay__selected:hover {
    background: ${(props) => props.theme.colors.primary} !important;
    color: white;
  }

  /* // Will edit when the second date (end date) in a range of dates
 // is not yet selected. Edits the dates between your mouse and said date */
  /*.CalendarDay__hovered_span:hover,*/
  .CalendarDay__hovered_span {
    background: ${(props) => props.theme.colors.primary} !important;
    color: white;
  }
`;
