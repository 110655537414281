import React, {useState} from 'react';
import { Form, TextField, SubmitWrapper } from '../../../gui/input/Form';
import { MainButton } from '../../../gui/input/MainButton';
import { Card } from '../../../gui/layout/Card';
import { Col } from '../../../gui/layout/Col';
import { Header } from './Header';
import { getCaseByToken } from './actions';
import { CaseInfoComponent } from '../CommonToAllPages/AllCases/CaseInfo';

export const Step4 = () => {

  const [reportCase, setReportCase] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const onSubmit = async (values) => {
    setErrorMessage('')
    try
    {
      let cases = await getCaseByToken(values.token)
      setReportCase(cases)
    }
    catch(e)
    {
      console.log(e)
      setErrorMessage(e.message)
    }
  }

  if(reportCase)
  {
    return (
      <div style={{ background:'#F9F9F9', height:'100vh'}}>
        <Header />
        <CaseInfoComponent loadedPageData={reportCase} fromToken={reportCase.jwt}/>
      </div>
    )
    
  }

  return (
    <div style={{ background:'#F9F9F9', height:'100vh'}}>
      <Header />
      <Form height='65vh' onSubmit={onSubmit}>
        <Col width='60rem' margin='auto'>
          <Card>
            <h2>Follow up on report</h2>
            <p style={{color:'#555555'}}>Enter the token below to follow up on a report.</p>
            <br/>
            {errorMessage !== '' && <p style={{color: "red"}}>{errorMessage}</p>}
            <TextField name="token" label=" " width='60%' placeholder="Enter token" />
            <SubmitWrapper><MainButton>Submit</MainButton></SubmitWrapper>
          </Card>
        </Col>
      </Form>
    </div>
  );
};
