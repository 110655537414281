import api from "../../../../../utils/api"

export const loadSettings = async () => {
    const {data} = await api.get('/organizations/settings')
    return data
}

export const saveSettings = async (values) => {
    let formData = new FormData();
    
    formData.append("VAT",values.VAT);
    formData.append("address",values.address);
    formData.append("bilingCountry",values.bilingCountry);
    formData.append("billingAddress",values.billingAddress);
    formData.append("billingCity",values.billingCity);
    formData.append("billingEmail",values.billingEmail);
    formData.append("billingName",values.billingName);
    formData.append("billingZip",values.billingZip);
    formData.append("city",values.city);
    formData.append("country",values.country);
    formData.append("email",values.email);
    if(values.logo){ formData.append('logo', values.logo[0]) };
    formData.append("name",values.name);
    formData.append("phone",values.phone);
    formData.append("requireMachineTransaltionTwoFactorAuth",values.requireMachineTransaltionTwoFactorAuth);
    formData.append("requireMultiplePeopleToDelete",values.requireMultiplePeopleToDelete);
    formData.append("requireTwoFactorAuth",values.requireTwoFactorAuth);
    formData.append("zip",values.zip);
    formData.append("reportLink", values.reportLink);

    const {data} = await api.put('/organizations/settings', formData, {})
    return data
}

export const getOrganizationImage = async () => {
    try
    {
        const {data} = await api.get('/organizations/logo', {"responseType": "blob"})
        let urlCreator = window.URL || window.webkitURL
        return urlCreator.createObjectURL(data)
    }
    catch(err)
    {
        return null
    }
}