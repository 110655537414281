import styled from 'styled-components';

export const StyledProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* min-height: 78vh; */

  img {
    display: block;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    object-fit: cover;
  }

  form {
    max-width: 350px;
    margin: auto;
    padding: 2rem;

    label {
      margin-right: auto;
    }

    input {
      border: none;
      margin-right: auto;
      margin-left: 4rem;

      &:focus {
        outline: none;
      }
    }
  }
  hr {
    width: 100%;
    border: 0.5px solid #d6d6d6;
  }

  .form-error-banner {
    background-color: #861724;
    color: white;
    font-weight: bold;
    padding:0.6rem 1rem;
    border-radius: 0.9rem;
    font-size: 1rem;
  }

  .form-success-banner {
    background-color: #027D6A;
    color: white;
    font-weight: bold;
    padding: 0.6rem 1rem;
    border-radius: 0.9rem;
    font-size: 1rem;
  }
`;
