import React from 'react';
import styled from 'styled-components';

const StyledProgressSteps = styled.div`
  text-align: center;

  .progress-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    position: relative;
    margin-bottom: 30px;
    max-width: 100%;
    width: 15px;
    height: 300px;
  }

  .progress-container::before {
    content: '';
    background-color: #dddddd;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    /* transform: rotate(90deg); */
    width: 2px;
    height: 100%;
    z-index: 1;
  }

  .progress {
    background-color: #dddddd;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 4px;
    width: 0%;
    z-index: 1;
    transition: 0.4s ease;
  }

  .circle {
    background-color: #fff;
    color: #999;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${(props) => props.theme.colors.primary};
    transition: 0.4s ease;
    z-index: 2;
  }

  .circle.active {
    border-color: ${(props) => props.theme.colors.primary};
  }

  .btn {
    background-color: ${(props) => props.theme.colors.primary};
    color: #fff;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    font-family: inherit;
    padding: 8px 30px;
    margin: 5px;
    font-size: 14px;
  }

  .btn:active {
    transform: scale(0.98);
  }

  .btn:focus {
    outline: 0;
  }

  .btn:disabled {
    background-color: var(--line-border-empty);
    cursor: not-allowed;
  }
`;

export const ProgressSteps = ({ showStepNumbers, withNavButtons }) => {
  return (
    <StyledProgressSteps>
      <div className='progress-container'>
        <div className='progress' id='progress'></div>
        <div className='circle active'>{showStepNumbers && 1}</div>
        <div className='circle'>{showStepNumbers && 2}</div>
        <div className='circle'>{showStepNumbers && 3}</div>
        <div className='circle'>{showStepNumbers && 4}</div>
      </div>

      <div>
        <p className='bold'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, corporis.</p>
        <span>2 March 2021, 13:45 PM</span>
      </div>

      {withNavButtons && (
        <>
          <button className='btn' id='prev' disabled>
            Prev
          </button>
          <button className='btn' id='next'>
            Next
          </button>
        </>
      )}
    </StyledProgressSteps>
  );
};
