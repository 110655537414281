import React from 'react';
import styled from 'styled-components/macro';
import { ProgressSteps } from './ProgressSteps';
import { StyledLatestActivity } from './Style';

export const StyledList = styled.div`
  min-width: 900px;
  min-width: fit-content;
  font-size: 80%;

  .li-wrapper {
    position: relative;
    border-left: solid 2px #dddddd;
    padding-left: 1.6rem;
    padding-bottom: 3rem;

    &:last-of-type {
      border-left: none;
      padding-bottom: 0;
    }

    &:first-of-type {
      .circle {
        background: #418eeb;
      }

      p.main-item {
        font-weight: bold;
      }
    }
  }

  .li-last {
    position: relative;
    padding-left: 2rem;
  }

  .circle {
    position: absolute;
    left: -6.5px;
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background: white;
    border: solid 2px #418eeb;
    border-radius: 50%;
  }

  .circle-last {
    left: -6px;
  }

  .myborder {
    display: inline-block;
    width: 100px;
    height: 100px;
    background: red;
  }

  p {
    margin: 0;
    transform: translateY(-4px);

    &.timestamp {
      color: #a5a5a5;
      /* font-size: 1rem; */
    }
  }
`;

export const LatestActivity = ({data, buildLatestActivityTime}) => {
  return (
    <StyledLatestActivity>
      <h2>Latest Activity</h2>
      <StyledList>
        {data.map(activity => 
          <div key={activity._id} className="li-wrapper">
            <div className="circle"></div>
            <p className="main-item">{activity.text}</p>
            <p className="timestamp">{buildLatestActivityTime(activity.dateTime)}</p>
          </div>
        )}
      </StyledList>

      {/*<ProgressSteps/>*/}
    </StyledLatestActivity>
  );
};
