import styled from 'styled-components';

export const StyledDepartments = styled.div`

    button{
        display:flex;
        align-items:center;

        svg{
           width: 1.1rem;
           height:1.1rem;
        }
    }
`;
