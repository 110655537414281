import React, { useState, useMemo } from 'react';

import { H1 } from '../../../../gui/elements/H1';
import { MainButton } from '../../../../gui/input/MainButton';
import { SearchBar } from '../../../../gui/input/SearchBar';
import { Select } from '../../../../gui/input/Select';
import { ToggleSwitchWithText } from '../../../../gui/input/ToggleSwitchWithText';
import { RightPanelModal } from '../../../../gui/layout/RightPanelModal';
import { Row } from '../../../../gui/layout/Row';
import { AllCasesTable } from './AllCasesTable';
import { UploadRectangle } from '../../../../gui/input/UploadRectangle';
import { FormGroup } from '../../../../gui/input/Form(old)/FormGroup';
import { StyledAllCases } from './Style';
import { Flex } from '../../../../../App/theme/mixins';
import { getListOfCases } from '../../../../gui/layout/Table/actions';
import withActionPageLoader from '../../../../hoc/withActionPageLoader';
import { useEffect } from 'react';
import { AddNewCase } from './AddNewCase';
import { DefaultItem, DropdownSelect, Item } from '../../../../gui/input/Select/DropdownSelect';
import { DropdownItem } from '../../../../gui/input/Select/Style';
import { DatePickerField } from '../../../../gui/input/Form';
import { DatePicker } from '../../../../gui/input/date/DatePicker';
import { statusIcon } from '../../../../../App/theme/icons';
import {Loader} from '../../../../../App/AppComponents/Loader';
import { breachTypes } from '../../../../../data/reportCase';
import { useSelector } from 'react-redux';

export const AllCases = withActionPageLoader(getListOfCases, ({ loadedPageData, colHeaders, reloadPageData }) => {

  const authIdString = useSelector(state => state.auth._id.toString())
  //const [allCases, setAllCases] = useState(loadedPageData);
  const [viewNewCaseModal, setViewNewCaseModal] = useState(false);
  const [filters, setFilters] = useState({ statusCode: null, myCase: true, searchDate: null, caseType: () => true });

  const [myCasesToggle, setMyCasesToggle] = useState(true);

  const allCases = useMemo(() => {

    let weekOld = new Date();
    weekOld = new Date(weekOld.setDate(weekOld.getDate() - 7));

    let accessMap = {}
    loadedPageData.caseHandlers.forEach(row => {
      accessMap[row.reportCaseId.toString()] = true
    });

    let toReturn = []
    loadedPageData.reportCases.forEach(row => {
      let myCase = row.handlerId ? row.handlerId.toString() === authIdString : false
      if(!myCase && accessMap[row._id.toString()])
      {
        myCase = true
      }

      let d = new Date(row.createdDate);

      toReturn.push({ 
        ...row,
        searchDate: d.getFullYear() + '-' + d.getMonth() + '-' + d.getDate(),
        statusCode: (row.statusCode === "ONGOING" ? (d >= weekOld ? "NEW" : "ONGOING") : row.statusCode),
        myCase
      })
    });

    /*return loadedPageData.reportCases.map((row) => {
      let d = new Date(row.createdDate);

      return { 
        ...row,
        searchDate: d.getFullYear() + '-' + d.getMonth() + '-' + d.getDate(),
        statusCode: (row.statusCode === "ONGOING" ? (d >= weekOld ? "NEW" : "ONGOING") : row.statusCode),
        myCase: row.handlerId ? row.handlerId.toString() === authIdString : false
      };
    });*/

    return toReturn

  }, [loadedPageData]);

  const onStatusChanged = (newStatus) => {
    setFilters((prevState) => ({ ...prevState, statusCode: newStatus }));
  };

  const onMyCaseToggle = (value) => {
    setMyCasesToggle(value);
    if (value) {
      setFilters((prevState) => ({ ...prevState, myCase: true }));
    } else {
      setFilters((prevState) => ({ ...prevState, myCase: null }));
    }
  };

  const onDateChanged = (date) => {
    let searchDate = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
    setFilters((prevState) => ({ ...prevState, searchDate: searchDate }));
  };

  const onSearchChange = (searchFilter) => {
    setFilters((prevState) => {
      let newFilterFunc = null;
      searchFilter = searchFilter.trim().toLowerCase();

      if (searchFilter.length === 0) {
        newFilterFunc = (value) => true;
      } else {
        newFilterFunc = (value) => breachTypes[value.caseType]?.name.toLowerCase().startsWith(searchFilter);
      }

      return { ...prevState, caseType: newFilterFunc };
    });
  };

  return (
    <StyledAllCases>
      <div className="page-heading">
        <H1>All Cases</H1>
        <MainButton onClick={() => setViewNewCaseModal(true)}>+ New case</MainButton>


        <RightPanelModal height=" " shouldShow={viewNewCaseModal} onRequestClose={() => setViewNewCaseModal(false)}>
          <AddNewCase reloadPageData={reloadPageData}/>
        </RightPanelModal>
      </div>

      <Row>
        <SearchBar onChange={onSearchChange} />

        <div className="buttons-group">
          <ToggleSwitchWithText switchActive={!myCasesToggle} setSwitchActive={onMyCaseToggle} options={['My Cases', 'All Cases']} />

          <DropdownSelect type="all-status" HeaderStyle="fill" defaultValue={null} onChange={onStatusChanged}>
            <DefaultItem>{statusIcon}&nbsp;All Status</DefaultItem>
            <Item value={null}>{statusIcon}&nbsp;All Status</Item>
            <Item value="NEW">{statusIcon}&nbsp;New</Item>
            <Item value="ONGOING">{statusIcon}&nbsp;Ongoing</Item>
            <Item value="CLOSED">{statusIcon}&nbsp;Closed</Item>
            <Item value="DELETED">{statusIcon}&nbsp;Rejected</Item>
          </DropdownSelect>

          <DatePicker type="switched" onDateChanged={onDateChanged} align="right"/>
        </div>
      </Row>

      {/* Table */}

      <AllCasesTable loadedPageData={allCases} filters={filters} />
    </StyledAllCases>
  );
});
