import styled from 'styled-components/macro';

const StyledBody = styled.div`
  /* background: #1c1c1e; */
  color: ${({ theme }) => theme.colors.fadedText};
  width: 100%;
  /*max-width: 1200px;*/
  margin: auto;

  margin-top: 2rem;
`;

export const Body = ({ children }) => {
  return <StyledBody>{children}</StyledBody>;
};
