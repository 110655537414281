import React, {useState} from 'react';
import { MainButton } from '../../../../../../gui/input/MainButton';
import { GridRow } from '../../../../../../gui/layout/GridRow';
import { StyledList } from '../../../Dashboard/LatestActivity';
import { FlexContainer, MainContent } from '../Style';
import { loadLatestActivity } from './actions/historyActions';
import withActionPageLoader from '../../../../../../hoc/withActionPageLoader';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const History = withActionPageLoader(loadLatestActivity,({loadedPageData, caseId, fromToken}) => {

  const [showAll, setShowAll] = useState(false)

  const formatTime = (dateTime) => {
    let d = new Date(dateTime)
    return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}, ${d.getHours().toString().padStart(2,'0')}:${d.getMinutes().toString().padStart(2,'0')}`
  }

  const renderFirst5 = () => {
    let toReturn = []
    let entry = null
    for(let i=0;i< Math.min(loadedPageData.length, 5); i++)
    {
      entry =  loadedPageData[i]
      toReturn.push(
        <div key={entry._id} className="li-wrapper">
          <div className="circle"></div>
          <p className="main-item">{entry.text}</p>
          <p className="timestamp">{formatTime(entry.dateTime)}</p>
        </div>
      )
    }

    return toReturn
  }

  return (
    <MainContent>
      {!showAll && <StyledList>{renderFirst5()}</StyledList>}
      {showAll && 
        <StyledList>
          {loadedPageData.map(entry => (
            <div key={entry._id} className="li-wrapper">
              <div className="circle"></div>
              <p className="main-item">{entry.text}</p>
              <p className="timestamp">{formatTime(entry.dateTime)}</p>
            </div>
          ))}
        </StyledList>
      }

      {!showAll && loadedPageData.length > 5 && 
        <button style={{ marginLeft: '1.2rem', marginBottom: '3rem' }} className="bold blue" onClick={() => setShowAll(true)}>
          Load more &gt;&gt;
        </button>
      }
    </MainContent>
  );
});
