

export const formContent = {
    breachTypes:[
        {code: "ALREADY_TAKEN_PLACE", name: "Already taken place", text:'Breach which has already taken place'},
        {code: "NOT_YET_MATERIALIZED", name: "Not yet materialized", text:'Breach which has not yet materialized, but is very likely to take place'},
        {code: "CONSIDER_AS_BREACH", name: "Consider as a breach", text:'Act or omission which the reporting person has reasonable grounds to consider as a breach'},
        {code: "CONCEAL_ATTEMPT", name: "Attempt to conceal", text:'Attempt to conceal breaches'},
        {code: "SUSPICION", name: "Suspicion", text:'No positive evidence about a breach, but the reporting person raises reasonable concerns or suspicions'}
    ],
    breachCategories: [
        {code:'PUBLIC_PROCUREMENT', text:'Public procurement'},
        {code:'FINANCIAL', text:'Financial services, products and markets, and prevention of money laundering and terrorist financing'},
        {code:'PRODUCT_SAFETY', text:'Product safety and compliance'},
        {code:'TRANSPORT_SAFETY', text:'Transport safety'},
        {code:'ENVIRONMENT', text:'Protection of the environment'},
        {code:'RADIATION', text:'Radiation protection and nuclear safety'},
        {code:'FOOD_AND_ANIMAL', text:'Food and feed safety, animal health and welfare'},
        {code:'PUBLIC_HEALTH', text:'Public health'},
        {code:'CONSUMER_PROTECTION', text:'Consumer protection'},
        {code:'PRIVACY_AND_SECURITY', text:'Protection of privacy and personal data, and security of network and information systems'},
        {code:'OTHER', text:'Other'}
    ],
    policyAgreement: [
        'I declare I have reasonable grounds to believe, in light of the circumstances and the information available to me, that the matters reported by me are true and that they are not already fully available in the public domain or unsubstantiated rumors and hearsay.','I have read and agree to WhistLaw Platform – Data Protection Policy and agree to be contacted by the case handler in order to facilitate the resolution of my case.']
}
