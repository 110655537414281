import React,{useRef, useState} from 'react';
import { Table } from '../../../../gui/layout/Table';
import { PaginationWrapper } from '../../../../gui/controls/PaginationWrapper';
import {Pagination as PaginationControl} from '../../../../gui/layout/Pagination'
import { FilterWrapper } from '../../../../gui/controls/FilterWrapper';
import { TableLineItem } from '../../../../gui/layout/Table/TableLineItem';
import ArrowFilterManager from '../../../../../utils/ArrowFilterManager';
import { TableSortArrows } from '../../../../gui/layout/Table/TableSortArrows';
import { SortControl, SortWrapper } from '../../../../gui/controls/SortWrapper';
import useSortManager from '../../../../hooks/useSortManager';
import styled from 'styled-components';

const StyledAllCasesTable = styled.div`
  
`;

// export const AllCasesTable = withActionPageLoader(getListOfCases, ({ loadedPageData, colHeaders }) => {
export const AllCasesTable = ({ loadedPageData, filters = {} }) => {

  return (
    <StyledAllCasesTable>
      <FilterWrapper data={loadedPageData} filters={filters} dataProp="data">
        <SortWrapper sortKey="createdDate" ascending={false}>
          <PaginationWrapper PaginationControl={PaginationControl} dataProp="data" dataName="cases">
            
            <Table 
              colHeaders={[
                <>Breach Category<SortControl sortKey="category"><TableSortArrows /></SortControl></>,
                <>Breach Type<SortControl sortKey="breachType"><TableSortArrows /></SortControl></>,
                // <>Company<SortControl sortKey="company"><TableSortArrows /></SortControl></>,
                <>Created Date<SortControl sortKey="createdDate"><TableSortArrows /></SortControl></>,
                <>Created By<SortControl sortKey="createdBy"><TableSortArrows /></SortControl></>,
                <>Source<SortControl sortKey="source"><TableSortArrows /></SortControl></>,
                <>Status<SortControl sortKey="statusCode"><TableSortArrows /></SortControl></>,
                <>Action</>
              ]}
              LineItem={TableLineItem}/>
          </PaginationWrapper>
        </SortWrapper>
      </FilterWrapper>
    </StyledAllCasesTable>
  );
};
