import api from "../../../utils/api";

export const loadProfileImage = () => async (dispatch) => {
    try
    {
      const {data} = await api.get('/users/profile-image', {"responseType": "blob"})
      let urlCreator = window.URL || window.webkitURL
      dispatch({type:"LOAD_IMAGE", payload: {image: urlCreator.createObjectURL(data), loaded: true}})
    }
    catch
    {
        dispatch({type:"LOAD_IMAGE", payload: {image: null, loaded: true}})
    }
  }