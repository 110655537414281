import styled from 'styled-components';

export const StyledInvoice = styled.div`
  header {
    width: 100%;
    display: flex;
    align-items: flex-start;

    svg {
      width: 25%;
    }
    .receipt-heading {
      margin-left: auto;
      font-size: 6rem;
      color: #418eeb;
      font-weight: bold;
    }
  }
  label {
    margin-right: 2rem;
  }

  .xl {
    font-size: 2.6rem;
    font-weight: bold;
  }

  table {
    width: 100%;
    border-bottom: 1px solid #d6d6d6;
    th {
      background: black;
      color: white;
      font-weight: normal;
      padding: 1rem 1.7rem;
      text-align: left;
      border-collapse: remove;

      &:nth-of-type(1) {
      }
    }

    td {
      padding: 2rem 2.7rem;
      text-align: left;

      &:nth-of-type(1) {
        width: 25%;
      }
      &:nth-of-type(3) {
        width: 0;
      }
      &:nth-of-type(4) {
        width: 5%;
      }
      &:nth-of-type(5) {
        width: 5%;
      }
    }
  }
  .payment-info {
    h4 {
      color: #555555;
    }

    span {
      color: #adadad;

      &.emphasis-grey {
        color: #858585;
        font-weight: bold;
      }
    }
  }
`;
