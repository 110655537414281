import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { WhistlawLogoSvg } from "../../../../../App/theme/WhistlawLogoSvg"
import { LoaderThree } from "../../../../gui/elements/LoaderThree"
import { getCompanyLogo } from "../actions"

const StyledCompanyLogo = styled.div`
    .profile-img {
        width:${({width})=>width || '5rem'};
        height:${({height})=>height || '5rem'};
        border-radius: ${({borderRadius})=>borderRadius || '50%'};
    }
`


export const CompanyLogo = ({...props}) => {
    const params = useParams()
    const [logoImage, setLogoImage] = useState(null)
    const [loading, setLoading] = useState(true)

    const getLogo = async () => {
        if(params.publicLink)
        {
            let img = await getCompanyLogo(params.publicLink)
            setLogoImage(img)
            setLoading(false)
        }
        else
        {
            setLoading(false)
        }
    }

    useEffect(() => {
        getLogo()
    },[params])

    if(loading)
    {
        return <LoaderThree align="right" margin="0rem" color="#418EEB" size="3rem"/>
    }

    if(logoImage)
    {
        return (
            <StyledCompanyLogo {...props}>
                <img className="profile-img" src={logoImage} />
            </StyledCompanyLogo>
        )
    }

    return (
        <WhistlawLogoSvg />
    )
}