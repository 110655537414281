import styled from 'styled-components';
import { dfccc } from '../../../../App/theme/mixins';
import { Flex } from '../../../../App/theme/mixins';

export const StyledResetPassword = styled.div`
  /* ${Flex(['column', 'center', 'center'])}; */
  ${dfccc};
  /* {dfrss}
  {dfrce} */

  background-color: #f9faff;
  width: 100%;
  height: 100vh;
  /* 
  svg {
    padding-bottom: 6rem;
  } */

  .card {
    width: 30%;
    padding: 5rem 5rem;
    background: #ffffff;
    box-shadow: 0px 7px 21px rgba(0, 0, 0, 0.03);
    border-radius: 12px;

    h2 {
      font-size: 3rem;
      margin-top: 0.5rem;
      margin-bottom: 0;
    }

    p {
      color: grey;
      font-size: 1.2rem;
    }

    .formInput {
      position: relative;
      font-size: 1.5rem;
      border: 1px solid rgb(0, 56, 95);
      display: inline-block;
      display: flex;
      width: 100%;
      padding: 1rem;
      margin: 1rem auto;
      border-radius: 5px;
      background: #f3f4f6;
      border: 0;

      &.error {
        background: rgba(255, 227, 231, 0.7);
      }

      input {
        border: none;
        font-size: 1.3rem;
        width: min(40vw, 300px);
        outline: none;
        background: transparent;
        color: #555555;
      }
    }

    button {
      &:hover {
        background-color: rgb(7, 162, 229);
      }
    }

    .error-message {
      position: absolute;
      color: #861724;
      bottom: -58%;
      right: 0;

      & {
        bottom: -90% !important;
      }
    }
  }
`;
