import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SideNavigationMenu } from '../../../../App/theme/SideNavigationMenu';
import { Header } from '../../../../App/theme/Header';
import setAuthToken from '../../../../utils/setAuthToken';
import { useNavigate } from 'react-router-dom';
import { clearPageLoaderCache } from '../../../hoc/withActionPageLoader';

const StyledAppMainLayout = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 3%), 0 2px 2px rgb(0 0 0 / 3%), 0 4px 4px rgb(0 0 0 / 3%), 0 8px 8px rgb(0 0 0 / 3%);
  overflow: hidden;

  .whitlaw-banner {
    width: 100%;
    text-align: center;
    background: #FFEBE7;
    color: #FF6D4D;
    font-weight: 700;

    a {
      color: #418eeb;
    }
  }

  .right-panel {
    width: 100%;

    .page-content {
      height: 100%;
      padding: 3rem;
      background: #fbfbfb;
    }
  }
`;

// const Header = styled.div``;

export const AppMainLayout = ({ children, menuUi, setMenuUi, menuTabs, isLoggedIn }) => {

  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const accountType = useSelector(state => state.auth.accountType)

  const unmanage = () => {
    clearPageLoaderCache()
    setAuthToken(auth.whistlaw.token)
    dispatch({type:"UNMANAGE"})
    dispatch({type:"REMOVE_MANAGED_IMAGE"})
    navigate('/')
  }


  return (
    <StyledAppMainLayout>
      <SideNavigationMenu />
      {/* {isLoggedIn && (
        <>
          <Header>
            <ul>
              <li className={`${menuUi === 2 ? 'selected' : ''}`} onClick={() => setMenuUi(2)}>
                {menuTabs[0]}
              </li>
              <li className={`${menuUi === 1 ? 'selected' : ''}`} onClick={() => setMenuUi(1)}>
                {menuTabs[1]}
              </li>
              <li className={`${menuUi === 3 ? 'selected' : ''}`} onClick={() => setMenuUi(3)}>
                {menuTabs[2]}
              </li>
              <li className={`${menuUi === 4 ? 'selected' : ''}`} onClick={() => setMenuUi(4)}>
                {menuTabs[3]}
              </li>
            </ul>
          </Header> */}
      <div className="right-panel">
        <Header accountType={auth.accountType}/>
        {auth.whistlaw && <p className="whitlaw-banner">Logged into {auth.organization}, your modifications will appear as Whistlaw Admin. (<a href="#" onClick={unmanage}>Sign out</a>)</p>}
        <div className="page-content">{children}</div>
      </div>
      {/* </> */}
    </StyledAppMainLayout>
  );
};
