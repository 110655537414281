import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DatePicker } from '../../../../../../gui/input/date/DatePicker';
import { Form, DatePickerField, GridRow, SelectField, TextField, SubmitWrapper } from '../../../../../../gui/input/Form';
import { FormGroup } from '../../../../../../gui/input/Form(old)/FormGroup';
import { DropdownSearch, PinnedItem, Item } from '../../../../../../gui/input/Select/DropdownSearch';
import { DropdownSelect, Item as DropdownItem, DefaultItem } from '../../../../../../gui/input/Select/DropdownSelect';
import { formContent } from './formContent';
import { MainButton } from '../../../../../../gui/input/MainButton';

const sourceMap = {
  PLATFORM: 'Platform',
  EMAIL: 'Email',
  PHONE: 'Phone',
  MEETING: 'Meeting'
}

export const Step1 = ({ caseData, manager, formIndex, categories }) => {

  const auth = useSelector(state => state.auth)

  const onSubmit = (values) => {
    manager.setFormData(values)
    manager.setFormIndex(formIndex)
  }

  let isAnonymous = caseData.anonymity === "ANONYMOUS"

  return (
    <Form onSubmit={onSubmit}>
      <h3>New Case</h3>

      <FormGroup margin="1.5rem 0">
        <SelectField margin="0" name="breachType"  
          label={<div>Breach type <span className="red">*</span></div>} 
          placeholder="Select breach type"
          defaultValue={caseData.breachType || null}
          type="long-text"
        >
          <DropdownSelect>
            <DefaultItem>{caseData.breachType || 'Select Breach Type'}</DefaultItem>
            {formContent.breachTypes.map((value) => (
              <DropdownItem key={value.code} value={value.code}>
                {value.text}
              </DropdownItem>
            ))}
          </DropdownSelect>
        </SelectField>
      </FormGroup>
      

      <FormGroup margin="1.5rem 0">
        <SelectField margin="0" name="breachCategory" 
          label={<div>Breach category <span className="red">*</span></div>} 
          placeholder="Select breach category"
          defaultValue={caseData.breachCategory || null}
          type="long-text"
        >
          <DropdownSelect type='long-text'>
            <DefaultItem>{caseData.breachCategory || 'Select breach category'}</DefaultItem>
            {categories.map((value) => (
              <DropdownItem key={value._id} value={value._id}>
                {value.category}
              </DropdownItem>
            ))}
          </DropdownSelect>
        </SelectField>
      </FormGroup>


      <FormGroup margin="1.5rem 0">
        <SelectField margin="0" name="sourceCode" 
          label={<div>Reporting Method <span className="red">*</span></div>} 
          placeholder="Select reporting method"
          defaultValue={caseData.sourceCode || (auth.accountType === "CASE_HANDLER" ? null : "PLATFORM")}
          type="long-text"
        >
          <DropdownSelect type='long-text'>
            <DefaultItem>{sourceMap[caseData.sourceCode] || (auth.accountType === "CASE_HANDLER" ? 'Select Source' : 'Platform')}</DefaultItem>
            {auth.accountType !== "CASE_HANDLER" && <DropdownItem key="platform" value={'PLATFORM'}>Platform</DropdownItem>}
            <DropdownItem key="email" value={'EMAIL'}>Email</DropdownItem>
            {!isAnonymous &&
              <>
              <DropdownItem key="phone" value={'PHONE'}>Phone</DropdownItem>
              <DropdownItem key="Meeting" value={'MEETING'}>Meeting</DropdownItem>
              </>
            }
          </DropdownSelect>
        </SelectField>
      </FormGroup>


      <FormGroup>
        <SubmitWrapper>
          <MainButton margin="">Confirm</MainButton>
        </SubmitWrapper>
      </FormGroup>

    </Form>
  );
};
