import api from "../../../../../../../../utils/api"
import axios from "axios";

export const uploadMediaFile = async (mediaFile, caseId, fromToken) => {
    const formData = new FormData();
    formData.append('mediaFile', mediaFile);

    if(fromToken)
    {
        const {data} = await axios.post("api/report-cases/media/"+caseId, formData, {headers:{"Authorization": fromToken}})
        return data.newFile
    }
    else
    {
        const {data} = await api.post("/report-cases/media/"+caseId, formData, {})
        return data.newFile
    }
}

export const loadMedia = async ({caseData, fromToken=null}) => {
    if(fromToken)
    {
        const {data} = await axios.get("api/report-cases/media/"+caseData._id,
            {headers:{"Authorization": fromToken}}
        )
        return data
    }
    else
    {
        const {data} = await api.get("/report-cases/media/"+caseData._id)
        return data
    }
}

export const downloadMedia = async (mediaId, fromToken) => {
    if(fromToken)
    {
        const {data} = await axios.get('api/media/'+mediaId, {
            "responseType": "blob",
            "headers":{"Authorization": fromToken}
        })
        return data
    }
    else
    {
        const {data} = await api.get('/media/'+mediaId, {
            "responseType": "blob"
        })
        return data
    }
}