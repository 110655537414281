import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { WhistLawLongLogo } from '../../../../App/theme/icons';
import { FormGroup } from '../../../gui/input/Form(old)/FormGroup';
import { MainButton } from '../../../gui/input/MainButton';
import { StyledResetPassword } from './Style';

import { requestPassworResetEmail } from './actions';
import { Loader } from '../../../../App/AppComponents/Loader';

export const ResetStep1 = () => {
  const [sentTo, setSentTo] = useState(null);
  const [formError, setFormError] = useState('');
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },

    validationSchema: yup.object({
      email: yup.string().email().max(128).required(),
    }),

    onSubmit: async (values) => {
      setFormError('');
      setLoading(true);

      try {
        await requestPassworResetEmail(values.email);
        setSentTo(values.email);
      } catch (err) {
        setFormError(err.message);
      }

      setLoading(false);
    },
  });

  return (
    <StyledResetPassword>
      <WhistLawLongLogo width="250px" />
      {sentTo === null && (
        <div className="card">
          <h2>Forgot password?</h2>
          <p style={{ marginBottom: '3rem' }}>Enter your email and we will send you a link to reset your password.</p>

          <form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <label>Email Address</label>
              {formError !== '' && <p style={{ color: 'red' }}>{formError}</p>}
              <div className={`formInput ${formik.touched.email && formik.errors.email ? 'error' : ''}`}>
                <input type="text" placeholder="Enter your email" name="email" onChange={formik.handleChange} value={formik.values.email} onBlur={formik.handleBlur} />

                {formik.touched.email && formik.errors.email && (
                  <p className="error-message">
                    {/* {formik.errors.email} */}
                    This field is required
                  </p>
                )}
              </div>
            </FormGroup>

            {!loading && (
              <MainButton type="submit" width="100%">
                Submit
              </MainButton>
            )}
            {loading && <Loader />}

            {/* <button type="submit" style={{width: "100%", background: "lightblue"}}>Submit</button> */}
            <p>
              Back to{' '}
              <Link to="/login" className="bold blue">
                Login&gt;&gt;
              </Link>
            </p>
          </form>
        </div>
      )}
      {sentTo !== null && (
        <div className="card">
          <h2>Reset link sent</h2>
          <p>
            An email with the reset link was sent to <b>{sentTo}</b>. In case you don't see any emails, please also verify your junk or spam folder.
          </p>
          <p>
            Back to{' '}
            <Link to="/login" className="bold blue">
              Login&gt;&gt;
            </Link>
          </p>
        </div>
      )}
    </StyledResetPassword>
  );
};
