import { useMemo } from 'react';
import { Size } from 'devextreme-react/chart';
import PieChart, { Legend, Series, Tooltip, Format, Label, Connector, Export } from 'devextreme-react/pie-chart';

const data = [
  { source: 'Anonymous', value: '60' },
  { source: 'Confidential', value: '40' },
];

export const PrivacyBreakdown = ({data}) => {

  const chartData = useMemo(() => {
    let total = data["ANONYMOUS"] + data["CONFIDENTIAL"]
    if(total === 0)
    {
      return [
        { source: 'Anonymous', value: 0 },
        { source: 'Confidential', value: 0 }
      ]
    }

    return [
      { source: 'Anonymous', value: Math.round(100.0*data["ANONYMOUS"]/total) },
      { source: 'Confidential', value: Math.round(100.0*data["CONFIDENTIAL"]/total) }
    ]
  }, [data])

  return (
    <PieChart id="pie" type="doughnut" palette={['#1C829C', '#00A389']} dataSource={chartData}>
      <Size width={250} height={300} />
      <Series argumentField="source" valueField="value" innerRadius={0.78}>
        <Label visible={true} customizeText={(data) => data.value + '%'}>
          <Connector visible={true} />
        </Label>
      </Series>
      <Export enabled={false} />
      <Legend margin={0} horizontalAlignment="center" verticalAlignment="bottom" />
      {/*<Tooltip enabled={true} customizeTooltip={this.customizeTooltip}>
            <Format type="percent" />
            </Tooltip>*/}
    </PieChart>
  );
};
