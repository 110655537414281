import React, { useState, useMemo } from 'react';
import { MainButton } from '../../../../../../gui/input/MainButton';
import { GridRow } from '../../../../../../gui/layout/GridRow';
import { FlexContainer, MainContent } from '../Style';
import { SearchSelect } from '../../../../../../gui/input/SearchSelect';
import { ControlledModal } from '../../../../../../gui/layout/ControlledModal';
import { RightPanelModal } from '../../../../../../gui/layout/RightPanelModal';
import { Col } from '../../../../../../gui/layout/Col';
import { FormGroup } from '../../../../../../gui/input/Form(old)/FormGroup';
import { Select } from '../../../../../../gui/input/Select';
import { DatePicker } from '../../../../../../gui/input/date/DatePicker';
import { ManageCaseForm } from './ManageCaseForm';
import { Row } from '../../../../../../gui/layout/Row';
import {resolveCase, deleteCase} from './actions/caseActions'
import { useNavigate } from 'react-router-dom';
import {Loader} from "../../../../../../../App/AppComponents/Loader"
import { clearPageLoaderCache } from '../../../../../../hoc/withActionPageLoader';
import { breachCategories, breachTypes } from '../../../../../../../data/reportCase';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const formatDate = (inputDate) => {
  let date = new Date(inputDate);
  let half = 'AM';
  let hours = date.getHours();
  if (hours === 0) {
    hours = 12;
  } else if (hours === 12) {
    half = 'PM';
  } else if (hours > 12) {
    hours -= 12;
    half = 'PM';
  }

  return date.getDate().toString().padStart(2, '0') + ' ' + months[date.getMonth()] + ' ' + date.getFullYear() + ', ' + hours.toString().padStart(2, '0') + ':' + date.getMinutes() + ' ' + half;
};

const statusCodeMap = { NEW: 'New', ONGOING: 'Ongoing', CLOSED: 'Closed', DELETED: 'Rejected'};
const sourceCodeMap = { PHONE: 'Phone', EMAIL: 'Email', SYSTEM: 'System', PLATFORM: 'Platform' };
const confidentialityMap = {CONFIDENTIAL: 'Confidential', ANONYMOUS: 'Anonymous'};
const caseTypeCodeMap = { FRAUD: 'Fraud' };

export const MainInfo = ({ caseData, fromToken=false }) => {

  const navigate = useNavigate();

  const [manageCaseModalView, setManageCaseModalView] = useState(false);
  const [resolveCaseModalView, setResolveCaseModalView] = useState(false);
  const [trashCaseModalView, setTrashCaseModalView] = useState(false)
  const [resolveSpinner, setResolveSpinner] = useState(false)

  const statusCode = useMemo(() => {
    let weekOld = new Date();
    weekOld = new Date(weekOld.setDate(weekOld.getDate() - 7));

    let d = new Date(caseData.createdDate);

    return (caseData.statusCode === "ONGOING" ? (d >= weekOld ? "NEW" : "ONGOING") : caseData.statusCode)
  }, [caseData])


  const onResolveCase = async () => {

    setResolveSpinner(true)
    try
    {
      clearPageLoaderCache()
      await resolveCase(caseData._id)
      navigate('/all-cases')
    }
    catch(err)
    {
      console.log(err)
      alert('cannot resolve case')
    }
    setResolveSpinner(false)
  }

  const onDeleteCase = async () => {
    setResolveSpinner(true)
    try
    {
      clearPageLoaderCache()
      await deleteCase(caseData._id)
      navigate('/all-cases')
    }
    catch(err)
    {
      console.log(err)
      alert('cannot resolve case')
    }
    setResolveSpinner(false)
  }

  return (
    <MainContent>
      <GridRow col="4">
        <div className="text-box">
          <label>Breach category</label>
          <p>{caseData.breachCategory}</p>
        </div>
        <div className="text-box">
          <label>Breach type</label>
          <p>{breachTypes[caseData.caseType]?.text || '--error--'}</p>
        </div>
        
        <div className="text-box">
          <label>Created Date</label>
          <p>{formatDate(caseData.createdDate)}</p>
        </div>
        <div className="text-box">
          <label>Status</label>
          <p className={`status-buttons ${statusCodeMap[statusCode] === 'New' && 'blue-status'} ${statusCodeMap[statusCode] === 'Ongoing' && 'red-status'} ${(statusCodeMap[statusCode] === 'Closed' || statusCode === 'DELETED') && 'green-status'}`}>{statusCodeMap[statusCode]}</p>
        </div>
      </GridRow>
      <GridRow col="4">
        <div className="text-box">
          <label>Confidentiality</label>
          <p>{caseData.anonymity ? confidentialityMap[caseData.anonymity] : (caseData.isAnonymous ? 'Anonymous' : 'Confidential')}</p>
        </div>
        <div className="text-box">
          <label>Source</label>
          <p>{sourceCodeMap[caseData.source]}</p>
        </div>

        <div className="text-box">
          <label>Created By</label>
          <p>{caseData.anonymity === "ANONYMOUS" ? <i>Anonymous</i> : (caseData.createdBy || <i>Anonymous</i>)}</p>
        </div>

        <div className="text-box">
          <label>Company</label>
          <p>{caseData.company}</p>
        </div>
      </GridRow>
      <GridRow col='4'>
        {/* <div className="text-box">
          <label>Parties Involved</label>
          <p>{caseData.partiesInvolved}</p>
        </div> */}
        <div className="text-box">
          <label>Deadline</label>
          <p>{formatDate(caseData.deadline)}</p>
        </div>
        <div className="text-box">
          <label>Priority</label>
          <p>{caseData.priority.toLowerCase().charAt(0).toUpperCase()+caseData.priority.slice(1).toLowerCase()}</p>
        </div>
      </GridRow>

      {!fromToken &&
      <>
        <hr />

        <FlexContainer>
          <MainButton color="#861724" type='empty' onClick={() => setTrashCaseModalView(true)}>
            Reject
          </MainButton>
          <MainButton color="#2F8958" onClick={() => setResolveCaseModalView(true)}>
            Resolve
          </MainButton>

          <MainButton onClick={() => setManageCaseModalView(true)}>Manage</MainButton>
        </FlexContainer>
      </>
      }

      <RightPanelModal onRequestClose={() => setManageCaseModalView(false)} shouldShow={manageCaseModalView}>
        <ManageCaseForm caseId={caseData._id} closeModal={() => setManageCaseModalView(false)} caseData={caseData} />
      </RightPanelModal>

      <ControlledModal backgroundColor='dark' shouldShow={resolveCaseModalView} onRequestClose={() => setResolveCaseModalView(false)}>
        <Col align="center" justify="center">
          <svg width="57" height="52" viewBox="0 0 57 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.4873 28.9868V19.0134" stroke="#D54D2F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28.4846 36.9999C28.1135 36.9999 27.8123 37.2986 27.815 37.6666C27.815 38.0346 28.1162 38.3333 28.4873 38.3333C28.8585 38.3333 29.1597 38.0346 29.1597 37.6666C29.1597 37.2986 28.8585 36.9999 28.4846 36.9999" stroke="#D54D2F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9427 5.03726L54.5412 40.7813C56.9348 44.9359 53.9092 50.1066 49.0843 50.1066H7.88718C3.0596 50.1066 0.033962 44.9359 2.43027 40.7813L23.0288 5.03726C25.4413 0.84793 31.5302 0.84793 33.9427 5.03726Z" stroke="#D54D2F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <h4>Confirm Action</h4>
          <span>Are you sure you want to resolve this case? This cannot be undone.</span>
          <Row width='45%' justify='space-between'>
            {resolveSpinner && <Loader />}
            {!resolveSpinner && 
              <>
                <MainButton onClick={() => setResolveCaseModalView(false)} color='#858585' type='empty' width='14rem'>Cancel</MainButton>
                <MainButton onClick={onResolveCase} width='14rem'>Confirm</MainButton>
              </>
            }
          </Row>
        </Col>
      </ControlledModal>

      <ControlledModal backgroundColor='dark' shouldShow={trashCaseModalView} onRequestClose={() => setTrashCaseModalView(false)}>
        <Col align="center" justify="center">
          <svg width="57" height="52" viewBox="0 0 57 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.4873 28.9868V19.0134" stroke="#D54D2F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28.4846 36.9999C28.1135 36.9999 27.8123 37.2986 27.815 37.6666C27.815 38.0346 28.1162 38.3333 28.4873 38.3333C28.8585 38.3333 29.1597 38.0346 29.1597 37.6666C29.1597 37.2986 28.8585 36.9999 28.4846 36.9999" stroke="#D54D2F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9427 5.03726L54.5412 40.7813C56.9348 44.9359 53.9092 50.1066 49.0843 50.1066H7.88718C3.0596 50.1066 0.033962 44.9359 2.43027 40.7813L23.0288 5.03726C25.4413 0.84793 31.5302 0.84793 33.9427 5.03726Z" stroke="#D54D2F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <h4>Confirm Action</h4>
          <span>Are you sure you want to delete this case? This cannot be undone.</span>
          <Row width='45%' justify='space-between'>
            {resolveSpinner && <Loader />}
            {!resolveSpinner && 
              <>
                <MainButton onClick={() => setTrashCaseModalView(false)} color='#858585' type='empty' width='14rem'>Cancel</MainButton>
                <MainButton onClick={onDeleteCase} width='14rem'>Confirm</MainButton>
              </>
            }
          </Row>
        </Col>
      </ControlledModal>

    </MainContent>
  );
};
