import React, { useEffect } from 'react';
import Joi from 'joi';

import { loadSettings, saveSettings } from './actions';
import withActionPageLoader from '../../../../hoc/withActionPageLoader';

import { Row } from '../../../../gui/layout/Row';
import { Col } from '../../../../gui/layout/Col';
import { StyledSettings } from './Style';
import MediaBrowseButton from './Form/MediaBrowseButton';
import { MainButton } from '../../../../gui/input/MainButton';
import { Form, GridRow, EmailField, NameField, PhoneField, TextField, AddressField, ZipCodeField, SelectField, CheckboxField, PasswordField, FileInputWrapper, SubmitWrapper, TextWrapper } from '../../../../gui/input/Form';
import { DefaultItem, DropdownSelect, Item } from '../../../../gui/input/Select/DropdownSelect';
import { FormGroup } from '../../../../gui/input/Form(old)/FormGroup';

import { CanadaFlag, UsaFlag } from '../../../../../App/theme/Header/icons';
import { Card } from '../../../../gui/layout/Card';
import { SpinnerWrapper } from '../../../../gui/input/Button/SpinnerWrapper';
import { LoaderThree } from '../../../../gui/elements/LoaderThree';
import { countries } from '../../../../../data/countries';
import { ReportLinkField } from './Form/ReportLinkField';
import { getOrganizationImage } from './actions';

export const Settings = withActionPageLoader(loadSettings, ({ loadedPageData }) => {
  let loadedData = loadedPageData[0] || {};
  let { name, billingInfo = {}, billingSubscription, contactDetails, machineTranslationAuth, publicLink, saml, security = {}, vat } = loadedData;
  let { address, city, country, countryCode, email, phone, zip} = contactDetails || {};

  const onSubmit = async (values) => {
    try
    {
      await saveSettings(values)
      alert("New settings were saved");
    }
    catch(err)
    {
      console.log("ERROR", err);
      alert(err.message);
    }
  }

  if(billingInfo === null){billingInfo = {}}
  if(security === null){security = {}}

  return (
    <StyledSettings>
      <h1>Settings</h1>
      <Card>
        <Form onSubmit={onSubmit}>
          <h2>Company</h2>

          <GridRow colTemplate="2fr 1fr">
            <NameField margin='0' marginBottom='0' defaultValue={name} />
            <FormGroup justify="center" margin="0">
              <FileInputWrapper name="logo" label="Logo" required={false}><MediaBrowseButton loadImageAction={getOrganizationImage}/></FileInputWrapper>
            </FormGroup>
          </GridRow>

          <GridRow colTemplate="1fr 1fr 1fr">
            <EmailField name="email" defaultValue={email} /> <PhoneField name="phone" defaultValue={phone} /> <TextField name="VAT" defaultValue={vat} />
          </GridRow>

          <GridRow colTemplate="1fr">
            <AddressField name="address" defaultValue={address} />
          </GridRow>
          <GridRow colTemplate="1fr 1fr 1fr">
            <ZipCodeField name="zip" marginBottom="0" defaultValue={zip} />
            <TextField margin="0" marginBottom="0" name="city" label="City" defaultValue={city} />

            <FormGroup justify="center" margin="0" padding="0">
              <SelectField name="country" label={<label>Country</label>} defaultValue={countryCode || null}>
                <DropdownSelect width="100%" HeaderStyle="fill" defaultValue={countryCode || null} maxHeight="50vh">
                  <DefaultItem>
                    {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M23.414 20.586L18.337 15.509C19.386 13.928 20 12.035 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C12.035 20 13.928 19.386 15.509 18.337L20.586 23.414C21.366 24.195 22.634 24.195 23.414 23.414C24.195 22.633 24.195 21.367 23.414 20.586ZM3 10C3 6.14 6.14 3 10 3C13.86 3 17 6.14 17 10C17 13.86 13.86 17 10 17C6.14 17 3 13.86 3 10Z" fill="#A5A5A5"/>
  </svg>*/}

                    Select country
                  </DefaultItem>

                  {countries.map(country => <Item key={"country_"+country.code} value={country.code} searchKey={country.name}>{country.name}</Item>)}
                  {/*<Item value="US">
                    <UsaFlag /> <span style={{ verticalAlign: 'super' }}>United-States</span>{' '}
                  </Item>
                  <Item value="CA">
                    <CanadaFlag /> <span style={{ verticalAlign: 'super' }}>Canada</span>{' '}
                  </Item>*/}
                </DropdownSelect>
              </SelectField>
            </FormGroup>
          </GridRow>

          <hr />
          <h2>Billing Information</h2>
          <GridRow colTemplate="1fr 1fr">
            <NameField name="billingName" label="Name" defaultValue={billingInfo.name } />
            <EmailField name="billingEmail" defaultValue={billingInfo.email} />
          </GridRow>

          <GridRow colTemplate="1fr">
            <AddressField name="billingAddress" defaultValue={billingInfo.address} />
          </GridRow>

          <GridRow colTemplate="1fr 1fr 1fr">
            <ZipCodeField name="billingZip" defaultValue={billingInfo.zip} />
            <TextField name="billingCity" label="City" defaultValue={billingInfo.city} />
            <FormGroup justify="center" margin="0" padding="0">
              <SelectField name="bilingCountry" label={<label>Country</label>} defaultValue={billingInfo.countryCode}>
                <DropdownSelect width="100%" HeaderStyle="fill" defaultValue={billingInfo.countryCode || null} maxHeight="50vh">
                  <DefaultItem>
                    Select country
                  </DefaultItem>
                  {countries.map(country => <Item key={"billingCountry_"+country.code} value={country.code} searchKey={country.name}>{country.name}</Item>)}
                </DropdownSelect>
              </SelectField>
            </FormGroup>
          </GridRow>

          <hr />

          <h2>Security</h2>
          <Col justify="flex-start" margin="0">
            <Row margin="0" marginRight="auto">
              <CheckboxField name="requireTwoFactorAuth" defaultValue={security.twoFactorAuthentication} label="Require two-factor authentication" disabled={true}/>
            </Row>
            <Row margin="0" marginRight="auto">
              <CheckboxField name="requireMultiplePeopleToDelete" defaultValue={security.multipleUsersToDeleteCase} label="Require multiple people to delete a case" disabled={true}/>
            </Row>
          </Col>

          <hr />
          <h2>SAML integration</h2>

          <GridRow colTemplate="1fr 1fr">
            <TextField name="samlUsername" label="Username" required={false} placeholder="Enter username" disabled={true}/>
            <PasswordField name="samlPassword" label="Password" required={false} placeholder="Enter password" disabled={true}/>
          </GridRow>

          <hr />
          <h2>Machine translation</h2>
          <Row margin="0" marginRight="auto">
            <CheckboxField name="requireMachineTransaltionTwoFactorAuth" defaultValue={machineTranslationAuth} disabled={true} label="Require two-factor authentication" />
          </Row>
          <hr />
          <h2>Reporting channel link</h2>
          {/*<TextField defaultValue={publicLink} name="reportLink" showLabel={false} readOnly={true} placeholder="https://sample-domain.com/report-a-case"/>*/}

          <div style={{width:"40rem"}}>
            <TextWrapper defaultValue={publicLink} name="reportLink" showLabel={false}
              validation={Joi.string().regex(/^[a-z0-9-]+$/).message("Report link can only have lower case letters, numbers and dashes").required().messages({ 'string.empty': 'Report link is required.' })}
            >
              <ReportLinkField />
            </TextWrapper>
          </div>
          

          <SubmitWrapper>
            <SpinnerWrapper Element={({loading, onClick}) => <MainButton onClick={onClick}>{loading ? <><LoaderThree size="2rem" color="#ffffff"/></> : <>Save info</>}</MainButton>} />
          </SubmitWrapper>
        </Form>
      </Card>
    </StyledSettings>
  );
});
