import api from "../../../../../utils/api"

export const getDashboardData = async () => {

    let [reportCases,categories, handlers, latestActivity] = await Promise.all([
        api.get('organizations/dashboard/admin'),
        api.get('categories/'),
        api.get('users/case-handlers'),
        api.get('report-cases/all-latest-activity')
    ]) 

    return {reportCases: reportCases.data.reportCases, stats: reportCases.data.stats, categories: categories.data.filter((val) => val.isActive), handlers:handlers.data, latestActivity:latestActivity.data}
}

export const getDashboardGeneralData = async () => {
    let [general, categories, handlers, latestActivity] = await Promise.all([
        api.get('organizations/dashboard/admin/general'),
        api.get('categories/'),
        api.get('users/case-handlers'),
        api.get('report-cases/all-latest-activity')
    ])
    
    return {general: general.data, categories: categories.data, handlers: handlers.data, latestActivity: latestActivity.data}
}

export const getDashboardChartData = async ({start,end},{category, handler}) => {
    let queryString = ""
    if(handler){queryString += "&handler="+handler}
    if(category){queryString += "&category="+category}
    let {data} = await api.get('/organizations/dashboard/admin/dates?startDate='+start.toISOString()+"&endDate="+end.toISOString()+queryString)
    return data
}