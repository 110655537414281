import React from 'react';
import { WhistLawLongLogo } from '../../../../../App/theme/icons';
import { StyledInvoice } from './Style';
import { Row } from '../../../../gui/layout/Row';
import { Col } from '../../../../gui/layout/Col';

export const Invoice = () => {
  let invoiceNum = '00009876564';
  let billedOnDate = 'Jan 25, 2022';
  let dueOnDate = 'Jan 25, 2022';
  return (
    <StyledInvoice>
      <header>
        <WhistLawLongLogo />
        <span className="receipt-heading">RECEIPT</span>
      </header>
      <Col className="invoice-due-details" align="flex-end">
        <Row margin="0.4rem 0" width="200px" justify="space-between">
          <label>Invoice #</label>
          <span className="grey">{invoiceNum}</span>
        </Row>
        <Row margin="0.4rem 0" width="200px" justify="space-between">
          <label>Billed on</label>
          <span className="grey">{billedOnDate}</span>
        </Row>
        <Row margin="0.4rem 0" width="200px" justify="space-between">
          <label>Due on</label>
          <span className="grey">{dueOnDate}</span>
        </Row>
      </Col>

      <div className="main-content-invoice">
        <Row>
          <div>
            <h4>Invoice to:</h4>
            <span className="xl blue">ABC Company</span>
            <div className="grey" style={{ marginTop: '1rem' }}>
              +989 89078965 | abccompany@abc.com
            </div>
            <div className="grey">#458, Higgens Lane, Colombo 04, Sri Lanka</div>
          </div>
          <Col margin="0" marginLeft="auto">
            <h4>Total Due:</h4>
            <span className="blue bold xl">$19.00</span>
          </Col>
        </Row>
        <table>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Subtotal</th>
          </tr>
          <tr>
            <td>Jan 25 - Feb 25, 2022</td>
            <td>WhistLaw monthly subscrition</td>
            <td>1</td>
            <td>$19.00</td>
            <td>$19.00</td>
          </tr>
        </table>

        <Col margin="1.4rem 0" marginLeft="auto" width="fit-content">
          <Row margin="1rem 0" width="200px" justify="space-between">
            <span>Subtotal</span>
            <span>$19.00</span>
          </Row>
          <Row margin="1rem 0" width="200px" justify="space-between">
            <span className="total bold xl">Total</span>
            <span className="blue bold">$19.00</span>
          </Row>
        </Col>

        <Col className="payment-info">
          <h4>Payment Info:</h4>
          <span>
            Paid <span className="emphasis-grey">$19.00</span> on <span className="emphasis-grey">Jan 25, 2022</span>
          </span>
          <span>
            Payment from <span className="emphasis-grey">Mastercard ....9876</span>
          </span>
        </Col>
      </div>
    </StyledInvoice>
  );
};
