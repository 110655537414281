import api from "../../../../../../../../utils/api";
import axios from "axios";

export const saveDescription = async (reportCaseId, description, fromToken) => {
    if(fromToken)
    {
        const {data} = await axios.patch('api/report-cases/'+reportCaseId+'/description', {description}, {headers:{"Authorization": fromToken}})
        return data
    }
    else
    {
        const {data} = await api.patch('/report-cases/'+reportCaseId+'/description', {description})
        return data
    }
    
    /*await new Promise((resolve) => {
        setTimeout(resolve, 2000)
    })
    return true*/
}