import React, { useState, useRef } from 'react';
import { PaginationWrapper } from '../../../../gui/controls/PaginationWrapper';
import { Table } from '../../../../gui/layout/Table';
import { StyledCategories } from './Style';
import { Pagination as PaginationControl } from '../../../../gui/layout/Pagination';
import { Row } from '../../../../gui/layout/Row';
import { MainButton } from '../../../../gui/input/MainButton';
import { SearchBar } from '../../../../gui/input/SearchBar';
import { Select } from '../../../../gui/input/Select';
import { RightPanelModal } from '../../../../gui/layout/RightPanelModal';
import { RightPanelModal as RightPanelModal2 } from '../../../../gui/layout/RightPanelModal';
import { FormGroup } from '../../../../gui/input/Form(old)/FormGroup';
import styled from 'styled-components';
import { ToggleSwitchWithText } from '../../../../gui/input/ToggleSwitchWithText';
import { TextInput } from '../../../../gui/input/TextInput';
import withActionPageLoader, {clearPageLoaderCache} from '../../../../hoc/withActionPageLoader';
import { formatDate } from '../../../../../utils/dataManipulation/formatDate';
import { ButtonLoadWrapper } from '../../../../gui/input/Button/ButtonLoadWrapper';

import { getCategories, changeCategoryStatus, makeNewCategory } from './actions';
import { DefaultItem, DropdownSelect, Item } from '../../../../gui/input/Select/DropdownSelect';
import { statusIcon } from '../../../../../App/theme/icons';
import { H1 } from '../../../../gui/elements/H1';
import { Form, BooleanWrapper, TextField, SubmitWrapper } from '../../../../gui/input/Form';
import { ToggleSwitch } from '../../../../gui/input/ToggleSwitch';
import { TableSortArrows } from '../../../../gui/layout/Table/TableSortArrows';
import { SortWrapper, SortControl } from '../../../../gui/controls/SortWrapper';
import { FilterWrapper } from '../../../../gui/controls/FilterWrapper';
import {SetAccessRight} from './SetAccessRight';

export const Categories = withActionPageLoader(getCategories, ({ loadedPageData, reloadPageData }) => {
  const [pageData, setPageData] = useState(loadedPageData);
  const [viewNewCategoryModal, setViewNewCategoryModal] = useState(false);
  const [Status, setStatus] = useState(false);
  const [filters, setFilters] = useState({ isActive: null, category: () => true });
  const [accessRightModalId, setAccessRightModalId] = useState(null)

  const onStatusChangeClick = async (_id, isActive) => {
    try {
      await changeCategoryStatus(_id, isActive);

      for (let i = 0; i < pageData.length; i++) {
        if (pageData[i]._id === _id) {
          //await new Promise((resolve) => setTimeout(resolve, 1500))
          let newPageData = [...pageData];
          newPageData[i].isActive = isActive;
          setPageData(newPageData);
          break;
        }
      }
    } catch (err) {
      console.log(err);
      alert('failed to update status');
    }
  };

  const onNewCategorySubmit = async (values) => {
    try {
      await makeNewCategory(values);
      setViewNewCategoryModal(false);
      reloadPageData();
    } catch (err) {
      console.log(err);
      alert('Failed to create new category');
    }
  };

  const onStatusChanged = (newStatus) => {
    setFilters((prevState) => ({ ...prevState, isActive: newStatus }));
  };

  const onSearchChange = (searchFilter) => {
    setFilters((prevState) => {
      let newFilterFunc = null;
      searchFilter = searchFilter.trim();

      if (searchFilter.length === 0) {
        newFilterFunc = (value) => true;
      } else {
        newFilterFunc = (value) => value.category.toLowerCase().indexOf(searchFilter) > -1;
      }

      return { ...prevState, category: newFilterFunc };
    });
  };

  const onSetAccessRights = (categoryId) => {

  }

  return (
    <StyledCategories>
      <Row justify="space-between" margin="0rem 0">
        <H1>Categories</H1>
        {/* <MainButton onClick={() => setViewNewCategoryModal(true)}>+ New category</MainButton> */}
      </Row>
      <Row justify="space-between" margin="2rem 0">
        <SearchBar onChange={onSearchChange}/>
        <DropdownSelect width="180px" type="all-status" HeaderStyle="fill" defaultValue={null} onChange={onStatusChanged}>
          <DefaultItem>{statusIcon}&nbsp;All Status</DefaultItem>
          <Item value={null}>{statusIcon}&nbsp;All Status</Item>
          <Item value={true}>{statusIcon}&nbsp;Active</Item>
          <Item value={false}>{statusIcon}&nbsp;Inactive</Item>
        </DropdownSelect>
      </Row>
      <FilterWrapper data={pageData} filters={filters}>
        <SortWrapper>
          <PaginationWrapper PaginationControl={PaginationControl}>
            <Table
              colHeaders={[
                <>Category<SortControl sortKey="category"><TableSortArrows /></SortControl></>, 
                <div style={{width:"100%", textAlign:"center"}}>Cases Submitted<SortControl sortKey="casesSubmitted"><TableSortArrows /></SortControl></div>, 
                <div style={{width:"100%", textAlign:"center"}}>New Cases<SortControl sortKey="newCases"><TableSortArrows /></SortControl></div>, 
                <div style={{width:"100%", textAlign:"center"}}>Status<SortControl sortKey="isActive"><TableSortArrows /></SortControl></div>, 
                <>Action</>
              ]}
              colCustomSize="3fr 2fr 2fr 2fr 5fr"
              LineItem={({ row }) => (
                <tr>
                  <td>{row.category}</td>
                  <td align="center">{row.casesSubmitted}</td>
                  <td align="center">{row.newCases}</td>
                  <td align="center"><div className={`status-buttons ${row.isActive ? 'green-status' : 'red-status'}`} style={{width:"7.84rem"}}>{row.isActive ? 'Active' : 'Inactive'}</div></td>
                  <td>
                    <Row margin="0">
                      <MainButton type="empty" padding="1rem" color="#D44D2F" onClick={()=>{clearPageLoaderCache(); setAccessRightModalId({id: row._id, name: row.category})}}>
                        Set access right <i className="fa-solid fa-pen-to-square"></i>
                      </MainButton>
                      &nbsp;&nbsp;&nbsp;
                      {row.isActive && (
                        <ButtonLoadWrapper spinnerSize="2.5rem" type="empty" padding="1rem" color="#861724" width="8.8rem" onClick={() => onStatusChangeClick(row._id, false)}>
                          Deactivate <i className="fa-solid fa-ban"></i>
                        </ButtonLoadWrapper>
                      )}
                      {!row.isActive && (
                        <ButtonLoadWrapper spinnerSize="2.5rem" type="empty" padding="1rem" color="#027D6A" width="8.8rem" onClick={() => onStatusChangeClick(row._id, true)}>
                          Activate <i className="fa-solid fa-check"></i>
                        </ButtonLoadWrapper>
                      )}
                    </Row>
                  </td>
                </tr>
              )}
            />
          </PaginationWrapper>
        </SortWrapper>
      </FilterWrapper>

      <RightPanelModal2 height="100%" shouldShow={accessRightModalId !== null} onRequestClose={() => setAccessRightModalId(null)}>
          <SetAccessRight id={accessRightModalId?.id} name={accessRightModalId?.name}/>
      </RightPanelModal2>


      <RightPanelModal shouldShow={viewNewCategoryModal} onRequestClose={() => setViewNewCategoryModal(false)}>
        <Form onSubmit={onNewCategorySubmit}>
          <h3>Add New Category</h3>
          <FormGroup margin="0">
            <TextField name="name" label="Name" />
          </FormGroup>
          <FormGroup margin='0'>
            <BooleanWrapper name="isActive" label="Status">
              <ToggleSwitch />
            </BooleanWrapper>
          </FormGroup>
          <FormGroup margin="1.5rem 0">
            <SubmitWrapper>
              <ButtonLoadWrapper marginLeft="auto" spinnerSize="2.5rem">
                Save category
              </ButtonLoadWrapper>
            </SubmitWrapper>
          </FormGroup>
        </Form>
      </RightPanelModal>
    </StyledCategories>
  );
});
