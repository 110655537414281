import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link, useParams } from 'react-router-dom';
import { IconLock, WhistLawLongLogo } from '../../../../App/theme/icons';
import { FormGroup } from '../../../gui/input/Form(old)/FormGroup';
import { MainButton } from '../../../gui/input/MainButton';
import { StyledResetPassword } from './Style';
import { resetPassword } from './actions';

export const ResetStep3 = () => {
  const { jwt } = useParams();
  const [formError, setFormError] = useState('');
  const [passwordReset, setPasswordReset] = useState(false);

  const myInput = useRef();
  const myInput2 = useRef();

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },

    validationSchema: yup.object({
      password: yup
        .string()
        .required('Please enter a password')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'Password must contain at least 8 characters, one upper case, one lowercase, one number and one special character.'),
      confirmPassword: yup
        .string()
        .required()
        .oneOf([yup.ref('password')], 'Passwords must match'),
    }),

    onSubmit: async (values) => {
      setFormError('');
      try {
        await resetPassword({ jwt, password: values.password });
        setPasswordReset(true);
      } catch (err) {
        setFormError(err.message);
      }
    },
  });

  const setPasswordLockView = (inputNumber) => {
    if (inputNumber.current.getAttribute('type') === 'password') {
      inputNumber.current.setAttribute('type', 'text');
    } else {
      inputNumber.current.setAttribute('type', 'password');
    }

    // if (myInput2.current.getAttribute('type') === 'password') {
    //   myInput2.current.setAttribute('type', 'text');
    // } else {
    //   myInput2.current.setAttribute('type', 'password');
    // }
  };

  return (
    <StyledResetPassword>
      <WhistLawLongLogo width='250px' />
      <div className='card'>
        {!passwordReset && (
          <>
            <h2>Reset password</h2>
            <p>Create a new password to continue.</p>

            <form onSubmit={formik.handleSubmit}>
              <FormGroup>
                <label>Password</label>
                <div className='formInput'>
                  <input ref={myInput} type='password' placeholder='Enter new password' name='password' onChange={formik.handleChange} value={formik.values.email} onBlur={formik.handleBlur} />
                  <IconLock onClick={() => setPasswordLockView(myInput)} />
                </div>
                {formik.touched.password && formik.errors.password && <p style={{ color: 'red' }}>{formik.errors.password}</p>}
              </FormGroup>
              <FormGroup>
                <label>Confirm password</label>
                <div className='formInput'>
                  <input ref={myInput2} type='password' placeholder='Enter confirm password' name='confirmPassword' onChange={formik.handleChange} value={formik.values.email} onBlur={formik.handleBlur} />
                  <IconLock onClick={() => setPasswordLockView(myInput2)} />
                </div>
                {formik.touched.confirmPassword && formik.errors.confirmPassword && <p style={{ color: 'red' }}>{formik.errors.confirmPassword}</p>}
              </FormGroup>

              <MainButton width='100%'>Reset password</MainButton>
              {/* <button type="submit" style={{width: "100%", background: "lightblue"}}>Reset password</button> */}
              <p>
                Back to{' '}
                <Link to='/login' className='bold blue'>
                  Login&gt;&gt;
                </Link>
              </p>
            </form>
          </>
        )}
        {passwordReset && (
          <>
            <h2>Your password was changed</h2>
            <p>You can now sign in to whistlaw with your new password.</p>
            <p>
              Back to{' '}
              <Link to='/login' className='bold blue'>
                Login&gt;&gt;
              </Link>
            </p>
          </>
        )}
      </div>
    </StyledResetPassword>
  );
};
