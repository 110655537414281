import api from "../../../../../../utils/api"

export const createUser = async (values) => {
    let {data} = await api.post('/users', values)
    return data
}

export const editUser = async (values) => {
    let {data} = await api.put('/users', values)
    return data
}

export const loadDepartments = async () => {
    let {data} = await api.get('/departments')
    return data
}