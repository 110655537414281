import styled from 'styled-components';

export const StyledSubscription = styled.div`
  .card {
    span {
      color: #484848;
    }
  }

  .cancel-sub {
    text-decoration-line: underline;
    color: #858585;
    font-size: 1.35rem;
    cursor: pointer;

    &:hover {
      color: red;
    }
  }

  .card-info {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f5f5f5;
    border: 1.5px solid transparent;
    border-radius: 5px;
    padding: 1rem;
    height: 8rem;
    width: 30rem;
    transition: 0.1s all ease-in-out;

    margin: 2rem;

    &:last-of-type {
      margin-left: 0;
    }

    &.selected {
      transition: 0.2s all ease-in-out;
      border: 1.5px solid #418eeb;
      .checkmark {
        position: absolute;
        right: -1rem;
        top: -1rem;
        transition: 0.2s all ease-in-out;
      }
    }

    span.cc-number {
      display: block;
      color: #858585;
      font-size: 1.5rem;
    }

    svg {
      /* width: 20px; */
    }

    .circle-checkmark {
      position: absolute;
      display: block;
      right: 0px;
      top: 0px;
      transform: translate(30%, -30%);
    }
  }
  h3 {
    margin: 0;
    margin-bottom: 1rem;
  }

  hr {
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
    border: 0;
    border-bottom: 1px solid #d6d6d6;
  }
  textarea{
    width:100%;
  }

  .creditCardDropdownElement-top {
    background: white;
    padding: 1rem;
    border-radius: 1.2rem 1.2rem 0 0;
    cursor: pointer;

    &:hover {
      background: #418EEB;
      color: white;
    }
  }

  .creditCardDropdownElement-bottom {
    background: white;
    padding: 1rem;
    border-radius: 0 0 1.2rem 1.2rem;
    cursor: pointer;

    &:hover {
      background: #418EEB;
      color: white;
    }
  }
`;
