import api from '../../../../../utils/api';
import setAuthToken from '../../../../../utils/setAuthToken';

export const getUserManagementData = async () => {
  const { data } = await api.get('/users/case-handlers');
  return data;
};

export const getOrganizations = async () => {
  const {data} = await api.get('/organizations')
  return data
}

export const changeUserStatus = async (_id, isActive) => {
  const { data } = await api.patch('/users/status', { _id, isActive });
  return data;
};

export const changeOrgStatus = async (_id, newStatus) => {
  const {data} = await api.patch('/organizations/'+ _id +'/status',{status: newStatus})
  return data
}

export const manageOrganization = (_id, callback) => async (dispatch) => {
  let {data} = await api.post('/organizations/manage',{_id})
  data.adminToken = api.defaults.headers.common['Authorization']
  api.defaults.headers.common['Authorization'] = data.token;

  try
  {
    const newProfilePic = await api.get('/users/profile-image', {"responseType": "blob"})
    let urlCreator = window.URL || window.webkitURL
    dispatch({type:"LOAD_MANAGED_IMAGE", payload: {managedImage: urlCreator.createObjectURL(newProfilePic.data), loaded: true}})
  }
  catch(err)
  {
    console.log("error", err)
      //do nothing
  }

  dispatch({
        type: 'MANAGE',
        payload: data
  })

  if(callback)
  {
    callback()
  }
}

export const createNewClient = async (values) => {

  const formData = new FormData();
  formData.append('address', values.address);
  formData.append('city', values.city);
  formData.append('country', values.country);
  formData.append('email', values.email);
  formData.append('name', values.name);
  formData.append('phone', values.phone);
  formData.append('vat', values.vat);
  formData.append('zip', values.zip);
  //formData.append('reportLink', values.reportLink);

  if(values.logo)
  {
    formData.append('logo', values.logo[0]);
  }

  const {data} = await api.post('/organizations', formData, {})
  return data;
}

export const editClient = async (values, orgId) => {

  const formData = new FormData();
  formData.append('address', values.address);
  formData.append('city', values.city);
  formData.append('country', values.country);
  formData.append('email', values.email);
  formData.append('name', values.name);
  formData.append('phone', values.phone);
  formData.append('vat', values.vat);
  formData.append('zip', values.zip);
  //formData.append('reportLink', values.reportLink);

  if(values.logo !== null)
  {
    formData.append('logo', values.logo[0]);
  }
  else
  {
    formData.append('logo', null);
  }
  

  const {data} = await api.patch('/organizations/'+orgId+'/info', formData, {})
  return data;
}
