import React,{useMemo} from 'react'
import { Form, CheckboxField, SubmitWrapper } from '../../../../../gui/input/Form'
import { MainButton } from '../../../../../gui/input/MainButton'
import { formContent } from './formContent'
import { breachCategories, breachTypes } from '../../../../../../data/reportCase'

import {Row} from '../../../../../gui/layout/Row'

import styled from 'styled-components';

const sourceCodeMap = {
  PLATFORM: "Platform",
  EMAIL: "Email",
  PHONE: "Phone",
  MEETING: "Meeting"
}

const CustomStyle = styled.div`


  h4,span{
font-size: 80%;
margin:1rem 0;
  }

  hr{
    border:none;
border-bottom: 1px solid lightgrey;
margin-bottom: 2rem;
  }

  input[type="checkbox"]{
    width: auto;
  }

  label{
    width:100%;
    font-size: 65%;
  }
`;

export const FormRecap = ({manager,formIndex,caseData,categories}) => {


  const onSubmit = (values) => {
    manager.setFormData(values)
    manager.setFormIndex(formIndex)
  }

  const categoryName = useMemo(() => {
    for(let i=0;i<categories.length;i++)
    {
      if(caseData.breachCategory === categories[i]._id)
      {
        return categories[i].category
      }
    }
  },[caseData])

  return (
    <Form onSubmit={onSubmit}>
      <CustomStyle>
        {/*JSON.stringify(caseData)*/}
        <h3>Add new case - please verify information entered before submitting.</h3>
<hr />
        <h4>Breach type: <span>{breachTypes[caseData.breachType].text}</span></h4>
        <h4>Breach category: <span>{categoryName}</span></h4>
        <h4>Reporting Method: <span>{sourceCodeMap[caseData.sourceCode]}</span></h4>
        <Row margin='0' justify='space-between'><h4>Date of breach: <span>{caseData.dateOfBreach?.toLocaleDateString('en-us',{month:"short", year:"numeric", day:"numeric"}) || ''}</span></h4>
        <h4>Time of breach: <span>{caseData.timeOfBreach}</span></h4></Row>
        <Row  margin='0' >
        <h4>Description of breach: <span>{caseData.description}</span></h4>
        </Row>
        <Row  margin='0' >
        <h4>Evidence (facultative): <span>{caseData.files ? Array.from(caseData.files).map(file => file.name).join("; ") : ''}</span></h4>
        </Row>
        <h4>Declaration:</h4>
            <CheckboxField name={"policyAgreement1"} defaultValue={false} label={formContent.policyAgreement[0]} required={true} />

            <CheckboxField name={"policyAgreement2"} defaultValue={false} label={formContent.policyAgreement[1]} required={true} />

        <Row>
          <SubmitWrapper>
              <MainButton>Confirm &amp; Submit</MainButton>
          </SubmitWrapper>
        </Row>
      </CustomStyle>
    </Form>
  )
}

