import { NOTIFICATIONS_LIST_SUCCESS } from './constants';

// export const nofiticationsListReducer = (state = { notificationsList: [] }, action) => {
export const nofiticationsListReducer = (state = { notificationsList: [] }, { type, payload }) => {
  switch (type) {
    case NOTIFICATIONS_LIST_SUCCESS:
      return {
        notificationsList: payload.notifications,
      };
    default:
      return state;
  }
};
