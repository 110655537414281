import api from "../../../../../utils/api";

export const loadProfileData = async () => {
    const {data} = await api.get('/users/profile')
    return data;
}
export const saveProfile = async (values) => {
    let formData = new FormData();
    formData.append("email",values.email);
    formData.append("name",values.name);
    formData.append("phone",values.phone);
    if(values.profilePicture){ formData.append('profilePicture', values.profilePicture[0]) };

    const {data} = await api.put('/users/profile', formData, {})
    return data;
}

export const changePassword = async (values) => {
    const {data} = await api.put('/users/password', values)
    return data;
}

export const getProfileImage = async () => {
    try
    {
      const {data} = await api.get('/users/profile-image', {"responseType": "blob"})
      let urlCreator = window.URL || window.webkitURL
      return urlCreator.createObjectURL(data)
    }
    catch
    {
        return null
    }
}