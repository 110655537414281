import { useState } from "react"
import styled from "styled-components"

const StyledReportLinkField = styled.label`

    display: flex;
    align-items: center;
    height: 4.5rem;
    background: #f3f4f6;
    border-radius: 12px;
    padding: 0 0.5rem;
    width: 46rem;
    border: 2px solid transparent;
    transition: 400ms all ease-in-out;
    -webkit-transition: 400ms all ease-in-out;
    ${props => props.error ? 'border: 1px solid #861724 !important;' : ''}

    &:hover {
        border: 2px solid #418EEB;
    }

    .holder {
        display: inline-block;
        white-space: nowrap;
        /*height: 7rem;*/
    }

    input {
        padding: 0;
        width: 25rem;
        border-radius: 0;
        height: 100%;
        outline: none;
        border: none !important;
    }
`

const StyledCopy = styled.span`
    .fa-copy {
        font-size: 2rem;
        color: #444;
        margin: 0rem 0.5rem;
        padding: 0.5rem;
        cursor: pointer;
        border: 1px solid transparent;
        transition: all 0.3s;
        border-radius: 9px;
    }

    .fa-copy:hover {
        border: 1px solid #888;
    }

    .fa-copy.copied {
        background-color: green;
        color: white;
    }
`

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
`

export const ReportLinkField = ({useRef, inputName, placeholder, onChange, value, onBlur, disabled, readOnly, onKeyDown, error}) => {


    const [copyClassName, setCopyClassName] = useState("fa-thin fa-copy")

    const onCopyClick = () => {
        navigator.clipboard.writeText('https://www.app.whistlaw.eu/report/'+value)
        setCopyClassName("fa-thin fa-copy copied");
        setTimeout(() => {
            setCopyClassName("fa-thin fa-copy");
        }, 200);
    }

    return (
        <StyledContainer>
            <StyledReportLinkField error={error}>
                <div className="holder">
                    <span>https://www.app.whistlaw.eu/report/</span>
                    <input type="text" ref={useRef} name={inputName} placeholder="company-name" onChange={onChange} value={value} onBlur={onBlur} disabled={disabled} readOnly={readOnly} onKeyDown={(e) => onKeyDown(e)}/>   
                </div>
            </StyledReportLinkField>
            <StyledCopy>
                <i className={copyClassName} onClick={onCopyClick}></i>
            </StyledCopy>
        </StyledContainer>
    )
}