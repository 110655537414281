import { css } from 'styled-components';

export const dfrcc = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const dfccc = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Flex = ([dir, justify, align]) => css`
  display: flex;
  flex-direction: ${dir};
  justify-content: ${justify};
  align-items: ${align};
`;
