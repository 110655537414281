import styled from 'styled-components/macro';
import { dfccc } from '../../../../../../App/theme/mixins';

export const StyledNotifications = styled.div`
  position: absolute;
  width: 48rem;
  top: 4rem;
  right: 0;
  padding: 0rem;
  z-index: 10 !important;

  .notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 3rem;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    border-radius: 12px 12px 0px 0px;
  }

  .notification-items {
    > div {
      display: grid;
      grid-template-columns: 1fr 3fr;
      padding: 0.2rem 1rem;
      padding-bottom: 1.8rem;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
      font-size: 88%;

      &:last-of-type {
        box-shadow: none;
      }

      > div {
        padding-right: 2rem;
      }
    }
  }

  svg {
    margin-right: 0;
    /* margin: 0 2rem; */
  }

  svg.notification-type-icon {
    width: 50px;
    height: 50px;
    margin: 0 2rem;
  }

  .time-marker {
    display: flex;

    svg.clock-icon {
      width: 25px;
      height: 25px;
      margin: 0;
      margin-right: 1rem;
    }
    color: #adadad;
    font-weight: bold;
  }
`;
