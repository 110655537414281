import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormGroup } from '../../../../gui/input/Form(old)/FormGroup';
import { Row } from '../../../../gui/layout/Row';
import { Col } from '../../../../gui/layout/Col';
import { UploadRectangle } from '../../../../gui/input/UploadRectangle';
import { MainButton } from '../../../../gui/input/MainButton';
import { DefaultItem, DropdownSelect, Item } from '../../../../gui/input/Select/DropdownSelect';
import { DatePicker } from '../../../../gui/input/date/DatePicker';
import { DatePickerField, Form, GridRow, SelectField, SubmitWrapper, TextField, TextareaField } from '../../../../gui/input/Form';
import { ClockIcon } from '../../../../../App/theme/Header/icons';
import { DropdownSearch, PinnedItem, Item as SearchItem } from '../../../../gui/input/Select/DropdownSearch';
import { Loader } from '../../../../../App/AppComponents/Loader';
import { createNewCase } from '../actions';
import { FileInputWrapper } from '../../../../gui/input/Form/FileInputWrapper';
import { Step0 } from './3StepsForm/Step0';
import { Step1 } from './3StepsForm/Step1';
import { Step2 } from './3StepsForm/Step2';
import { Step3 } from './3StepsForm/Step3';

import { StyledAddNewCase } from './Style';
import { ProgressBar } from './3StepsForm/ProgressBar';
import { FormRecap } from './3StepsForm/FormRecap';
import { Success } from '../Success';
import { Header } from '../Header';

import withActionPageLoader from '../../../../hoc/withActionPageLoader';
import { loadCategories } from './actions';
import { ReportACasePage } from '../ReportACasePage';

export const AddNewCase = withActionPageLoader(loadCategories,({loadedPageData, organizationId}) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [screenState, setScreenState] = useState('form');
  const [caseData, setCaseData] = useState({
    formIndex: -1,
    images: [],
    sourceCode: 'PLATFORM'
  });

  // example from baytaq - used onSubmit
  const saveCase = async (data) => {
    try {
      setScreenState('uploading');
      let insertedId = await createNewCase(data, organizationId);
      navigate('/all-cases/' + insertedId);
      setScreenState('success');
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      let insertedId = await createNewCase(values);
      navigate('/all-cases/' + insertedId);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
    setLoading(false);
  };

  //  //  //  //  //  //

  const manager = useRef({
    setFormData: (formData) =>
      setCaseData((state) => {
        let newFormData = { ...state, ...formData };
        return newFormData;
      }),
    setFormIndex: (formIndex) => {
      setCaseData((state) => {
        if (formIndex + 1 > state.formIndex) {
          return { ...state, formIndex: formIndex + 1 };
        } else {
          return state;
        }
      });
    },
    previousStep: (formIndex)=>{
      setCaseData((state) => {
        if (formIndex + 1 > state.formIndex) {
          return { ...state, formIndex: formIndex - 1 };
        } else {
          return state;
        }
      });
    },
    saveCase: (formData) => {
      setCaseData((currentState) => {
        let newState = { ...currentState, ...formData };
        saveCase(newState);
        return newState;
      });
    },
  });

  const progessManager = () => {
    if (caseData.formIndex === 1) {
      alert('alkjgdasljk');
      return 10;
    }
    if (caseData.formIndex === 2) {
      return 90;
    }
    return 1;
  };

  const submitAnother = () => {
    setCaseData({
      formIndex: 0,
      images: [],
      sourceCode: 'PLATFORM'
    })
    setScreenState('form')
    setErrorMessage('')
  }

  useEffect(() => {
    // if (selection.breachType !== '' && selection.breachCategory !== '') {

    // }
    // if (caseData.breachType !== '') {
    //   manager.setFormData({ breachType: selection.breachType, breachCategory: selection.breachCategory, breachDate: selection.breachDate, breachTime: selection.breachTime });
    //   manager.setFormIndex(manager.formIndex);
    // }
  }, [caseData]);

  return (
    <StyledAddNewCase>
        {screenState === 'form' && (
          <Col width='100%'>
            {caseData.formIndex === -1 && <ReportACasePage manager={manager.current} formIndex={-1} caseData={caseData} />}
            {caseData.formIndex === 0 && <Step0 manager={manager.current} formIndex={0} caseData={caseData} />}
            {caseData.formIndex === 1 && <Step1 manager={manager.current} formIndex={1} caseData={caseData} categories={loadedPageData} />}
            {caseData.formIndex === 2 && <Step2 manager={manager.current} formIndex={2} caseData={caseData} />}
            {caseData.formIndex === 3 && <Step3 manager={manager.current} formIndex={3} caseData={caseData} />}
            {caseData.formIndex === 4 && <FormRecap manager={manager.current} formIndex={4} caseData={caseData} categories={loadedPageData} />}
            {caseData.formIndex === 5 && <Success caseData={caseData} organizationId={organizationId} formIndex={5} submitAnother={submitAnother}/>} 

            {caseData.formIndex === 1 && <><ProgressBar progress={10} /></>}
            {caseData.formIndex === 2 && <ProgressBar progress={45} />}
            {caseData.formIndex === 3 && <ProgressBar progress={80} />}
            {caseData.formIndex === 4 && <ProgressBar progress={95} />}
            {caseData.formIndex === 5 && <ProgressBar progress={100} />}

            <Row justify="space-between" width="100%">
              {caseData.formIndex >= 1 && caseData.formIndex < 5 && (
                <MainButton width="fit-content" margin="1.2rem 0 !important" onClick={() => manager.current.previousStep(caseData.formIndex)}>
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </MainButton>
              )}
              {/* {caseData.formIndex >= 1 && caseData.formIndex <= 4 && (
                <MainButton width="fit-content" margin="1.2rem 0 !important" onClick={() => manager.setFormIndex(0)}>
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </MainButton>
              )} */}
            </Row>
          </Col>
        )}

        {screenState === 'uploading' && (
          <h3 className="registeringMessage">
            <Loader /> &nbsp; Registering case...
          </h3>
        )}

        {screenState === 'success' && <h3 className="registerSuccess">Case registered successfully</h3>}

        {errorMessage !== '' && <p style={{ color: 'red', textAlign: 'center', fontSize: '1.8rem' }}>Registration Failed:: {errorMessage}</p>}

       
    </StyledAddNewCase>
  );
});
