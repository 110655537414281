import PieChart, { Legend, Series, Tooltip, Format, Label, Connector, Export, Size } from 'devextreme-react/pie-chart';
import { useMemo } from 'react';
import styled from 'styled-components';

/*const data = [
  { source: 'Platform', value: '50' },
  { source: 'Email', value: '19' },
  { source: 'Phone', value: '25' },
  { source: 'Meeting', value: '7' },
];*/

const StyledPieChart = styled.div``;

export const Sources2 = ({data}) => {

  const chartData = useMemo(() => {
    let total = data.PLATFORM + data.EMAIL + data.PHONE + data.MEETING

    if(total === 0)
    {
      return [
        { source: 'Platform', value: 0 },
        { source: 'Email', value: 0 },
        { source: 'Phone', value: 0 },
        { source: 'Meeting', value: 0 }
      ]
    }

    return [
      { source: 'Platform', value: Math.round(100.0*data.PLATFORM/total) },
      { source: 'Email', value: Math.round(100.0*data.EMAIL/total) },
      { source: 'Phone', value: Math.round(100.0*data.PHONE/total) },
      { source: 'Meeting', value: Math.round(100.0*data.MEETING/total) }
    ]
  }, [data])

  return (
    <StyledPieChart>
      <PieChart id="pie" type="doughnut" palette={['#ff6d4c', '#418eeb', '#00a388']} dataSource={chartData}>
        <Size width={300} height={300} />
        <Series argumentField="source" valueField="value" innerRadius={0.78}>
          <Label visible={true} customizeText={(data) => data.value + '%'}>
            <Connector visible={true}></Connector>
          </Label>
        </Series>
        <Export enabled={false} />
        <Legend margin={0} horizontalAlignment="center" verticalAlignment="bottom" />
        {/*<Tooltip enabled={true} customizeTooltip={this.customizeTooltip}>
              <Format type="percent" />
              </Tooltip>*/}
      </PieChart>
    </StyledPieChart>
  );
};
