import React, {useState} from 'react'
import styled from 'styled-components';
import withActionPageLoader from '../../../../hoc/withActionPageLoader';
import { getAccessRights, updateAccessStatus } from './actions';
import { PaginationWrapper } from '../../../../gui/controls/PaginationWrapper';
import { Pagination } from '../../../../gui/layout/Pagination';
import { Table } from '../../../../gui/layout/Table';
import { ButtonLoadWrapper } from '../../../../gui/input/Button/ButtonLoadWrapper';

const StyledSetAccessRight = styled.div`

`;

const statusMap = {ACTIVE:"Active", INACTIVE:"Inactive"}

const LineItem = ({ row, onAccessChange, categoryId }) => {

  const updateAccessCode = async (accessCode) => {
    try
    {
      await updateAccessStatus({accessCode, categoryId, accessId: row._id, accountId: row.accountId})
      onAccessChange((row._id ? row._id.toString() : null), (row.accountId ? row.accountId.toString() : null), accessCode)
    }
    catch(err)
    {
      alert("failed to updatre access: " + err.message)
      console.log("ERROR", err)
    }
  }

  return (
    <tr>
      <td>{row.name}</td>
      <td className={`status-buttons ${row.status === 'ACTIVE' ? 'green-status' : 'red-status'}`} style={{width:"7.84rem"}}>{statusMap[row.status]}</td>
      <td>
        {row.status === 'ACTIVE' && (
          <ButtonLoadWrapper type="empty" color="#861724" onClick={() => updateAccessCode('INACTIVE')}>
            Block Access
          </ButtonLoadWrapper>
        )}
        {row.status === 'INACTIVE' && (
          <ButtonLoadWrapper type="empty" color="#00A389" onClick={() => updateAccessCode('ACTIVE')}>
            Grant Access
          </ButtonLoadWrapper>
        )}
      </td>
    </tr>
  );
};

const TextComponent = ({showCount, totalCount}) => {

  return <></>

  return (
    <div>
      <span className='showing-count' style={{float: "right"}}>
            Showing {showCount} from {totalCount} handlers
      </span>
    </div>
  )
}

export const SetAccessRight = withActionPageLoader(getAccessRights,({id, loadedPageData, name}) => {

  const [pageData, setPageData] = useState(loadedPageData)

  const onAccessChange = (id, accountId, accessCode) => {

    let newState = [...pageData];
    if(id)
    {
      for(let i=0;i<newState.length;i++)
      {
        if(newState[i]._id && newState[i]._id.toString() === id)
        {
          newState[i].status = accessCode
          break;
        }
      }
    }
    else
    {
      for(let i=0;i<newState.length;i++)
      {
        if(newState[i].accountId.toString() === accountId)
        {
          newState[i].status = accessCode
          break;
        }
      }
    }

    setPageData(newState)
  }

  return (
    <StyledSetAccessRight>
      <h5 style={{color:"#bbb"}}>Set access rights</h5>
      <h2>{name}</h2>
      <PaginationWrapper PaginationControl={Pagination} dataProp="data" data={pageData} dataName="handlers" TextComponent={TextComponent}>
        <Table colHeaders={['Handler', 'Status', 'Action']} LineItem={LineItem} lineItemExtraProps={{onAccessChange, categoryId: id}}/>
      </PaginationWrapper>
    </StyledSetAccessRight>
  )
})
