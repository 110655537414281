import React from 'react';
import { MainButton } from './input/MainButton';
import { Row } from './layout/Row';
import styled from 'styled-components';
import { Select } from './input/Select';
import { SearchBar } from './input/SearchBar';
import { Dropdown, Header, List } from './input/Select/Dropdown';
import { DropdownSelect, DefaultItem, Item } from './input/Select/DropdownSelect';
import { DropdownSearch, PinnedItem, Item as SearchItem } from './input/Select/DropdownSearch';

const canadaFlag = (
  <svg enable-background="new 0 0 512 512" viewBox="0 0 512 512" width="25px" height="25px" xmlns="http://www.w3.org/2000/svg">
    <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
    <g fill="#d80027">
      <path d="m512 256c0-101.494-59.065-189.19-144.696-230.598v461.195c85.631-41.407 144.696-129.103 144.696-230.597z" />
      <path d="m0 256c0 101.494 59.065 189.19 144.696 230.598v-461.196c-85.631 41.408-144.696 129.104-144.696 230.598z" />
      <path d="m300.522 289.391 44.521-22.261-22.26-11.13v-22.261l-44.522 22.261 22.261-44.522h-22.261l-22.261-33.391-22.261 33.391h-22.261l22.261 44.522-44.522-22.261v22.261l-22.26 11.13 44.521 22.261-11.13 22.261h44.522v33.391h22.26v-33.391h44.522z" />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

const usaFlag = (
  <svg height="25px" viewBox="0 0 512 512.01" width="25px" xmlns="http://www.w3.org/2000/svg">
    <path d="m256 512c141.38 0 256-114.63 256-256s-114.61-256-256-256-256 114.64-256 256 114.62 256 256 256z" fill="#f0f0f0" />
    <g fill-rule="evenodd">
      <path d="m474.17 122.33h-229.68v-66.85h170.66a255.89 255.89 0 0 1 59.02 66.85zm37.83 133.67h-267.37v-66.83h258.56a256.28 256.28 0 0 1 8.81 66.83zm-256 256a254.3 254.3 0 0 0 159.29-55.6h-318.57a254.19 254.19 0 0 0 159.28 55.6zm218.45-122.3h-436.9a253.31 253.31 0 0 1 -28.73-66.84h494.36a253.17 253.17 0 0 1 -28.73 66.84z" fill="#d80027" />
      <path
        d="m118.85 39.84h-.23v.16zm0 0h23.15l-21.81 15.65 8.25 25.6-21.76-15.65-21.62 15.65 7.11-22.09a256.08 256.08 0 0 0 -49.49 55.5h7.54l-13.8 10-6.26 11 6.54 20.34-12.37-9-8.53 19.86 7.11 22.3h27l-21.46 15.81 8.25 25.6-21.76-15.65-12.8 9.38a237.13 237.13 0 0 0 -2.14 31.86h256v-256a254.85 254.85 0 0 0 -137.15 39.84zm9.54 190.44.19.15h-.14zm-8.21-25.46 
  8.21 25.46-21.72-15.5-21.76 15.65 8.25-25.6-21.76-15.65h27l8.25-25.6 8.25 25.6h27zm.15-74.52 8.25 25.6-21.76-15.65-21.76 15.65 8.25-25.6-21.76-15.65h27l8.25-25.6 8.25 25.6h27zm78.36 84.48 21.76 15.65-8.25-25.6 21.8-15.65h-27l-8.25-25.6-8.25 25.6h-27l21.76 15.65-8.25 25.6zm13.51-84.48 8.25 25.6-21.76-15.65-21.76 15.65 8.25-25.6-21.76-15.65h27l8.25-25.6 8.25 
  25.6h27zm8.25-48.93-8.25-25.6 21.8-15.65h-27l-8.25-25.6-8.25 25.6h-27l21.76 15.65-8.25 25.6 21.76-15.65z"
        fill="#0052b4"
      />
    </g>
  </svg>
);

const StyledSandbox = styled.div`
  width: 80%;
  margin: auto;

  h1 {
    text-align: center;
  }
  button {
    margin: 1rem 2rem;
  }
`;

export const Sandbox = () => {
  return (
    <StyledSandbox>
      <h1>Sandbox - WhistLaw</h1>
      <div>
        <h2>Main Button</h2>
        <Row>
          <ol>
            <li>- empty buttons with different colors (Case info page)</li>
            <li>- buttons: status buttons, action buttons (empty), main button (blue)</li>
            <li>- buttons need to be able to take icon, or svg</li>
            <li>- priority component</li>
          </ol>
          <div>
            <MainButton>Main Button</MainButton>
            <MainButton type="empty">Main Button - type 'empty'</MainButton>
            <MainButton color="pink">Main Button - color pink</MainButton>
            <MainButton type="empty" color="pink">
              Main Button - type='empty' color pink
            </MainButton>
          </div>
        </Row>
      </div>
      <div>
        <h2>Select</h2>
        <Row>
          <ol>
            <li>( ! important ! )Must be operated from parent (because of filteredData displayed)</li>
            <li>Must close when click away</li>
            <li>Select needs to be able to take in any icon</li>
            <li>Calendar Select</li>
            <li>Input text Select</li>
            <li>Fake Select (like download pdf button)</li>
            <li>SearchBar (with dropdown Select)</li>
            <li>SearchBar (without dropdown Select)</li>
          </ol>

          {/*<Select label={['label1', 'label2']} values={['spif1', 'spaf2', 'spouf3']} />*/}

          {/* Default Select dropdown */}
          <DropdownSelect defaultValue="">
            <DefaultItem>Initial Text</DefaultItem>
            <Item value="val1">item 1</Item>
            <Item value="val2">item 2</Item>
            <Item value="val3">item 3</Item>
            {['4', '5', '6'].map((num) => (
              <Item value={'val' + num}>item {num}</Item>
            ))}
          </DropdownSelect>

          {/*<Select type='language' label={['type "language"']} values={['spif1', 'spaf2', 'spouf3']} />*/}
          <DropdownSearch>
            <PinnedItem value="pinnedOne">pinned one</PinnedItem>
            <SearchItem value="just an item">Just an item</SearchItem>
            <SearchItem value="another item">Another Item</SearchItem>
          </DropdownSearch>

          {/* Dropdown with background and icons */}
          <DropdownSelect HeaderStyle="fill" defaultValue="CA">
            <DefaultItem>
              <i className="fa-solid fa-earth-americas"></i> Country
            </DefaultItem>
            <Item value="US">
              {' '}
              {usaFlag} <span style={{ verticalAlign: 'super' }}>United-States</span>{' '}
            </Item>
            <Item value="CA">
              {' '}
              {canadaFlag}
              <span style={{ verticalAlign: 'super' }}>Canada</span>{' '}
            </Item>
          </DropdownSelect>

          {/*<SearchBar />*/}


          {/* Dropdown in it's most basic form where no items can be selected (mainly to use with buttons) */}
          <div style={{ margin: '0 1rem' }}>
            <Dropdown>
              <Header>
                <i className="fa-solid fa-circle-user" style={{ fontSize: '4rem' }}></i>
              </Header>
              <List>
                <div>
                  <button style={{ width: '100px', background: '#ddd' }} onClick={() => alert('Clicked Button 1')}>
                    Button 1
                  </button>
                </div>
                <div>
                  <button style={{ width: '100px', background: '#ddd' }} onClick={() => alert('Clicked Button 2')}>
                    Button 2
                  </button>
                </div>
              </List>
            </Dropdown>
          </div>

          {/* ALEX ADDED DURING CALL!!!!!!!!!!! */}

          {/* <SearchDropDown /> */}
          {/* <TextInput /> */}
          {/* Button dropdown (like in Profile and dashboard download button) */}
        </Row>
      </div>
    </StyledSandbox>
  );
};
