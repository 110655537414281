import api from "../../../../../../../../utils/api";
import axios from "axios";

export const loadLatestActivity = async ({caseId, fromToken}) => {
    if(fromToken)
    {
        let {data} = await axios.get('api/report-cases/'+caseId+"/latest-activity",{headers:{"Authorization": fromToken}})
        return data
    }
    else
    {
        let {data} = await api.get('report-cases/'+caseId+"/latest-activity")
        return data
    }
}