import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

export const StyledTabControl = styled.div`
  /* min-height: 78vh; */
  border-radius: 20px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 3%), 0 2px 2px rgb(0 0 0 / 3%), 0 4px 4px rgb(0 0 0 / 3%), 0 8px 8px rgb(0 0 0 / 3%);
  background: white;
  overflow: hidden;
`;

export const TabControlHeader = styled.div`
  display: flex;
  width: 98%;
  margin: auto;
  hr {
    /* margin: 0.5rem auto; */
    width: 100%;
    border-color: #fafafa;
    border: 0.5px solid #f2f3f4;
    margin-top: 3px;
    /* border: none; */
  }
  ul {
    display: flex;
    width: 100%;
    min-height: 8vh;
    margin: 0 auto;
    margin-left: auto;
    padding: 0;
    padding-left: 2.5rem;
    justify-content: flex-start;
    background: linear-gradient(#2c67ad, #418eeb);
    border-radius: 12px;

    li {
      list-style: none;
      margin: auto 0.8rem;
      /* width: 170px; */
      padding: 0.8rem 2rem;
      text-align: center;
      font-weight: 500;
      letter-spacing: 0.5px;
      color: white;

      cursor: pointer;
      font-size: 1.3rem;
      /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.03), 0 8px 8px rgba(0, 0, 0, 0.03); */

      &.selected {
        background: #4381cd;
        opacity: 0.85;
        border-radius: 10px;
      }

      &:hover {
        /* border-bottom: 2px solid ${(props) => props.theme.colors.secondary};
        color: ${(props) => props.theme.colors.secondary}; */
      }

      &.disabled {
        cursor: not-allowed;
        color: ${(props) => props.theme.colors.gray};
      }
    }
  }
  .fa-circle-user {
    font-size: 3.3rem;
    color: ${(props) => props.theme.colors.primary};
    margin: auto 1rem;
    margin-left: auto;
    cursor: pointer;
  }
`;

//menuTabs is an array where each element is either String or {text, disabled, visible}
export const TabControlCustomCase = ({ menuTabs, defaultTabIndex = 0, children }) => {
  const [tabIndex, setTabIndex] = useState(defaultTabIndex);

  const tabs = useMemo(() => {
    let tabs = [];
    menuTabs.forEach((tab) => {
      if (typeof tab === 'string') {
        tabs.push({ text: tab, disabled: false, visible: true });
      } else {
        tabs.push({ text: tab, disabled: false, visible: true});
      }
    });
    return tabs;
  }, [menuTabs]);

  return (
    <StyledTabControl>
      <TabControlHeader>
        <ul>
          {tabs.map((tab, index) => {
            if (!tab.visible) {
              return <></>;
            }

            return (
              <li key={'tab_control_' + index} className={`${tabIndex === index ? 'selected' : ''} ${tab.disabled ? 'disabled' : ''}`} onClick={() => setTabIndex(index)}>
                {tab.text}
              </li>
            );
          })}
        </ul>
      </TabControlHeader>
      
      {children[tabIndex]}
    </StyledTabControl>
  );
};
