import styled from 'styled-components';
import { Card } from '../../../../gui/layout/Card';

export const ChartCard = styled(Card)`
  margin: 1.2rem 0;
  padding: 2rem;
  width: ${(props) => props.width};
  height: fit-content;

  h3 {
    margin-right: auto;
    margin-top: 0;
  }
`;
