import React, { useRef, useEffect, useState } from 'react';

import { Chart, Legend, Series, Size, Tooltip } from 'devextreme-react/chart';
import styled from 'styled-components';

const StyledChart = styled.div`
  width: 100%;
  > div {
    width: 100%;
  }
  svg {
    width: 100%;
  }

  .dxc-series {
    #amount_of_cases_0 {
      fill: rgb(173, 173, 173);
    }
    #amount_of_cases_1 {
      fill: rgb(65, 142, 235);
    }
    #amount_of_cases_2 {
      fill: rgb(28, 130, 156);
    }
    #amount_of_cases_3 {
      fill: rgb(255, 109, 76);
    }
    #amount_of_cases_4 {
      fill: rgb(0, 163, 137);
    }
    #amount_of_cases_5 {
      fill: rgb(176, 58, 72);
    }
  }

  .dxc-val-grid {
    path {
      stroke-dasharray: 5, 5;
    }
  }

  .dxc-axes-group {
    display: none;
  }
`;

const dataSource = [
  {
    caseStatus: 'New',
    amountOfCases: 75,
  },
  {
    caseStatus: 'Received',
    amountOfCases: 180,
  },
  {
    caseStatus: 'Assigned',
    amountOfCases: 60,
  },
  {
    caseStatus: 'Active',
    amountOfCases: 150,
  },
  {
    caseStatus: 'Closed',
    amountOfCases: 160,
  },
  {
    caseStatus: 'Re-opened',
    amountOfCases: 20,
  },
];

export const AmountOfCases = () => {
  const myChart = useRef();
  const [initChart, setInitChart] = useState(null)

  useEffect(() => {
    if (myChart.current) {
      let rects = document.getElementById('amount_of_cases_chart').getElementsByClassName('dxc-series')[0].getElementsByTagName('rect');
      rects[0].id = 'amount_of_cases_0';
      rects[1].id = 'amount_of_cases_1';
      rects[2].id = 'amount_of_cases_2';
      rects[3].id = 'amount_of_cases_3';
      rects[4].id = 'amount_of_cases_4';
      rects[5].id = 'amount_of_cases_5';
    }
  }, [myChart, initChart]);

  useEffect(() => {
    let width = document.getElementById("amountOfCasesChart").getBoundingClientRect().width;
    setInitChart({width})
  },[])

  return (
    <StyledChart id="amountOfCasesChart">
      {initChart && 
        <Chart id="amount_of_cases_chart" ref={myChart} dataSource={dataSource}>
          <Size width={initChart.width} height={300} />
          <Series valueField="amountOfCases" argumentField="caseStatus" name="" type="bar" color="#418eeb" barWidth={20} cornerRadius={10} />
          <Legend verticalAlignment="top" horizontalAlignment="" visible={false} />
          <Tooltip enabled={true} />
        </Chart>
      }
    </StyledChart>
  );
};
