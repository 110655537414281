import styled from 'styled-components';

export const StyledCategories = styled.div`


button{
    display:flex;
    align-items:center;
}

`;
