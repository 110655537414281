import React, {useState} from 'react';
// import { FlexContainer, FormGroup } from '../AddEditUnit/Style';
import { StyledProfile } from './Style';
import {useDispatch} from 'react-redux'

import { EmailField, Form, GridRow, NameField, PhoneField, SubmitWrapper, FileInputWrapper, ErrorWrapper } from '../../../../gui/input/Form';
import { PasswordField } from '../../../../gui/input/Form/PasswordField';
import { MainButton } from '../../../../gui/input/MainButton';
import MediaBrowseButton from '../Settings/Form/MediaBrowseButton';
import { FormGroup } from '../../../../gui/input/Form(old)/FormGroup';
import { Row } from '../../../../gui/layout/Row';
import { Card } from '../../../../gui/layout/Card';
import withActionPageLoader from '../../../../hoc/withActionPageLoader';
import { loadProfileData } from './actions';
import { LoaderThree } from '../../../../gui/elements/LoaderThree';
import { SpinnerWrapper } from '../../../../gui/input/Button/SpinnerWrapper';
import { saveProfile, changePassword } from './actions';
import { ConfirmPasswordField } from '../../../../gui/input/Form/ConfirmPasswordField';
import Joi from 'joi';
import { getProfileImage } from './actions';

export const Profile = withActionPageLoader(loadProfileData,({ loadedPageData }) => {

  const dispatch = useDispatch()


  const onProfileSubmit = async (values) => {
    try
    {
      let response = await saveProfile(values)
      dispatch({type:'CHANGE_NAME', payload: {firstName: response.firstName, lastName: response.lastName}})
      if(response.newLogo)
      {
        dispatch({type:"LOAD_IMAGE", payload: {image: "data:image/ext;base64,"+response.newLogo, loaded: true}})
      }
      alert("Profile settings were saved");
    }
    catch(err)
    {
      console.log("ERROR", err);
      alert(err.message);
    }
  }

  const onPasswordChange = async (values, _, errorCallback) => {
    try
    {
      await changePassword(values)
      errorCallback("SUCCESS")
    }
    catch(e)
    {
      console.log(e)
      errorCallback(e.message)
    }
  }


  return (
    <StyledProfile>
      <h1>Settings</h1>
      <Card>

        <Form onSubmit={onProfileSubmit}>
          <h2>Profile</h2>
          <GridRow colTemplate="2fr 1fr">
            <NameField defaultValue={loadedPageData.name} />
            <FormGroup  margin="0">
              <FileInputWrapper label={<label>Profile picture</label>} name="profilePicture" required={false}><MediaBrowseButton loadImageAction={getProfileImage}/></FileInputWrapper>
            </FormGroup>
          </GridRow>
        
          <GridRow colTemplate="1fr 1fr">
            <EmailField name="email" defaultValue={loadedPageData.email} /> <PhoneField name="phone" defaultValue={loadedPageData.phone} />
          </GridRow>
          <Row align="flex-end" margin="2rem 0" marginLeft="auto">
            <SubmitWrapper>
              <SpinnerWrapper Element={({loading, onClick}) => <MainButton onClick={onClick}>{loading ? <><LoaderThree size="2rem" color="#ffffff"/></> : <>Save info</>}</MainButton>} />
            </SubmitWrapper>
          </Row>
          <hr />
        </Form>

        <Form onSubmit={onPasswordChange}>

          <h2>Change password</h2>.

          <ErrorWrapper Element={({error}) => 
            <>
              {error === "SUCCESS" && <p className='form-success-banner'>Password was changed successfully</p>}
              {error !== "SUCCESS" && <p className='form-error-banner'>{error}</p>}
            </>
          }/>

          <PasswordField name="currentPassword" label="Current password" placeholder="Enter current password" width="65%" validation={Joi.string().required().messages({ 'string.empty': 'Password is required.'})}/>
          <PasswordField name="newPassword" label="New password" placeholder="Enter new password" width="65%" />
          <ConfirmPasswordField name="confirmPassword" passwordFieldName="newPassword" label="Confirm password" placeholder="Enter confirm password" width="65%" />
          <Row align="flex-end" margin="2rem 0" marginLeft="auto">
            <SubmitWrapper>
              <SpinnerWrapper Element={({loading, onClick}) => <MainButton onClick={onClick}>{loading ? <><LoaderThree size="2rem" color="#ffffff"/></> : <>Change password</>}</MainButton>} />
            </SubmitWrapper>
          </Row>
        </Form>

      </Card>
    </StyledProfile>
  );
});
