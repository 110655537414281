import api from "../../../../utils/api"

export const createNewCase = async (values, organizationId) => {

    const formData = new FormData();
    formData.append('anonymity', values.anonymity);
    formData.append('breachType', values.breachType);
    formData.append("breachCategory", values.breachCategory);
    formData.append('dateOfBreach', values.dateOfBreach);
    formData.append('sourceCode', values.sourceCode);
    for(let i=0;i<values.files?.length;i++)
    {
        formData.append('files', values.files[i]);
    }
    formData.append('followupActions', values.followupActions || null);
    formData.append('justification', values.justification || null);
    formData.append('otherBreachType', values.otherBreachType || null);
    formData.append('placeOfBreach', values.placeOfBreach || null);
    formData.append('timeOfBreach', values.timeOfBreach);
    formData.append('description', values.description);
    formData.append('organizationId', organizationId);

    const {data} = await api.post('/report-cases', formData, {})
    return data.reportCaseToken
}

export const initReportLink = async ({publicLink}) => {
    const {data} = await api.get('/organizations/publicLink/'+publicLink)
    return data
}

export const getCaseByToken = async (token) => {
    const {data} = await api.get('/report-cases/token/'+token)
    return data
}

export const getCompanyLogo = async (reportLink) => {
    try
    {
      const {data} = await api.get('/organizations/report-link/'+reportLink+'/logo', {"responseType": "blob"})
      let urlCreator = window.URL || window.webkitURL
      return urlCreator.createObjectURL(data)
    }
    catch
    {
        return null
    }
}