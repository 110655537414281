import React from 'react';
import { ReportACasePage } from './ReportACasePage';
import { AddNewCase } from './AddNewCase/index';
import { Header } from './Header';
import {initReportLink} from './actions'
import withParamsPageLoader from '../../../hoc/withParamsPageLoader';
import { Col } from '../../../gui/layout/Col';

export const EmployeeReporting = withParamsPageLoader(initReportLink,({loadedPageData}) => {

  if(!loadedPageData.organizationId)
  {
    return (
      <div>
        <Header />
        <Col width='60rem' margin='auto'>
          <h1>The link you've entered is invalid.</h1>
        </Col>
      </div>
    )
  }


  return (
    <div>
      <Header />
      <AddNewCase organizationId={loadedPageData.organizationId}/>
    </div>
  );
});
