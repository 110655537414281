import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { LoaderThree } from '../../../../../gui/elements/LoaderThree';

const StyledMediaBrowseButton = styled.label`
  width: ${props => props.width || '100%'};
  cursor: pointer;
  span {
    display: inline-flex;
    align-items: center;
    padding: 0rem 1.3rem;
    height: 4.5rem;
    margin-top: 0.4rem;
    font-size: 1.33rem;
    line-height: normal;
    &:nth-of-type(1) {
      background: #f3f4f6;
      border-radius: 12px 0px 0px 12px;
      color: #484848;
      padding-right: 4rem;
      width: calc(100% - 8rem);
    }
    &:nth-of-type(2) {
      background: #d6d6d6;
      border-radius: 0px 12px 12px 0px;
      color: #333333;
      padding: auto auto;
      width: 8rem;
      font-weight: 700;
    }
  }

  .profile-img {
    width:5rem;
    height: 5rem;
    border-radius: 50%;
  }
`;

export const MediaBrowseButton = ({onChange = null, text = "Choose File", loadImageAction}) => {

  const [fileText, setFileText] = useState(text)
  const [loadingProfileImage, setLoadingProfileImage] = useState(() => loadImageAction ? true : false)
  const [profileImg, setProfileImg] = useState(null)
  const [fileSelected, setFileSelected] = useState(false)

  const onFileSelected = ({target}) => {

      if(target.files.length > 0)
      {
          setFileText(target.files[0].name)
          setFileSelected(true)

          if(onChange)
          {
              onChange(target.files)
          }
      }
      else
      {
          setFileText(text)
          setFileSelected(false)

          if(onChange)
          {
              onChange(null)
          }
      }
  }

  const loadImage = async () => {
    let img = await loadImageAction()
    setProfileImg(img)
    setLoadingProfileImage(false)
  }

  useEffect(() => {
    if(loadImageAction)
    {
      loadImage()
    }
  }, [])

  if(loadingProfileImage)
  {
    return <LoaderThree size="4rem" color="#418EEB" margin="0rem"/>
  }

  if(profileImg && !fileSelected)
  {
    return (
      <StyledMediaBrowseButton width="5rem">
        <img className="profile-img" src={profileImg} />
        <input type="file" style={{display: "none"}} onChange={onFileSelected} />
      </StyledMediaBrowseButton>
    ) 
  }

  return (
    <StyledMediaBrowseButton>
      <span>{fileText}</span>
      <span>Browse</span>
      <input type="file" style={{display: "none"}} onChange={onFileSelected} />
    </StyledMediaBrowseButton>
  );
};

export default MediaBrowseButton;
