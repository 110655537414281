import api from "../../../../../utils/api";

export const getDashboardData = async () => {
    let {data} = await api.get('organizations/dashboard/whistlaw')
    return data
}

export const getDashboardChartData = async ({start,end}) => {
    let {data} = await api.get('/organizations/dashboard/whistlaw/dates?startDate='+start.toISOString()+"&endDate="+end.toISOString())
    return data
}