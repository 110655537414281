import React, { useEffect, useState } from 'react';
import api from '../../../utils/api';

import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { WhistlawLogoSvg } from '../WhistlawLogoSvg';
import { Notifications } from '../../../components/containers/UserPages/CommonToAllPages/Dashboard/Notifications';

import { useDispatch, useSelector } from 'react-redux';
import { loadNotifications } from '../../../components/containers/UserPages/CommonToAllPages/Dashboard/Notifications/action';
import { StyledHeader } from './Style';
import { CanadaFlag, IconNotifications, IconProfile, IconSettings, UsaFlag } from './icons';
import flag from '../../../media/img/us-flag.png';
import { MainButton } from '../../../components/gui/input/MainButton';
import { loadProfileImage } from './actions';

import { Select } from '../../../components/gui/input/Select';
import { signOut } from '../../actions';
import { DefaultItem, DropdownSelect, Item } from '../../../components/gui/input/Select/DropdownSelect';
import { Dropdown, Header as DropdownHeader, List} from '../../../components/gui/input/Select/Dropdown';

import usflag from '../../../media/img/us-flag.png';

export const Header = ({accountType}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ui, setUi] = useState({ notificationOpen: false });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [headerDisplay, setHeaderDisplay] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const notificationsList = useSelector((state) => state.notifications.notificationsList);
  const auth = useSelector((state) => state.auth);
  const profile = useSelector(state => state.profile);

  useEffect(() => {
    if(!profile.loaded)
    {
      if(!profile.image)
      {
        dispatch(loadProfileImage())
      }
    }
  }, [profile])


  useEffect(() => {

    let notificationTimer = setInterval(() => {
      dispatch(loadNotifications());
    }, 1000*60*15)

    if (auth.loaded && auth.isAuthenticated) {
      dispatch(loadNotifications());
    }

    return () => clearInterval(notificationTimer)

    // window.addEventListener('click', () => {
    //   if (ui.notificationOpen === true) {
    //     setUi({ notificationOpen: false });
    //   } else {
    //     setUi({ notificationOpen: true });
    //   }
    // });
  }, [auth]);

  const closeFunc = () => {
    if (ui.notificationOpen === true) {
      setUi({ notificationOpen: !ui.notificationOpen });
    } else if (ui.notificationOpen === false) {
      setUi({ notificationOpen: !ui.notificationOpen });
      // alert('nothing to close');
    }
  };

  // useEffect(() => {
  //   window.addEventListener('click', () => closeFunc());

  //   return () => {
  //     window.removeEventListener('click', () => closeFunc());
  //   };
  // }, []);

  const onBellIconClick = (e) => {
    e.stopPropagation()
    setUi({ notificationOpen: !ui.notificationOpen })
  }

  return (
    <StyledHeader>
      <DropdownSelect marginRight=".9rem" type="language" width="200px" HeaderStyle="fill" defaultValue="CA">
        <DefaultItem>
          <img src={usflag} width="30px" />
          EN US
        </DefaultItem>
        <Item value="US">
          {' '}
          <img src={usflag} width="30px" />
          <span style={{ verticalAlign: 'super' }}>EN US</span>{' '}
        </Item>
      </DropdownSelect>
      <div className="notification-widget" onClick={onBellIconClick}>
        {ui.notificationOpen && (
          <Notifications
            data={notificationsList}
            onRequestClose={() => setUi({ notificationOpen: false })}
          />
        )}
        <div className="notification-count">{notificationsList.length}</div>
        <IconNotifications />
      </div>
      {accountType === "ADMIN" &&
        <div className="settings-widget" onClick={() => navigate('/settings')}>
          <IconSettings />
        </div>
      }
      <span className="hello-name">
        Hello, <span className="bold">{auth.firstName || ''}</span>
      </span>
      <Dropdown className="profile-dropdown" width="64px">
        <DropdownHeader>
          {profile.managedImage && <img width={42} height={42} style={{borderRadius: "50%", marginLeft: "1rem"}} src={profile.managedImage} />}
          {profile.image && !profile.managedImage && <img width={42} height={42} style={{borderRadius: "50%", marginLeft: "1rem"}} src={profile.image} />}
          {!profile.image && !profile.managedImage && <IconProfile />}
        </DropdownHeader>
        <List className="profile-dropdown-list" position="right">
          <div className="profile-option-button" onClick={() => navigate('/profile')}><button>My Profile</button></div>
          <div className="profile-option-button" onClick={() => dispatch(signOut())}><button>Sign Out</button></div>
        </List>
      </Dropdown>
    </StyledHeader>
  );
};
