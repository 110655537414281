import React, { useState, useRef } from 'react';
import { MainButton, MainButtonSpan } from '../../../../../../../gui/input/MainButton';
import { GridRow } from '../../../../../../../gui/layout/GridRow';
import { FlexContainer, MainContent } from '../../Style';
import styled from 'styled-components';
import image from '../../../../../../../../media/img/img_icon.svg';
import fileImage from '../../../../../../../../media/img/file_icon.svg';
import { Row } from '../../../../../../../gui/layout/Row';
import { UploadedItem } from './UploadedItem';
import { RightPanelModal } from '../../../../../../../gui/layout/RightPanelModal';
import { UploadRectangle } from '../../../../../../../gui/input/UploadRectangle';

import {Loader} from "../../../../../../../../App/AppComponents/Loader"
import withActionPageLoader from '../../../../../../../hoc/withActionPageLoader';
import { uploadMediaFile, loadMedia } from './actions';

const StyledMedia = styled.div`
  /* width: 90%; */
  margin: 0 !important;
  margin-right: auto !important;

  h3 {
    font-size: 1.65rem;
  }
  .grid {
    grid-gap: 1.2rem;
  }

  span.grey {
    color: #555555;
  }

  .upload-text {
    color: #418eeb;
    font-weight: bold;
  }

  a,
  button {
    display: flex;
    margin-top: 2rem;
    margin-left: auto;
  }
`;

export const Media = withActionPageLoader(loadMedia, ({ caseData, loadedPageData, fromToken=null }) => {
  const [media, setMedia] = useState(loadedPageData);
  const [uploadFileModalView, setUploadFileModalView] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileUploadError, setFileUploadError] = useState('');
  const [uploadingState, setUploadingState] = useState(false);

  const fileInput = useRef();
  const selectedFile = useRef(null);
  const clearFunction = useRef(null)

  /*const clearInput = () => {
    fileInput.current.value = null;
    setFileName('');
  };*/

  const clearInput = () => {
    if(clearFunction.current)
    {
      clearFunction.current.clearInput()
    }
  }

  const onFileChange = (files) => {
    const file = files[0];

    if (file) {
      selectedFile.current = file;
      setFileName(selectedFile.current.name);
      setFileUploadError('');
    }
  };

  const uploadFileClick = async () => {
    if (!selectedFile.current) {
      setFileUploadError('No file selected.');
      return;
    }

    setFileUploadError('');
    setUploadingState(true);

    try {
      let newFile = await uploadMediaFile(selectedFile.current, caseData._id, fromToken);
      let newMedia = { ...media };
      if (newFile.isImage) {
        newMedia.photos.push(newFile);
      } else {
        newMedia.files.push(newFile);
      }
      setMedia(newMedia);

      setUploadFileModalView(false);
      clearInput();
    } catch (err) {
      console.log(err);
      setFileUploadError(err.message);
    }

    setUploadingState(false);
  };

  return (
    <MainContent>
      <StyledMedia>
        {/*JSON.stringify(loadedPageData)*/}
        {/*<MainButton type='empty'>Upload file</MainButton>*/}{console.log(media)}
        <RightPanelModal shouldShow={uploadFileModalView} onRequestClose={() => setUploadFileModalView(false)}>
          <h2>Upload files</h2>

          <div>
            <span className="grey">Files</span>
            <label>
              <UploadRectangle fileName={fileName} onChange={onFileChange} loadController={(obj) => {clearFunction.current = obj}} multiple={false} />
              {/*<input ref={fileInput} style={{ display: 'none' }} type="file" onChange={onFileChange} />*/}
            </label>
            {fileUploadError && <p style={{ color: 'red' }}>{fileUploadError}</p>}
            {uploadingState && (
              <div>
                <span>
                  Uploading... <Loader />
                </span>
              </div>
            )}
            {!uploadingState && <MainButton onClick={uploadFileClick}>Upload now</MainButton>}
          </div>
        </RightPanelModal>
        <label>
          <MainButton color="#D44D2F" style={{ marginLeft: 'auto', display: 'flex', width: 'fit-content' }} type="empty" onClick={() => setUploadFileModalView(true)}>
            Upload File <i className="fa-solid fa-arrow-up-from-bracket"></i>
          </MainButton>
          {/* <input ref={fileInput} style={{ display: 'none' }} type='file' onChange={onFileChange} /> */}
        </label>
        {media.files.length === 0 && media.photos.length === 0  ? <p>No file has been attached.</p> : ''}
        {media.files.length + media.photos.length > 0 &&
          <>
          <div>
            <h3>Photos</h3>
            <GridRow className="grid" col="3">
              {media.photos.map((photo) => (
                <UploadedItem key={photo._id} data={photo} image={image} fullScreen={false} fromToken={fromToken}/>
              ))}
            </GridRow>
          </div>
        
          <div>
            <h3>Files</h3>
            <GridRow className="grid" col="3">
              {/*<UploadedItem image={fileImage} fullScreen={false}/>*/}
              {media.files.map((file) => (
                <UploadedItem key={file._id} data={file} image={fileImage} fullScreen={false} fromToken={fromToken}/>
              ))}
            </GridRow>
          </div>
          </>
        }
      </StyledMedia>
    </MainContent>
  );
});
