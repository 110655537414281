import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconAllCases } from '../../../../../../App/theme/SideNavigationMenu/icons';
import { Row } from '../../../../../gui/layout/Row';
import Card from '../StatsCard';
import { clearNotifications } from './action';
import { IconList, IconTimeMarker } from './icons';
import { NotificationItem } from './NotificationItem';
import { StyledNotifications } from './Style';

export const Notifications = ({ data, onRequestClose }) => {
  
  const dispatch = useDispatch()

  const onClearClick = async () => {
      dispatch(clearNotifications())
  }

  useEffect(() => {
    const toggle = () => onRequestClose();

    window.addEventListener('click', toggle);

    return () => {
      window.removeEventListener('click', toggle);
    };
  }, []);

  return (
    <StyledNotifications >
      <Card onClick={(e) => e.stopPropagation()} padding="0">
        <div className="notification-header">
          <h4>Notifications</h4>
          {data.length > 0 && <span className="blue bold" onClick={onClearClick}>
            Clear all
          </span>}
        </div>
        <div className={"notification-items"+(data.length > 0 ? ' notification-scroll' : '')}>
          {data.map((notif,index) => (
            <NotificationItem notification={notif} key={"notif_"+index} />
          ))}
          {data.length === 0 && <p>No new notification</p>}
        </div>
      </Card>
    </StyledNotifications>
  );
};
