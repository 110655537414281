import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import TimeKeeper from 'react-timekeeper';
import { DatePicker } from '../../../../../../gui/input/date/DatePicker';
import { Form, DatePickerField, GridRow, SelectField, TextareaField, TextField, SubmitWrapper } from '../../../../../../gui/input/Form';
import { FormGroup } from '../../../../../../gui/input/Form(old)/FormGroup';
import { TimePickerField } from '../../../../../../gui/input/Form/TimePickerField';
import { MainButton } from '../../../../../../gui/input/MainButton';
import { DropdownSearch, PinnedItem, Item } from '../../../../../../gui/input/Select/DropdownSearch';
import { TimePicker } from '../../../../../../gui/input/time/TimePicker';

export const Step2 = ({ caseData, manager, formIndex }) => {
  const [selection, setSelection] = useState({});
  const [otherEnabled, setOtherEnabled] = useState(false);


  const onSubmit = (values) => {
    manager.setFormData(values)
    manager.setFormIndex(formIndex)
  }


  // const breachTypeSelection = (type) => {
  //   setSelection((state) => ({ ...state, breachType: type }));
  // };


  // useEffect(() => {
  //   if (selection.bedrooms > 0 && selection.bathrooms > 0) {
  //     manager.setFormData({ numBedrooms: selection.bedrooms, numBathrooms: selection.bathrooms });
  //     manager.setFormIndex(formIndex);
  //   }
  // }, [selection]);

  return (
    <Form onSubmit={onSubmit}>
      <h3>New Case</h3>

      {/* Date time description */}


      <GridRow colTemplate="1fr 1fr">
        <FormGroup margin="0">
          <DatePickerField name="dateOfBreach" label="Date of breach" defaultValue={caseData.dateOfBreach || null} required={false} >
            <DatePicker type="default" date={caseData.dateOfBreach || null} align="left"/>
          </DatePickerField>
        </FormGroup>

        <TimePickerField label="Time of breach" name="timeOfBreach" defaultValue={caseData.timeOfBreach || ''} required={false}>
          <TimePicker time={caseData.timeOfBreach || null} align="right"/>
        </TimePickerField>
       
      </GridRow>

        <TextareaField
          name="description"
          label={
            <>
              Describe the breach <span className="red">*</span>
            </>
          }
          placeholder=" "
          rows={4}
          cols={60}
          width="100%"
          defaultValue={caseData.description}
        />
         <FormGroup>
        <SubmitWrapper>
          <MainButton margin="">Confirm</MainButton>
        </SubmitWrapper>
      </FormGroup>
    </Form>
  );
};
