import React from 'react';
import styled from 'styled-components/macro';
import { Card } from '../../../../gui/layout/Card';

export const StyledStatsCard = styled(Card)`
  flex-direction: row;
  /* width: 340px; */
  width: 100%;
  height: 142px;
  margin: 0 1.2rem;
  justify-content: flex-start;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  .text-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: grey;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 2rem 0.1rem;
    text-align: left;
    margin-left: 2rem;

    .stats-number {
      font-size: 3rem;
      font-weight: bold;
      color: #202020;
    }
  }
`;

export const StatsCard = ({ children, content }) => {
  return (
    <StyledStatsCard>
      {children}
      <div className="text-box">
        <span className="stats-number">{content.number}</span>
        {content.text}
      </div>
    </StyledStatsCard>
  );
};

export default Card;
