import { useState } from "react";
import Joi from 'joi';


import withActionPageLoader from "../../../../../../hoc/withActionPageLoader";
import { getManageCaseInfo } from "./actions/caseActions";

import { FormGroup } from "../../../../../../gui/input/Form(old)/FormGroup";
import { DatePicker } from "../../../../../../gui/input/date/DatePicker";
import Select from "../../../../../../gui/input/Toggle/Select";
import { DropdownSelect, DefaultItem, Item } from "../../../../../../gui/input/Select/DropdownSelect";
import { MainButton } from "../../../../../../gui/input/MainButton";
import { Form, DatePickerField, SelectField, SubmitWrapper } from "../../../../../../gui/input/Form";
import { updateCaseInfo } from "./actions/manageCaseActions";
import {Loader} from "../../../../../../../App/AppComponents/Loader"
import { useDispatch } from "react-redux";

export const ManageCaseForm = withActionPageLoader(getManageCaseInfo, ({caseId, loadedPageData, closeModal, caseData}) => {

    const dispatch = useDispatch()
    const [updating, setUpdating] = useState(false)
    

    const onSubmit = async (values) => {
        setUpdating(true)
        try
        {
            let changes = await updateCaseInfo({...values, caseId})
            caseData.priority = values.priority
            caseData.deadline = values.deadline
            caseData.handlerId = values.handler
            if(typeof changes.unassignedChange !== "undefined" && changes.unassignedChange !== 0)
            {
                dispatch({type:"ADD_CASES", payload: {add: changes.unassignedChange}})
            }
            closeModal()
        }
        catch(err)
        {
            console.log(err)
            alert(err.message)
        }
        setUpdating(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            {/*JSON.stringify(caseData)*/}
            <h3>Manage Case</h3>

            <>
              <span>Deadline</span>
              {/*<Select placeholder='Select date' />*/}
              <DatePickerField name="deadline" showLabel={false} defaultValue={caseData.deadline || null}><DatePicker date={caseData.deadline || null} align="left"/></DatePickerField>
            </>
            <>
                <span>Priority</span>
                <SelectField name="priority" showLabel={false} defaultValue={caseData.priority}>
                    <DropdownSelect defaultValue={caseData.priority}>
                        <DefaultItem>{caseData.priority === "LOW" ?<div>Low Priority</div> : <div>High Priority</div>}</DefaultItem>
                        {loadedPageData.priority.map((prio) => (
                            <Item key={prio._id} value={prio.priorityCode}><div>{prio.priorityName}</div></Item>
                        ))}
                    </DropdownSelect>
                </SelectField>

            </>
            <>
                <span>Handler</span>
                <SelectField name="handler" defaultValue={caseData.handlerId || ""} showLabel={false} validation={loadedPageData.handlers.length === 0 ? Joi.string().allow('').required() : null}>
                    <DropdownSelect defaultValue={caseData.handlerId || ""}>
                        <DefaultItem>
                        <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.414 20.586L18.337 15.509C19.386 13.928 20 12.035 20 10C20 4.486 15.514 0 10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C12.035 20 13.928 19.386 15.509 18.337L20.586 23.414C21.366 24.195 22.634 24.195 23.414 23.414C24.195 22.633 24.195 21.367 23.414 20.586ZM3 10C3 6.14 6.14 3 10 3C13.86 3 17 6.14 17 10C17 13.86 13.86 17 10 17C6.14 17 3 13.86 3 10Z" fill="#A5A5A5"/>
                        </svg>
                            {"Select Handler"}</DefaultItem>
                        {loadedPageData.handlers.map((handler) => (
                            <Item key={handler._id} value={handler._id}>{handler.name}</Item>
                        ))}
                    </DropdownSelect>
                </SelectField>
            </>
            {!updating && <SubmitWrapper><MainButton width="15rem" marginLeft='auto'>Save Case</MainButton></SubmitWrapper>}
            {updating && <Loader />}
        </Form>
    )
})