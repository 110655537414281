import React, {useState, useRef, useEffect} from 'react';
import { StyledSuccess } from './Style';
import { Form, TextField } from '../../../../gui/input/Form';
import Card from '../../CommonToAllPages/Dashboard/StatsCard';
import { Header } from '../Header';
import { MainButton } from '../../../../gui/input/MainButton';
import { Row } from '../../../../gui/layout/Row';
import { Col } from '../../../../gui/layout/Col';
import { createNewCase } from '../actions';
import { Loader } from '../../../../../App/AppComponents/Loader';

export const Success = ({caseData, organizationId, submitAnother}) => {

  const [uploading, setUploading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const newCaseLink = useRef('')
  const caseLinkTextBox = useRef()


  const uploadData = async () => {
    try
    {
      let reportLink = await createNewCase(caseData, organizationId)
      newCaseLink.current = reportLink
      setUploading(false)
    }
    catch(err)
    {
      console.log(err)
      setErrorMessage(err.message)
    }
  }

  const copyCode = async (e) => {
    caseLinkTextBox.current.select();
    document.execCommand('copy')
    e.target.focus();
  }


  useEffect(() => {
    if(uploading)
    {
      uploadData()
    }
  }, [caseData])



  if(errorMessage !== null)
  {
    return (
      <Form>
            <Col align='center' margin='auto'>
  
              <h3>{errorMessage}</h3>

            </Col>
      </Form>
    )
  }


  if(uploading)
  {
    return (
      <Form>
        <Col align='center' margin='auto'>
          <Loader />

          <h3>Uploading...</h3>

        </Col>
      </Form>
    )
  }

  return (
    <>
      <StyledSuccess>
        <Form>
          <Col align='center'>
            <svg width="73" height="71" viewBox="0 0 73 71" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M36.0133 48.3421C34.7969 48.3421 33.7422 47.774 33.0123 46.8824L20.0342 30.0111C18.7363 28.3075 19.061 25.9556 20.6832 24.6576C22.3868 23.3597 24.7387 23.6845 26.0367 25.3066L35.5267 37.5543C40.2312 29.6055 50.6946 13.9508 66.7547 0.891853C68.3768 -0.486972 70.8103 -0.243681 72.1082 1.45991C73.487 3.082 73.2437 5.51549 71.5402 6.81342C50.7756 23.8469 39.5011 46.0714 39.3388 46.3148C38.7708 47.4503 37.6352 48.2616 36.2565 48.4235C36.257 48.3426 36.0946 48.3426 36.0137 48.3426L36.0133 48.3421Z"
                fill="#027D6A"
              />
              <path
                d="M65.5379 17.5197C63.5911 19.4665 61.7256 21.4943 60.0226 23.4407C61.807 27.0906 62.7802 31.1462 62.7802 35.4451C62.7802 50.613 50.4511 63.023 35.2023 63.023C19.9533 63.023 7.62435 50.6939 7.62435 35.4451C7.62435 20.2772 19.9535 7.86713 35.2023 7.86713C40.4743 7.86713 45.4222 9.4084 49.6402 12.0037C51.3438 10.1383 53.1277 8.27226 54.9937 6.40683C49.316 2.51362 42.5028 0.242497 35.2024 0.242497C15.8168 0.161589 0 15.9782 0 35.3638C0 54.7495 15.8168 70.5663 35.2024 70.5663C54.5881 70.5668 70.4049 54.7495 70.4049 35.3638C70.4049 28.8753 68.621 22.7106 65.5385 17.5193L65.5379 17.5197Z"
                fill="#027D6A"
              />
            </svg>

            <h3>Success</h3>

            <p>Thank you for your submission. Now you can track your cases’ progress via following token. Copy and save the token.</p>

            <Row style={{position:'relative'}}>
              <TextField defaultValue={newCaseLink.current} readOnly={true} useRef={caseLinkTextBox} width="21.5rem" height="15rem" />
              <svg className="copy-icon" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={copyCode}>
                <rect x="4.52441" y="4.99805" width="12.9336" height="14.0058" rx="2" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.52442 15.0019H2.67676C1.65633 15.0019 0.829102 14.1061 0.829102 13.0011V2.99693C0.829102 1.8919 1.65633 0.996094 2.67676 0.996094H11.9151C12.9355 0.996094 13.7627 1.8919 13.7627 2.99693V4.99776" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </Row>

            <Row>
              <MainButton type="empty">
                <a href='/report' target='_blank'>
                Follow up on report</a></MainButton>
              <MainButton onClick={submitAnother}>Submit another case</MainButton>
            </Row>
          </Col>
        </Form>
      </StyledSuccess>
    </>
  );
};
