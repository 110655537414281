
import { useSelector } from "react-redux"
import { Dashboard } from "./index"
import {Dashboard as Dashboard2} from '../../EmployeeReporting/Dashboard'
import {Dashboard as Dashboard3} from '../../WhistlawUser/Dashboard'

export const DashboardSelector = () => {
    const accountType = useSelector(state => state.auth.accountType)

    if(accountType === "ADMIN")
    {
        return <Dashboard/>
    }
    else if(accountType === "SUPER_ADMIN")
    {
        return <Dashboard3/>
    }
    else if(accountType === "CASE_HANDLER")
    {
        return <Dashboard2/>
    }

    return <></>
}