import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';
import styled from 'styled-components';
import { FormGroup } from '../../../../../gui/input/Form(old)/FormGroup';
import { Row } from '../../../../../gui/layout/Row';
import { UploadRectangle } from '../../../../../gui/input/UploadRectangle';
import { MainButton } from '../../../../../gui/input/MainButton';
import { DefaultItem, DropdownSelect, Item } from '../../../../../gui/input/Select/DropdownSelect';
import { DatePicker } from '../../../../../gui/input/date/DatePicker';
import { DatePickerField, Form, GridRow, SelectField, SubmitWrapper, TextField, TextareaField, EmailField } from '../../../../../gui/input/Form';
import { ClockIcon } from '../../../../../../App/theme/Header/icons';
import { DropdownSearch, PinnedItem, Item as SearchItem } from '../../../../../gui/input/Select/DropdownSearch';
import { createNewCase } from '../actions';
import { FileInputWrapper } from '../../../../../gui/input/Form/FileInputWrapper';
import { createUser, editUser } from './actions';
import withActionPageLoader from '../../../../../hoc/withActionPageLoader';
import { loadDepartments } from './actions';
import { ButtonLoadWrapper } from '../../../../../gui/input/Button/ButtonLoadWrapper';
import { Col } from '../../../../../gui/layout/Col';

const StyledAddNewUser = styled.div`
  width: 100%;

  h3 {
    margin-top: 0;
    margin-bottom: 0.8rem;
    font-size: 1.8rem;
  }

  .wrapper {
    min-width: 100%;
    width: 100%;
  }

  span {
    color: #969ba0;
    font-size: 1.3rem;
  }

  input {
    display: flex;
    background: #f3f4f6;
    border-radius: 12px;
    width: 100%;
    /*height: 30px;*/
    border: none;
    padding: 0rem 1rem;

    &:active,
    &:focus,
    &:hover {
      outline: none;
      border: none;
    }
  }
  .red {
    color: #861724;
  }

  textarea {
    width: 100%;
    padding: 1rem;
    background: #f3f4f6;
    border-radius: 12px;
    border: none;
    resize: none;
    font-family: inherit;

    &:active,
    &:focus {
      outline: none;
    }
  }
  button {
    height: fit-content;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0.2rem;
    font-size: 1.1rem;
  }

  label {
    font-size: 1.3rem;
  }
`;

export const AddNewUser = withActionPageLoader(loadDepartments,({loadedPageData, reloadUserPage, userData=null}) => {
  const navigate = useNavigate();
  const [otherEnabled, setOtherEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  // const onSelectFieldChange = (e) => {
  //   if (e === 'OTHER') {
  //     setOtherEnabled(true);
  //   } else {
  //     setOtherEnabled(false);
  //   }
  // };

  const onSubmit = async (values) => {
    setLoading(true);
    try
    {
      if(userData)
      {
        await editUser({...values, _id: userData._id})
      }
      else
      {
        await createUser(values)
      }
      reloadUserPage()
    }
    catch (err)
    {
       console.log(err);
       alert(err.message);
    }
    setLoading(false);
  };

  return (
    <StyledAddNewUser>
      <h3>{userData ? 'Edit User' : 'Add New User'}</h3>
      <Form onSubmit={onSubmit} verticalSpacing="0.7rem">
        <Row margin="0">
          <TextField
            name="firstName"
            label={
              <span>
                First name<span className="red">*</span>
              </span>
            }
            style={{ marginTop: '0' }}
            placeholder="Enter first name"
            defaultValue={userData ? userData.firstName : null}
          />
          <TextField
            name="lastName"
            label={
              <span>
                Last name<span className="red">*</span>
              </span>
            }
            style={{ marginTop: '0' }}
            placeholder="Enter last name"
            defaultValue={userData ? userData.lastName : null}
          />
        </Row>
        <Row margin="1rem 0">
          {/* <SelectField
            name="title"
            label={<span>Title<span className="red">*</span></span>}
            placeholder="Select title"
            defaultValue={userData ? userData.title : null}
          >
            <DropdownSelect HeaderStyle="fill" defaultValue={userData ? userData.title : null}>
              <DefaultItem>Select Title</DefaultItem>
              <Item value="Mr">Mr</Item>
              <Item value="Ms">Ms</Item>
            </DropdownSelect>
          </SelectField> */}

          <TextField
            name="phone"
            label={<span>Phone</span>}
            style={{ marginTop: '0' }}
            required={false}
            // placeholder="Select time"
            defaultValue={userData ? userData.phone : null}
          />
        </Row>

        <EmailField name="email" label={<span>Email<span className="red">*</span></span>} placeholder="Enter email" defaultValue={userData ? userData.email : null}/>

        <FormGroup margin="1rem 0">
          <SelectField name="department" label={<span>Department<span className="red">*</span></span>} defaultValue={userData ? (userData.departmentId ? userData.departmentId.toString() : null) : null}>
            <DropdownSelect HeaderStyle="fill" defaultValue={userData ? (userData.departmentId ? userData.departmentId.toString() : null) : null}>
              <DefaultItem>Select department</DefaultItem>
              {loadedPageData.map((department) => <Item key={department._id} value={department._id.toString()}>{department.name}</Item>)}
            </DropdownSelect>
          </SelectField>
        </FormGroup>

        <FormGroup margin="1rem 0">
          <SelectField name="role" label={<span>User Role<span className="red">*</span></span>} defaultValue={userData ? userData.role : null}>
            <DropdownSelect HeaderStyle="fill" defaultValue={userData ? userData.role : null}>
              <DefaultItem>Select user role</DefaultItem>
              <Item value="CASE_HANDLER">Case Handler</Item>
              <Item value="ADMIN">Admin</Item>
              {/*<Item value="WHISTLEBLOWER">Whistleblower</Item>*/}
            </DropdownSelect>
          </SelectField>
        </FormGroup>
        
        <Col align="flex-end" margin="0.7rem 0rem">
          <SubmitWrapper>
            <ButtonLoadWrapper spinnerSize="2.5rem" width="110px">Save user</ButtonLoadWrapper>
          </SubmitWrapper>
          <center><p style={{fontSize: '1.37rem', color:'grey'}}>* A newly created user will automatically receive an email to set their password.</p></center>
        </Col>
      </Form>
    </StyledAddNewUser>
  );
});
