import React, { useState, useEffect, useMemo, useRef } from 'react';
import { SideNavigationMenu } from '../../../../../App/theme/SideNavigationMenu';
import { AppMainLayout } from '../../../../gui/layout/AppMainLayout';
import { IconNewCases, IconOpenCases, IconClosedCases, IconTotalCases } from './icons';
import { StyledDashboard } from './Style';
import { H1 } from '../../../../gui/elements/H1';
import { H2 } from '../../../../gui/elements/H2';
import { StatsCard } from './StatsCard';
import { Row } from '../../../../gui/layout/Row';
import { MainButton } from '../../../../gui/input/MainButton';
import { Notifications } from './Notifications';
import * as htmlToImage from 'html-to-image';

import Toggle from '../../../../gui/input/Toggle';
import { ToggleOption } from '../../../../gui/input/ToggleOption';
import { Select } from '../../../../gui/input/Select';
import { RightPanelModal } from '../../../../gui/layout/RightPanelModal';
import { LatestActivity, StyledList } from './LatestActivity';
import { DatePicker, DatePickerCustom } from './DatePicker';
import { DatePickerNew } from './DatePickerNew';
import { GridRow } from '../../../../gui/layout/GridRow';
import { ChartCard } from './ChartCard';
import { Charts } from './Charts';
import { Sources2 } from './Charts/Sources2';
import { ClosingCode } from './Charts/ClosingCode';
import { PrivacyBreakdown } from './Charts/PrivacyBreakdown';
import { Cases } from './Charts/Cases';
import { SumOfCases } from './Charts/SumOfCases';
import { AmountOfCases } from './Charts/AmountOfCases';
import { CSVDownload, CSVLink } from 'react-csv';
import { csvTestData } from './csvTestData.js';
import { DateRangePicker } from '../../../../gui/input/date/DateRangePicker';
import { Dropdown, Header, List } from '../../../../gui/input/Select/Dropdown';
import { DropdownItem } from '../../../../gui/input/Select/Style';
//import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import withActionPageLoader from '../../../../hoc/withActionPageLoader';
import { getDashboardData, getDashboardGeneralData, getDashboardChartData } from './actions';
import { DropdownSelect, DefaultItem, Item } from '../../../../gui/input/Select/DropdownSelect';
import { Loader } from '../../../../../App/AppComponents/Loader';

const monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const buildLatestActivityTime = (date) => {
  let d = new Date(date)
  return `${d.getDate()} ${monthList[d.getMonth()]}, ${d.getHours()}:${d.getMinutes().toString().padStart(2,"0")} ${d.getHours() >= 12 ? "PM" : "AM"}`
}

export const Dashboard = withActionPageLoader(getDashboardGeneralData,({ loadedPageData }) => {
  const [ui, setUi] = useState({ latestActivityView: false, datePickerView: false });
  /*const [filters, setFilters] = useState(() => {
    let date = new Date()
    let oneYear = (new Date()).setFullYear(date.getFullYear() - 1)
    return {category: null, handler: null, dateRange: {start: oneYear, end: date}}
  })*/
  const filters = useRef({category: null, handler: null})
  const [chartsLoading, setChartsLoading] = useState(true)

  const [dateRange, setDateRange] = useState(() => {
    let date = new Date()
    let oneYear = new Date(((new Date()).setMonth(date.getMonth() - 11))).setDate(1)
    return {start: new Date(oneYear), end: date}
  })

  const reportCases = useRef(null)
  const [chartData, setChartData] = useState(null)
  const [csvData, setcsvData] = useState("")
  const [csvFileName, setCsvFileName] = useState("statistics.csv")


  const onDateRangeChange = (dates) => {

    /*let startDate = dates.startDate.toDate()
    let endDate = dates.endDate.toDate()

    let newFilters = {...filters}
    newFilters.dateRange = {start: startDate, end: endDate}

    setFilters(newFilters)*/

    setDateRange({start: dates.startDate.toDate(), end: dates.endDate.toDate()})
  }
  

  const downloadPDF = () => {
    htmlToImage.toCanvas(document.getElementById('chart_container'),{
      backgroundColor: null
    }).then(function (canvas) {
      const doc = new jsPDF("p", "px", "a4");

      const pageWidth = doc.internal.pageSize.getWidth()-10;
      const pageHeight = doc.internal.pageSize.getHeight()-10;

      const pageFraction = 1.0*pageWidth/pageHeight
      const canvasFraction = 1.0*canvas.width/canvas.height

      if(canvasFraction >= pageFraction)
      {
        const scale = 1.0*pageWidth / canvas.width;
        doc.addImage(canvas, 'JPEG', 5, 5, pageWidth, Math.floor(canvas.height*scale), 'FAST');
      }
      else
      {
        const scale = 1.0*pageHeight / canvas.height;
        const newWidth = canvas.width * scale;
        const padding = (pageWidth - newWidth)/2
        doc.addImage(canvas, 'JPEG', 5+padding, 5, newWidth, canvas.height*scale, 'FAST');
      }

      let start = dateRange.start
      let end = dateRange.end
      start = start.getFullYear()+"-"+(start.getMonth()+1).toString().padStart(2,"0")+"-"+start.getDate().toString().padStart(2,"0")
      end = end.getFullYear()+"-"+(end.getMonth()+1).toString().padStart(2,"0")+"-"+end.getDate().toString().padStart(2,"0")
      doc.save('Statistics_'+start+'_to_'+end+'.pdf');
    });
  };



  const onCategorySelected = (value) => {
    //setFilters({...filters, category: value.value})
    filters.current.category = value.value
    onChangeReloadData(dateRange)
  }

  const onHandlerSelected = (value) => {
    //setFilters({...filters, handler: value.value})
    filters.current.handler = value.value
    onChangeReloadData(dateRange)
  }


  const computeChartData = (data, newDateRange, oldCasesCount) => {
    let casesChart = {}
    let orderedCaseChart = []
    let sumOfCasesChart = {}
    let orderdSumOfCasesChart = []
    let sources = {"PLATFORM": 0, "EMAIL": 0, "PHONE": 0, "MEETING": 0}
    let closingCode = {"Received": 0, "Rejected": 0}
    //let amountOfCases = {"New": 0, "Received": 0, "Assigned": 0, "Active": 0, "Reopened": 0, "Closed": 0}
    let amountOfCases = {"Unassigned": 0, "Assigned": 0, "Closed": 0, "Total": 0, "Rejected": 0}
    let privacyBreakdown = {"ANONYMOUS":0, "CONFIDENTIAL": 0}

    let reportCase = null
    let createDate = null
    let currentDate = new Date(newDateRange.start);
    let endDate = new Date(newDateRange.end);
    let month = currentDate.getMonth()
    let year = currentDate.getFullYear()

    while(month !== endDate.getMonth() || year !== endDate.getFullYear())
    {
      casesChart[year+"-"+month] = 0
      sumOfCasesChart[year+"-"+month] = 0
      month++
      if(month >= 12)
      {
        month = 0
        year++
      }
    }
    casesChart[endDate.getFullYear()+"-"+endDate.getMonth()] = 0
    sumOfCasesChart[endDate.getFullYear()+"-"+endDate.getMonth()] = 0


    for(let i=0;i<data.length;i++)
    {
      reportCase = data[i]

      createDate = new Date(reportCase.createdDate)
      month = createDate.getMonth()
      year = createDate.getFullYear()
      casesChart[year+"-"+month]++
      sumOfCasesChart[year+"-"+month]++
      sources[reportCase.sourceCode]++
      closingCode["Received"]++
      privacyBreakdown[reportCase.anonymity]++

      amountOfCases["Total"]++

      if(reportCase.statusCode === "ONGOING")
      {
        if(reportCase.assigned)
        {
          amountOfCases["Assigned"]++
        }
        else
        {
          amountOfCases["Unassigned"]++
        }
      }
      else if(reportCase.statusCode === "CLOSED")
      {
        amountOfCases["Closed"]++
      }
      else if(reportCase.statusCode === "DELETED")
      {
        amountOfCases["Rejected"]++
        closingCode["Rejected"]++;
      }

    }

    currentDate = new Date(newDateRange.start)
    endDate = new Date(newDateRange.end);
    month = currentDate.getMonth()
    year = currentDate.getFullYear()
    let runningTotal = oldCasesCount;

    while(month !== endDate.getMonth() || year !== endDate.getFullYear())
    {
      runningTotal += sumOfCasesChart[year+"-"+month]
      orderedCaseChart.push({year, month, count: casesChart[year+"-"+month]})
      orderdSumOfCasesChart.push({year, month, count: runningTotal})
      month++
      if(month >= 12)
      {
        month = 0
        year++
      }
    }
    runningTotal += sumOfCasesChart[year+"-"+month]
    orderedCaseChart.push({year, month, count: casesChart[year+"-"+month]})
    orderdSumOfCasesChart.push({year, month, count: runningTotal})

    let newState = {casesChart: orderedCaseChart, sources, closingCode, sumOfCases: orderdSumOfCasesChart, amountOfCases, privacyBreakdown}

    //make csv data
    makeCsvData(newState)

    setChartData(newState)

  }

  const getCategoryTextById = (id) => {
    if(!id){return "All Categories"}

    for(let i=0;i<loadedPageData.categories.length;i++)
    {
      if(loadedPageData.categories[i]._id.toString() === id)
      {
        return loadedPageData.categories[i].category
      }
    }

    return "All Categories"
  }

  const getHandlerTextById = (id) => {
    if(!id){return "All Handlers"}

    for(let i=0;i<loadedPageData.handlers.length;i++)
    {
      if(loadedPageData.handlers[i]._id.toString() === id)
      {
        return loadedPageData.handlers[i].firstName+" "+loadedPageData.handlers[i].lastName
      }
    }

    return "All Handlers"
  }

  const makeCsvActiveCases = (data) => {

    let monthIndexMap = {
      "0": "Jan", 
      "1": "Feb",
      "2": "Mar",
      "3": "Apr",
      "4": "May",
      "5": "Jun",
      "6": "Jul",
      "7": "Aug",
      "8": "Sep",
      "9": "Oct",
      "10": "Nov",
      "11": "Dec"
    }

    if(data === null){return []}

    let toReturn = []

    if(data.length > 12)
    {
      for(let i=0; i<data.length; i++)
      {
        toReturn.push({month: data[i].year+" "+monthIndexMap[data[i].month.toString()], active: data[i].count})
      }
    }
    else
    {
      for(let i=0; i<data.length; i++)
      {
        toReturn.push({month: monthIndexMap[data[i].month.toString()], active: data[i].count})
      }
    }

    return toReturn
  }

  const makeCsvSourcesChart = (data) => {
    let total = data.PLATFORM + data.EMAIL + data.PHONE + data.MEETING

    if(total === 0)
    {
      return [
        { source: 'Platform', value: 0 },
        { source: 'Email', value: 0 },
        { source: 'Phone', value: 0 },
        { source: 'Meeting', value: 0 }
      ]
    }

    return [
      { source: 'Platform', value: Math.round(100.0*data.PLATFORM/total) },
      { source: 'Email', value: Math.round(100.0*data.EMAIL/total) },
      { source: 'Phone', value: Math.round(100.0*data.PHONE/total) },
      { source: 'Meeting', value: Math.round(100.0*data.MEETING/total) }
    ]
  }

  const makeCsvClosingCodeChart = (data) => {
    let total = data["Received"] + data["Rejected"]
    if(total === 0)
    {
      return [
        { source: 'Received', value: 0 },
        { source: 'Rejected', value: 0 }
      ]
    }

    return [
      { source: 'Received', value: Math.round(100.0*data["Received"]/total) },
      { source: 'Rejected', value: Math.round(100.0*data["Rejected"]/total) }
    ]
  }

  const makeCsvSumOfCasesChart = (data) => {

    let monthIndexMap = {
      "0": "Jan", 
      "1": "Feb",
      "2": "Mar",
      "3": "Apr",
      "4": "May",
      "5": "Jun",
      "6": "Jul",
      "7": "Aug",
      "8": "Sep",
      "9": "Oct",
      "10": "Nov",
      "11": "Dec"
    }

    if(data === null){return []}

    let toReturn = []

    if(data.length > 12)
    {
      for(let i=0; i<data.length; i++)
      {
        toReturn.push({month: data[i].year+" "+monthIndexMap[data[i].month.toString()], count: data[i].count})
      }
    }
    else
    {
      for(let i=0; i<data.length; i++)
      {
        toReturn.push({month: monthIndexMap[data[i].month.toString()], count: data[i].count})
      }
    }

    return toReturn
  }

  const makeCsvPrivacyBreakdownChart = (data) => {
      let total = data["ANONYMOUS"] + data["CONFIDENTIAL"]
      if(total === 0)
      {
        return [
          { source: 'Anonymous', value: 0 },
          { source: 'Confidential', value: 0 }
        ]
      }
  
      return [
        { source: 'Anonymous', value: Math.round(100.0*data["ANONYMOUS"]/total) },
        { source: 'Confidential', value: Math.round(100.0*data["CONFIDENTIAL"]/total) }
      ]
  }

  const makeCsvData = (chartData) => {
    let lines = [];
    lines.push(["#overview"])
    lines.push(["","Unassigned Cases","Assigned Cases","Closed Cases","Total Cases"])
    lines.push(["",loadedPageData.general.UNASSIGNED,loadedPageData.general.ASSIGNED,loadedPageData.general.CLOSED,loadedPageData.general.TOTAL])
    lines.push([])
    // lines.push(["#filters"])
    // lines.push(["Start Date", "End Date", "Category", "Handler"])
    // lines.push([dateRange.start.toLocaleDateString('en-us'), dateRange.end.toLocaleDateString('en-us'), getCategoryTextById(filters.current.category), getHandlerTextById(filters.current.handler)])
    // lines.push([])
    lines.push(["#Active Cases"])
    lines.push(["","month","active"])

    let activeCases = makeCsvActiveCases(chartData.casesChart)
    for(let i=0;i<activeCases.length;i++)
    {
      lines.push(["",activeCases[i].month, activeCases[i].active])
    }

    lines.push([])
    lines.push(["#Sources"])
    lines.push(["","Platform", "Email", "Phone", "Meeting"])

    let sourcesChart = makeCsvSourcesChart(chartData.sources)
    lines.push(["",sourcesChart[0].value,sourcesChart[1].value,sourcesChart[2].value,sourcesChart[3].value])

    lines.push([])
    lines.push(["#Closing code"])
    // lines.push(["Received", "Rejected"])
    lines.push(["", "Received", "Rejected"])

    let closingCodeChart = makeCsvClosingCodeChart(chartData.closingCode)
    lines.push(["",closingCodeChart[0].value,closingCodeChart[1].value])

    lines.push([])
    lines.push(["#Sum of cases"])
    lines.push(["","month", "total"])

    let sumOfCases = makeCsvSumOfCasesChart(chartData.sumOfCases)
    for(let i=0; i<sumOfCases.length; i++)
    {
      lines.push(["",sumOfCases[i].month, sumOfCases[i].count])
    }

    lines.push([])
    lines.push(["#Amount of cases"])
    lines.push(["","Unassigned", "Assigned", "Closed", "Total", "Rejected"])
    lines.push(["",
      chartData.amountOfCases["Unassigned"],
      chartData.amountOfCases["Assigned"],
      chartData.amountOfCases["Closed"],
      chartData.amountOfCases["Total"],
      chartData.amountOfCases["Rejected"]
    ])


    lines.push([])
    lines.push(["#Privacy breakdown"])
    lines.push(["","Anonymous","Confidential"])

    let privacyBreakdownChart = makeCsvPrivacyBreakdownChart(chartData.privacyBreakdown)
    lines.push(["",privacyBreakdownChart[0].value,privacyBreakdownChart[1].value])

    setcsvData(lines)

    let start = dateRange.start
    let end = dateRange.end
    start = start.getFullYear()+"-"+(start.getMonth()+1).toString().padStart(2,"0")+"-"+start.getDate().toString().padStart(2,"0")
    end = end.getFullYear()+"-"+(end.getMonth()+1).toString().padStart(2,"0")+"-"+end.getDate().toString().padStart(2,"0")
    setCsvFileName('Statistics_'+start+'_to_'+end+'.csv')
  }


  const onChangeReloadData = async (newDateRange) => {
    setChartsLoading(true)
    try
    {
      let newChartData = await getDashboardChartData(newDateRange, filters.current)
      reportCases.current = newChartData
      computeChartData(newChartData.reportCases, newDateRange, newChartData.oldCasesCount)

    }
    catch(err)
    {
      console.log(err)
      alert(err.message)
    }
    setChartsLoading(false)
  }


  useEffect(() => {
    if(dateRange)
    {
      onChangeReloadData(dateRange)
    }
  }, [dateRange])




    return (
      <StyledDashboard id="chart_container">
        <H1>Dashboard</H1>
        <Row>
          <StatsCard content={{ number: loadedPageData.general.UNASSIGNED, text: 'Unassigned Cases' }}>
            <IconNewCases />
          </StatsCard>
          <StatsCard content={{ number: loadedPageData.general.ASSIGNED, text: 'Assigned Cases' }}>
            <IconOpenCases />
          </StatsCard>
          <StatsCard content={{ number: loadedPageData.general.CLOSED, text: 'Closed Cases' }}>
            <IconClosedCases />
          </StatsCard>
          <StatsCard content={{ number: loadedPageData.general.TOTAL, text: 'Total Cases' }}>
            <IconTotalCases />
          </StatsCard>
        </Row>
        {/* <Notifications /> */}

        {chartsLoading && <Loader/>}
        {!chartsLoading && 
        <>
        <div className="statistics-section">
          <Row margin="2rem auto">
            <H2>Statistics</H2>

            <div className="buttons-group">
              <Dropdown>
                <Header>
                  <span className="dropdownButtonLabel">
                    Download <i className="fa-solid fa-down-to-bracket"></i>
                  </span>
                </Header>
                <List>
                  <DropdownItem>
                    <CSVLink style={{ padding: '1rem' }} filename={csvFileName} data={csvData}>
                      <MainButton type="none">Download CSV</MainButton>
                    </CSVLink>
                  </DropdownItem>

                  <DropdownItem>
                    <button style={{ padding: '1rem', cursor:'pointer' }} onClick={downloadPDF}>
                      Download PDF
                    </button>
                  </DropdownItem>
                </List>
              </Dropdown>

              <DateRangePicker defaultStartDate={dateRange.start} defaultEndDate={dateRange.end} onDateIntervalChange={onDateRangeChange} />
              
            </div>
          </Row>
          <Row>
            <div className="selects-group">
              {/*<DropdownSelect>
                <DefaultItem value={null}>All Categories</DefaultItem>
                <Item>item 1</Item>
              </DropdownSelect>*/}
              <Select type="empty" textLabel="Select Category" label="All Categories" values={
                ([{value: null, text: "All Categories"}]).concat(
                  loadedPageData.categories.map(row => ({
                    value: row._id ? row._id.toString() : null,
                    text: row.category
                })))
                } onChange={onCategorySelected} defaultValue={filters.current.category}/>


              <Select type="empty" textLabel="Select Handler" label="All Handlers" values={
                ([{value: null, text: "All Handlers"}]).concat(
                  loadedPageData.handlers.map(row => ({
                    value: row._id ? row._id.toString() : null,
                    text: row.firstName+' '+row.lastName
                })))
                } onChange={onHandlerSelected} defaultValue={filters.current.handler}/>


              {/* <Select label=' Select Department' options={['All Departments', 'All Handlers']} />

                
                <Select label=' Select Handler' options={['All Handlers', 'Specific Handlers']} /> */}
            </div>
          </Row>
        </div>

        <div>
          
          <GridRow colTemplate={'4fr 1fr'}>
            <ChartCard>
              <h3>Active Cases</h3>
              <Cases data={chartData ? chartData.casesChart : null}/>
            </ChartCard>
            <ChartCard>
              <h3>Sources</h3>
              <Sources2 data={chartData ? chartData.sources : null}/>
            </ChartCard>
          </GridRow>
          <GridRow colTemplate={'1fr 5fr'}>
            <ChartCard>
              <h3>Closing code</h3>
              <ClosingCode data={chartData ? chartData.closingCode : null}/>
            </ChartCard>
            <ChartCard>
              <h3>Sum of cases</h3>
              <SumOfCases data={chartData ? chartData.sumOfCases : null} />
            </ChartCard>
          </GridRow>
          <GridRow colTemplate={'5fr 1fr 3fr'}>
            <ChartCard>
              <h3>Amount of cases</h3>
              <AmountOfCases data={chartData ? chartData.amountOfCases : []}/>
            </ChartCard>
            <ChartCard>
              <h3>Privacy breakdown</h3>
              <PrivacyBreakdown data={chartData ? chartData.privacyBreakdown : []}/>
            </ChartCard>
            <ChartCard style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', height: 'auto', paddingBottom: '0' }}>
              <Row align="flex-start" justify="space-between" width="-webkit-fill-available" margin="0">
                <h3>Latest Activity</h3>
                {loadedPageData.latestActivity && loadedPageData.latestActivity.length > 4 && <button style={{ marginTop: '.8rem', marginLeft: 'auto', marginRight: 0, cursor: "pointer" }} className="blue bold" onClick={() => setUi({ ...ui, latestActivityView: true })}>
                  View more
                </button>}
              </Row>
              <StyledList>
                {loadedPageData.latestActivity[0] &&
                  <div className="li-wrapper">
                    <div className="circle"></div>
                    <p className="main-item">{loadedPageData.latestActivity[0].text}</p>
                    <p className="timestamp">{buildLatestActivityTime(loadedPageData.latestActivity[0].dateTime)}</p>
                  </div>
                }
                {loadedPageData.latestActivity[1] &&
                  <div className="li-wrapper">
                    <div className="circle"></div>
                    <p className="main-item">{loadedPageData.latestActivity[0].text}</p>
                    <p className="timestamp">{buildLatestActivityTime(loadedPageData.latestActivity[1].dateTime)}</p>
                  </div>
                }
                {loadedPageData.latestActivity[2] &&
                  <div className="li-wrapper">
                    <div className="circle"></div>
                    <p className="main-item">{loadedPageData.latestActivity[0].text}</p>
                    <p className="timestamp">{buildLatestActivityTime(loadedPageData.latestActivity[2].dateTime)}</p>
                  </div>
                }
                {loadedPageData.latestActivity[3] &&
                  <div className="li-wrapper">
                    <div className="circle"></div>
                    <p className="main-item">{loadedPageData.latestActivity[0].text}</p>
                    <p className="timestamp">{buildLatestActivityTime(loadedPageData.latestActivity[3].dateTime)}</p>
                  </div>
                }
              </StyledList>
            </ChartCard>
          </GridRow>
          
        </div>
        </>
        }

        <div className="charts">
          {/* <Row> */}
          {/* <CasesChart />
              </Row>
              <SourcesChart />
              <ClosingCode />
              <SumOfCases />
              <AmountOfCases />
              <PrivacyBreakdown />
              <LatestActivity /> */}
        </div>
        <RightPanelModal shouldShow={ui.latestActivityView} onRequestClose={() => setUi({ ...ui, latestActivityView: false })}>
          <LatestActivity data={loadedPageData.latestActivity} buildLatestActivityTime={buildLatestActivityTime} />
        </RightPanelModal>
      </StyledDashboard>
    );

 
});
