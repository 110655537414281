import styled from 'styled-components/macro';

export const StyledClients = styled.div`

.file-input-button{
    width:100%;
    height: 5rem;
    background: #000;
}
`;
