import styled from 'styled-components/macro';

export const StyledAddNewCase = styled.div`
  width: 100%;
  min-height: calc(100vh - 8rem);
  display: flex;

  align-items: center;
  justify-content: center;

  h3 {
    margin-top: 0;
    margin-bottom: 0.8rem;
    font-size: 1.8rem;
  }

  .wrapper {
    min-width: 100%;
    width: 100%;
  }

  span {
    color: #969ba0;
    font-size: 1.5rem;
  }

  input {
    display: flex;
    background: #f3f4f6;
    border-radius: 12px;
    width: 100%;
    height: 30px;
    border: none;
    padding: 1rem 1rem;

    &:active,
    &:focus,
    &:hover {
      outline: none;
      border: none;
    }
  }
  .red {
    color: #861724;
  }

  textarea {
    width: 100%;
    padding: 1rem;
    background: #f3f4f6;
    border-radius: 12px;
    border: none;
    resize: none;
    font-family: inherit;

    &:active,
    &:focus {
      outline: none;
    }
  }
  button {
    height: fit-content;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0.2rem;
    font-size: 1.1rem;
  }

  label {
    font-size: 1.3rem;
  }

  
`;
