export const breachCategories = {
    PUBLIC_PROCUREMENT:'Public procurement',
    FINANCIAL:'Financial services, products and markets, and prevention of money laundering and terrorist financing',
    PRODUCT_SAFETY:'Product safety and compliance',
    TRANSPORT_SAFETY:'Transport safety',
    ENVIRONMENT:'Protection of the environment',
    RADIATION:'Radiation protection and nuclear safety',
    FOOD_AND_ANIMAL:'Food and feed safety, animal health and welfare',
    PUBLIC_HEALTH:'Public health',
    CONSUMER_PROTECTION:'Consumer protection',
    PRIVACY_AND_SECURITY:'Protection of privacy and personal data, and security of network and information systems',
    OTHER:'Other'
}

export const breachTypes = {
    ALREADY_TAKEN_PLACE: {name: "Already taken place", text:'Breach which has already taken place'},
    NOT_YET_MATERIALIZED: {name: "Not yet materialized", text:'Breach which has not yet materialized, but is very likely to take place'},
    CONSIDER_AS_BREACH: {name: "Consider as a breach", text:'Act or omission which the reporting person has reasonable grounds to consider as a breach'},
    CONCEAL_ATTEMPT: {name: "Attempt to conceal", text:'Attempt to conceal breaches'},
    SUSPICION: {name: "Suspicion", text:'No positive evidence about a breach, but the reporting person raises reasonable concerns or suspicions'}
}