import styled from 'styled-components';

export const StyledSuccess = styled.div`
  display: flex;
  flex-direction: column;

  margin: auto;
  margin-top: 8rem;
  max-width: 780px;

  p {
    width: 70%;
    text-align: center;
  }

  a,
  button {
    margin: 0 0.5rem;
    margin-bottom: 2rem;
  }

  input {
    /* padding-right: 7rem; */
    color:#484848;
    font-size: 1.3rem;
  }

  svg.copy-icon {
    width: 15px;
    position: absolute;
    right: 0.9rem;
    top: 37%;
    transform: translate(-50%, -50%);
    
    cursor: pointer;

    rect, path{
      stroke:#333333;
    }
  }
`;
