import React, { useState, useEffect, useRef} from 'react';
import { SideNavigationMenu } from '../../../../../App/theme/SideNavigationMenu';
import { AppMainLayout } from '../../../../gui/layout/AppMainLayout';
import { IconNewCases, IconOpenCases, IconClosedCases, IconTotalCases } from './icons';
import { StyledDashboard } from './Style';
import { H1 } from '../../../../gui/elements/H1';
import { H2 } from '../../../../gui/elements/H2';
import { StatsCard } from './StatsCard';
import { Row } from '../../../../gui/layout/Row';
import { MainButton } from '../../../../gui/input/MainButton';
import { Notifications } from './Notifications';
import * as htmlToImage from 'html-to-image';

import Toggle from '../../../../gui/input/Toggle';
import { ToggleOption } from '../../../../gui/input/ToggleOption';
import { Select } from '../../../../gui/input/Select';
import { RightPanelModal } from '../../../../gui/layout/RightPanelModal';
import { LatestActivity, StyledList } from './LatestActivity';
import { DatePicker, DatePickerCustom } from './DatePicker';
import { DatePickerNew } from './DatePickerNew';
import { GridRow } from '../../../../gui/layout/GridRow';
import { ChartCard } from './ChartCard';
import { Charts } from './Charts';
import { Sources2 } from './Charts/Sources2';
import { ClosingCode } from './Charts/ClosingCode';
import { PrivacyBreakdown } from './Charts/PrivacyBreakdown';
import { Cases } from './Charts/Cases';
import { SumOfCases } from './Charts/SumOfCases';
import { AmountOfCases } from './Charts/AmountOfCases';
import { CSVDownload, CSVLink } from 'react-csv';
import { csvTestData } from './csvTestData.js';
import { DateRangePicker } from '../../../../gui/input/date/DateRangePicker';
import { Dropdown, Header, List } from '../../../../gui/input/Select/Dropdown';
import { DropdownItem } from '../../../../gui/input/Select/Style';
//import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import withActionPageLoader from '../../../../hoc/withActionPageLoader';
import { getDashboardData, getDashboardChartData } from './actions';
import { Loader } from '../../../../../App/AppComponents/Loader';

// by default dashboard should show current year

export const Dashboard = withActionPageLoader(getDashboardData,({loadedPageData}) => {
  const [ui, setUi] = useState({ latestActivityView: false, datePickerView: false });
  const [dateRange, setDateRange] = useState(() => {
    let date = new Date()
    let oneYear = new Date(((new Date()).setMonth(date.getMonth() - 11))).setDate(1)
    return {start: new Date(oneYear), end: date}
  })
  const [chartsLoading, setChartsLoading] = useState(true)
  const [chartData, setChartData] = useState(null)

  const reportCases = useRef(null)

  /*const downloadPDF = () => {
    htmlToImage.toPng(document.getElementById('chart_container')).then(function (blob) {
      const doc = new jsPDF()
      doc.addImage(blob,"PNG",0,0)
      doc.save("dashboard.pdf");
      //saveAs(blob, 'dashboard.png');
    });
  }*/

  const downloadPDF = () => {
    htmlToImage.toCanvas(document.getElementById('chart_container')).then(function (canvas) {
      let fraction = 210.0 / canvas.width;
      const doc = new jsPDF();
      doc.addImage(canvas, 'JPEG', 0, 0, canvas.width * fraction, canvas.height * fraction, 'FAST');
      doc.save('dashboard.pdf');
    });
  };

  const onDateRangeChange = (dates) => {

    setDateRange({start: dates.startDate.toDate(), end: dates.endDate.toDate()})
  }

  const computeChartData = ({sales, sourceCodes}, newDateRange) => {
  
    let salesChart = {}
    let orderedSalesChart = []
    let sourceChart = {}
    let orderedSourceChart = []
    let totalSources = 0

    let currentDate = new Date(newDateRange.start);
    let endDate = new Date(newDateRange.end);
    let month = currentDate.getMonth()
    let year = currentDate.getFullYear()

    while(month !== endDate.getMonth() || year !== endDate.getFullYear())
    {
      salesChart[year+"-"+month.toString().padStart(2,"0")] = 0
      month++
      if(month >= 12)
      {
        month = 0
        year++
      }
    }
    salesChart[year+"-"+month.toString().padStart(2,"0")] = 0


    for(let i=0; i<sourceCodes.length; i++)
    {
      totalSources += sourceCodes[i].count
      sourceChart[sourceCodes[i]._id] = sourceCodes[i].count
    }

    orderedSourceChart = [
      {source: "Meeting", count: (sourceChart.MEETING ? Math.round(100.0*sourceChart.MEETING/totalSources) : 0) },
      {source: "Platform", count: (sourceChart.PLATFORM ? Math.round(100.0*sourceChart.PLATFORM/totalSources) : 0) },
      {source: "Phone", count: (sourceChart.PHONE ? Math.round(100.0*sourceChart.PHONE/totalSources) : 0) },
      {source: "Email", count: (sourceChart.EMAIL ? Math.round(100.0*sourceChart.EMAIL/totalSources) : 0) }
    ]

    for(let i=0;i<sales.length;i++)
    {
      salesChart[sales[i]._id] = sales[i].count;
    }


    currentDate = new Date(newDateRange.start)
    endDate = new Date(newDateRange.end);
    month = currentDate.getMonth()
    year = currentDate.getFullYear()

    while(month !== endDate.getMonth() || year !== endDate.getFullYear())
    {
      orderedSalesChart.push({year, month, count: salesChart[year+"-"+month.toString().padStart(2,"0")]})
      month++
      if(month >= 12)
      {
        month = 0
        year++
      }
    }
    orderedSalesChart.push({year, month, count: salesChart[year+"-"+month.toString().padStart(2,"0")]})
    
    setChartData({salesChart: orderedSalesChart, sourceChart: orderedSourceChart})
  }


  const onChangeReloadData = async (newDateRange) => {
    setChartsLoading(true)
    try
    {
      let newChartData = await getDashboardChartData(newDateRange)
      computeChartData(newChartData, newDateRange)

    }
    catch(err)
    {
      console.log(err)
      alert(err.message)
    }
    setChartsLoading(false)
  }


  useEffect(() => {
    if(dateRange)
    {
      onChangeReloadData(dateRange)
    }
  }, [dateRange])


    return (
      <StyledDashboard id="chart_container">
        <H1>Dashboard</H1>
        <Row>
          <StatsCard content={{ number: loadedPageData.organizationCount, text: 'All clients' }}>
            <IconNewCases />
          </StatsCard>
          <StatsCard content={{ number: loadedPageData.reportCasesCount, text: 'All cases' }}>
            <IconOpenCases />
          </StatsCard>
          <StatsCard content={{ number: loadedPageData.organizationCount, text: 'Total demo sign ups' }}>
            <IconClosedCases />
          </StatsCard>
          <StatsCard content={{ number: loadedPageData.customerCount, text: 'Total sales' }}>
            <IconTotalCases />
          </StatsCard>
        </Row>
        {/* <Notifications /> */}

        {chartsLoading && <Loader />}
        {!chartsLoading && 
        <>
        <div className="statistics-section">
          <Row margin="5rem auto">
            <H2>Statistics</H2>

            <div className="buttons-group">

              <DateRangePicker defaultStartDate={dateRange.start} defaultEndDate={dateRange.end} onDateIntervalChange={onDateRangeChange}/>
            </div>
          </Row>
        </div>

        <div>
          <GridRow colTemplate={'4fr 1fr'}>
            <ChartCard>
              <h3>Sales</h3>
              <Cases data={chartData ? chartData.salesChart : []}/>
            </ChartCard>
            <ChartCard>
              <h3>Cases</h3>
              <Sources2 data={chartData ? chartData.sourceChart : []}/>
            </ChartCard>
          </GridRow>
        </div>
        </>
      }

        <RightPanelModal shouldShow={ui.latestActivityView} onRequestClose={() => setUi({ ...ui, latestActivityView: false })}>
          <LatestActivity />
        </RightPanelModal>
      </StyledDashboard>
    );
});
