import React from 'react';

export const IconNewCases = () => {
  return (
    <svg width='83' height='80' viewBox='0 0 83 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='82.6748' height='80' rx='12' fill='#DDECFF' fillOpacity='0.81' />
      <rect x='22' y='24.4445' width='35.5556' height='35.5556' rx='11' fill='#418EEB' stroke='#418EEB' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M30 42.2223H39.7778' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M30 49.3333H49.5556' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M53.1111 36C48.6928 36 45.1111 32.4183 45.1111 28C45.1111 23.5817 48.6928 20 53.1111 20C57.5294 20 61.1111 23.5817 61.1111 28C61.1111 32.4183 57.5294 36 53.1111 36'
        fill='white'
      />
      <path
        d='M53.1111 36C48.6928 36 45.1111 32.4183 45.1111 28C45.1111 23.5817 48.6928 20 53.1111 20C57.5294 20 61.1111 23.5817 61.1111 28C61.1111 32.4183 57.5294 36 53.1111 36'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path fillRule='evenodd' clipRule='evenodd' d='M53.1112 27.7039V24.7679Z' fill='white' />
      <path d='M53.1112 27.7039V24.7679' stroke='#418EEB' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M53.1111 30.9424C53.0727 30.9424 53.0358 30.9578 53.0088 30.9851C52.9817 31.0125 52.9667 31.0495 52.9671 31.088C52.9672 31.147 53.0028 31.2002 53.0574 31.2226C53.112 31.2451 53.1748 31.2323 53.2163 31.1903C53.2578 31.1484 53.2698 31.0855 53.2468 31.0311C53.2238 30.9768 53.1702 30.9417 53.1111 30.9424'
        fill='white'
      />
      <path
        d='M53.1111 30.9424C53.0727 30.9424 53.0358 30.9578 53.0088 30.9851C52.9817 31.0125 52.9667 31.0495 52.9671 31.088C52.9672 31.147 53.0028 31.2002 53.0574 31.2226C53.112 31.2451 53.1748 31.2323 53.2163 31.1903C53.2578 31.1484 53.2698 31.0855 53.2468 31.0311C53.2238 30.9768 53.1702 30.9417 53.1111 30.9424'
        stroke='#418EEB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const IconOpenCases = () => {
  return (
    <svg width='82' height='80' viewBox='0 0 82 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='81.4305' height='80' rx='12' fill='#FFF0ED' />
      <rect x='21' y='20' width='40' height='40' rx='11' fill='#FF6D4D' stroke='#FF6D4D' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M29.889 31.1111H52.1112Z' fill='#FF6D4D' />
      <path d='M29.889 31.1111H52.1112' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M29.889 40H36.0618Z' fill='#FF6D4D' />
      <path d='M29.889 40H36.0618' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M29.889 48.8889H36.0618Z' fill='#FF6D4D' />
      <path d='M29.889 48.8889H36.0618' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.093 48.8889C48.5476 48.8889 50.5375 46.899 50.5375 44.4444C50.5375 41.9898 48.5476 40 46.093 40C43.6384 40 41.6486 41.9898 41.6486 44.4444C41.6486 46.899 43.6384 48.8889 46.093 48.8889Z'
        fill='#FF6D4D'
        stroke='white'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path fillRule='evenodd' clipRule='evenodd' d='M48.9005 47.8693L52.1112 51.1112Z' fill='#FF6D4D' />
      <path d='M48.9005 47.8693L52.1112 51.1112' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const IconClosedCases = () => {
  return (
    <svg width='82' height='80' viewBox='0 0 82 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='81.4305' height='80' rx='12' fill='#CEF5EF' fillOpacity='0.79' />
      <rect x='20' y='23.6364' width='36.3636' height='36.3636' rx='11' fill='#00A389' stroke='#00A389' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M28.1818 41.8181H38.1818' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M28.1818 49.0909H48.1818' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52.7273 36.3636V36.3636C48.2082 36.3636 44.5455 32.7009 44.5455 28.1818V28.1818C44.5455 23.6627 48.2082 20 52.7273 20V20C57.2464 20 60.9091 23.6627 60.9091 28.1818V28.1818C60.9091 32.7009 57.2464 36.3636 52.7273 36.3636Z'
        fill='white'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M56.3636 26.3636L51.8182 30.9091L49.0909 28.1818' stroke='#00A389' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const IconTotalCases = () => {
  return (
    <svg width='82' height='80' viewBox='0 0 82 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='81.4305' height='80' rx='12' fill='#FFE3E7' fillOpacity='0.8' />
      <rect x='18' y='18' width='36.6667' height='36.6667' rx='11' fill='#DAB1B4' stroke='#DAB1B4' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <rect x='25.3333' y='24.4166' width='36.6667' height='36.6667' rx='11' fill='#B03A48' stroke='#B03A48' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M39.0833 42.7499H53.75' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M39.0833 34.4999H53.75' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M39.0833 50.9999H53.75' stroke='white' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M33.5742 34.037C33.3175 34.037 33.1158 34.2479 33.1158 34.5045C33.1158 34.7612 33.3267 34.9629 33.5833 34.9629C33.84 34.9629 34.0508 34.752 34.0508 34.5045C34.0417 34.2479 33.8308 34.037 33.5742 34.037'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M33.5742 42.287C33.3175 42.287 33.1158 42.4979 33.1158 42.7545C33.1158 43.0112 33.3267 43.2129 33.5833 43.2129C33.84 43.2129 34.0508 43.002 34.0508 42.7545C34.0417 42.4979 33.8308 42.287 33.5742 42.287'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M33.5742 50.537C33.3175 50.537 33.1158 50.7479 33.1158 51.0045C33.1158 51.2612 33.3267 51.4629 33.5833 51.4629C33.84 51.4629 34.0508 51.252 34.0508 51.0045C34.0417 50.7479 33.8308 50.537 33.5742 50.537'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
