import styled from 'styled-components';

export const StyledReportACasePage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0rem;
  width:100%;

p, ol{
  font-size:1.40rem;
  
  &.bold{
    font-size: 1.3rem;
    text-align:center;
  }
}

h1{
  text-align:left;
  margin-right:auto;
  font-size: 2.75rem;
}

ol{
  margin:0;
  padding:2rem;
}

.card{
  box-shadow: 0px 7px 21px rgba(0, 0, 0, 0.09);
  padding:2rem 1rem;
  padding-top:3rem;
  justify-content:flex-start;

  &:last-of-type{
    padding-bottom:1rem;
  }
}

svg{
  width: 4rem;
}

  .main-content {
    max-width: 800px;
    padding: 4rem 1rem;

    ol {
      counter-reset: list;
      padding: 0;
    }
    ol > li {
      list-style: none;
    }
    ol > li:before {
      content: counter(list, numbers) ') ';
      counter-increment: list;
    }

    .buttons {
      > button {
        margin-right: 1rem;

        &:nth-of-type(2) {
          margin-left: 1rem;
        }
      }
    }
  }
`;
