export const IconList = () => {
  return (
    <svg className='notification-type-icon' width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='21' cy='21' r='21' fill='#CEF5EF' fillOpacity='0.79' />
      <path fillRule='evenodd' clipRule='evenodd' d='M17 13L18.3 11.375C18.49 11.138 18.777 11 19.081 11H22.92C23.224 11 23.511 11.138 23.701 11.375L25 13V13V14C25 14.552 24.552 15 24 15H18C17.448 15 17 14.552 17 14V13V13H17Z' stroke='#027D6A' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17 13H15C13.895 13 13 13.895 13 15V28C13 29.105 13.895 30 15 30H27C28.105 30 29 29.105 29 28V15C29 13.895 28.105 13 27 13H25' stroke='#027D6A' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17 20H25' stroke='#027D6A' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17 25H25' stroke='#027D6A' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const IconBell = () => {
  return (
    <svg className='notification-type-icon' width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='21' cy='21' r='21' fill='#DDECFF' fill-opacity='0.81' />
      <path
        d='M28.5 22.5758V20.1825C28.4975 18.3304 27.8044 16.5459 26.5561 15.1777C25.3078 13.8096 23.5941 12.9562 21.75 12.7844V11.25C21.75 11.0511 21.671 10.8603 21.5303 10.7197C21.3897 10.579 21.1989 10.5 21 10.5C20.8011 10.5 20.6103 10.579 20.4697 10.7197C20.329 10.8603 20.25 11.0511 20.25 11.25V12.7846C18.4059 12.9563 16.6923 13.8097 15.4439 15.1778C14.1956 16.5459 13.5025 18.3305 13.5 20.1825V22.5757C12.6536 22.7496 11.8931 23.2101 11.3466 23.8794C10.8002 24.5487 10.5011 25.3859 10.5 26.25C10.5007 26.8465 10.7379 27.4185 11.1597 27.8403C11.5815 28.2621 12.1535 28.4993 12.75 28.5H17.3258C17.4988 29.3468 17.9589 30.1079 18.6284 30.6545C19.2979 31.2011 20.1357 31.4997 21 31.4997C21.8643 31.4997 22.7021 31.2011 23.3716 30.6545C24.0411 30.1079 24.5012 29.3468 24.6742 28.5H29.25C29.8465 28.4993 30.4185 28.2621 30.8403 27.8403C31.2621 27.4185 31.4993 26.8465 31.5 26.25C31.4988 25.386 31.1998 24.5487 30.6533 23.8795C30.1068 23.2102 29.3463 22.7498 28.5 22.5758ZM15 20.1825C15.002 18.6097 15.6277 17.1019 16.7398 15.9898C17.8519 14.8777 19.3597 14.252 20.9325 14.25H21.0675C22.6403 14.252 24.1481 14.8777 25.2602 15.9898C26.3723 17.1019 26.998 18.6097 27 20.1825V22.5H15V20.1825ZM21 30C20.5362 29.9986 20.0842 29.8536 19.7061 29.5851C19.328 29.3165 19.0423 28.9375 18.8882 28.5H23.1118C22.9577 28.9375 22.672 29.3165 22.2939 29.5851C21.9158 29.8536 21.4638 29.9986 21 30ZM29.25 27H12.75C12.5511 26.9999 12.3604 26.9208 12.2198 26.7802C12.0792 26.6396 12.0001 26.4489 12 26.25C12.0007 25.6535 12.2379 25.0815 12.6597 24.6597C13.0815 24.2379 13.6535 24.0007 14.25 24H27.75C28.3465 24.0007 28.9185 24.2379 29.3403 24.6597C29.7621 25.0815 29.9993 25.6535 30 26.25C29.9999 26.4489 29.9208 26.6396 29.7802 26.7802C29.6396 26.9208 29.4489 26.9999 29.25 27Z'
        fill='#418EEB'
      />
    </svg>
  );
};

export const IconTimeMarker = () => {
  return (
    <svg className='clock-icon' width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='7' cy='7' r='6' stroke='#ADADAD' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.30267 7.76733L7 7V3' stroke='#ADADAD' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};
