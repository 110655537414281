import api from '../../../../utils/api'

export const requestPassworResetEmail = async (email) => {
    const {data} = await api.get(`/auth/reset-link/${email}`)
    return data
}

export const resetPassword = async ({password, jwt}) => {
    const {data} = await api.post('/auth/reset-password/',{password, jwt})
    return data
}