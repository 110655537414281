import api from "../../../../utils/api"
import store from "../../../../store"
import { breachTypes } from "../../../../data/reportCase";

export const getListOfCases = async () => {
    try
    {
        const {data} = await api.get('/report-cases')

        let reportCases = data.reportCases;
        let count = 0;

        let report = null

        for(let i=0;i<reportCases.length;i++)
        {
            report = reportCases[i]
            reportCases[i].breachType = breachTypes[report.caseType]?.name || '--error--'
            if(report.handler === null && report.statusCode === "ONGOING")
            {
                count++
            }
        }

        store.dispatch({ type: 'OPENED_CASES', payload:{openedReportCases: count} })
        return data
    }
    catch(err)
    {
        store.dispatch({ type: 'OPENED_CASES', openedReportCases: -1 })
        console.log(err)
        return []
    }
}