import React from 'react';
import { Row } from '../../../../../gui/layout/Row';
import { IconBell, IconList, IconTimeMarker } from './icons';
import { IconNotifications } from '../../../../../../App/theme/Header/icons';

import {breachTypes} from '../../../../../../data/reportCase'
import { useNavigate } from 'react-router-dom';

const computeTime = (createdDate) => {
  let dif =  (new Date()).getTime() - (new Date(createdDate)).getTime()
  if(dif > 1000*60*60*24)
  {
    return Math.floor(dif/(1000*60*60*24))+"d"
  }
  else if(dif > 1000*60*60)
  {
    return Math.floor(dif/(1000*60*60))+"h"
  }
  else if(dif > 1000*60)
  {
    return Math.floor(dif/(1000*60))+"m"
  }
  else
  {
    return Math.floor(dif/1000)+"s"
  }
}

export const NotificationItem = ({ notification }) => {

  const navigate = useNavigate()

  const navigateToCase = (id) => {
    navigate('/all-cases/'+id)
  }

  const typeHandler = () => {
    if (notification.type === 'DEADLINE_REMINDER_OVERDUE') {
      return (
        <>
          <IconBell onClick={() => navigateToCase(notification.reportCase)} />
          <div onClick={() => navigateToCase(notification.reportCase)}>
            <p>A case of type <b>‘{breachTypes[notification.details.caseTypeCode].name}’</b> is overdue.</p>
        
            <div className="time-marker">
              <IconTimeMarker />
              <span>
                {computeTime(notification.createdAt)}
              </span>
            </div>
          </div>
        </>
      );
    }
    if(notification.type === 'DEADLINE_REMINDER_3') {
      return (
        <>
          <IconBell onClick={() => navigateToCase(notification.reportCase)} />
          <div onClick={() => navigateToCase(notification.reportCase)}>
            <p>A case of type <b>‘{breachTypes[notification.details.caseTypeCode].name}’</b> has less than 3 days left before the deadline.</p>
        
            <div className="time-marker">
              <IconTimeMarker />
              <span>
                {computeTime(notification.createdAt)}
              </span>
            </div>
          </div>
        </>
      );
    }
    if(notification.type === 'DEADLINE_REMINDER_1') {
      return (
        <>
          <IconBell onClick={() => navigateToCase(notification.reportCase)} />
          <div onClick={() => navigateToCase(notification.reportCase)}>
            <p>A case of type <b>‘{breachTypes[notification.details.caseTypeCode].name}’</b> has less than 1 day left before the deadline.</p>
        
            <div className="time-marker">
              <IconTimeMarker />
              <span>
                {computeTime(notification.createdAt)}
              </span>
            </div>
          </div>
        </>
      );
    }
    if(notification.type === "DEADLINE_CHANGED") {
      return (
        <>
          <IconList onClick={() => navigateToCase(notification.reportCase)} />
          <div onClick={() => navigateToCase(notification.reportCase)}>
            <p><b>{notification.details.createdBy}</b> has changed the deadline of a case <b>‘{breachTypes[notification.details.caseTypeCode].name}’</b> to {new Date(notification.details.deadline).toLocaleDateString('en-GB') }.</p>
        
            <div className="time-marker">
              <IconTimeMarker />
              <span>
                {computeTime(notification.createdAt)}
              </span>
            </div>
          </div>
        </>
      );
    }
    if(notification.type === 'PRIORITY_CHANGE') {
      return (
        <>
          <IconList onClick={() => navigateToCase(notification.reportCase)} />
          <div onClick={() => navigateToCase(notification.reportCase)}>
            <p><b>{notification.details.createdBy}</b> has changed the priority of a case <b>‘{breachTypes[notification.details.caseTypeCode].name}’</b> to {notification.details.priority}.</p>
        
            <div className="time-marker">
              <IconTimeMarker />
              <span>
                {computeTime(notification.createdAt)}
              </span>
            </div>
          </div>
        </>
      );
    }
    if(notification.type === 'CASE_RESOLVED') {
      return (
        <>
          <IconList onClick={() => navigateToCase(notification.reportCase)} />
          <div onClick={() => navigateToCase(notification.reportCase)}>
            <p><b>{notification.details.createdBy}</b> has resolved a case <b>‘{breachTypes[notification.details.caseTypeCode].name}’</b>.</p>
        
            <div className="time-marker">
              <IconTimeMarker />
              <span>
                {computeTime(notification.createdAt)}
              </span>
            </div>
          </div>
        </>
      );
    }
    if(notification.type === 'DESCRIPTION_CHANGED') {
      return (
        <>
          <IconList onClick={() => navigateToCase(notification.reportCase)} />
          <div onClick={() => navigateToCase(notification.reportCase)}>
            <p><b>{notification.details.createdBy || "The whistleblower"}</b> has changed the description of a case <b>‘{breachTypes[notification.details.caseTypeCode].name}’</b>.</p>
        
            <div className="time-marker">
              <IconTimeMarker />
              <span>
                {computeTime(notification.createdAt)}
              </span>
            </div>
          </div>
        </>
      );
    }
    if(notification.type === 'NEW_MESSAGE') {
      return (
        <>
          <IconList onClick={() => navigateToCase(notification.reportCase)} />
          <div onClick={() => navigateToCase(notification.reportCase)}>
            <p><b>{notification.details.createdBy || "The whistleblower"}</b> has posted a new message on a case <b>‘{breachTypes[notification.details.caseTypeCode].name}’</b>.</p>
        
            <div className="time-marker">
              <IconTimeMarker />
              <span>
                {computeTime(notification.createdAt)}
              </span>
            </div>
          </div>
        </>
      );
    }
    if(notification.type === 'NEW_MEDIA') {
      return (
        <>
          <IconList onClick={() => navigateToCase(notification.reportCase)} />
          <div onClick={() => navigateToCase(notification.reportCase)}>
            <p><b>{notification.details.createdBy || "The whistleblower"}</b> has added a new file to a case <b>‘{breachTypes[notification.details.caseTypeCode].name}’</b>.</p>
        
            <div className="time-marker">
              <IconTimeMarker />
              <span>
                {computeTime(notification.createdAt)}
              </span>
            </div>
          </div>
        </>
      );
    }
    if(notification.type === 'CASE_ASSIGNED') {
      return (
        <>
          <IconBell onClick={() => navigateToCase(notification.reportCase)} />
          <div onClick={() => navigateToCase(notification.reportCase)}>
            <p><b>{notification.details.createdBy}</b> has assigned you a case of type <b>‘{breachTypes[notification.details.caseTypeCode]? breachTypes[notification.details.caseTypeCode].name : <strong>--ERROR--</strong>}’</b>.</p>
        
            <div className="time-marker">
              <IconTimeMarker />
              <span>
                {computeTime(notification.createdAt)}
              </span>
            </div>
          </div>
        </>
      );
    }
    if(notification.type === 'CASE_UNASSIGNED') {
      return (
        <>
          <IconBell onClick={() => navigateToCase(notification.reportCase)} />
          <div onClick={() => navigateToCase(notification.reportCase)}>
            <p><b>{notification.details.createdBy}</b> has unassigned your case of type <b>‘{breachTypes[notification.details.caseTypeCode].name}’</b>.</p>
        
            <div className="time-marker">
              <IconTimeMarker />
              <span>
                {computeTime(notification.createdAt)}
              </span>
            </div>
          </div>
        </>
      );
    }
    return <>--error--</>
  }

  return (
    <Row align="center" justify="center" margin="0">
      {/*<IconList />*/}
      {typeHandler()}
    </Row>
  );
};
