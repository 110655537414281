import PieChart, { Legend, Series, Tooltip, Format, Label, Connector, Export, Size } from 'devextreme-react/pie-chart';
import styled from 'styled-components';

/*const data = [
  { source: 'Phone', value: '28' },
  { source: 'System', value: '50' },
  { source: 'Email', value: '22' },
];*/

const StyledPieChart = styled.div``;

export const Sources2 = ({data}) => {
  return (
    <>
    <StyledPieChart>
      <PieChart id="pie" type="doughnut" palette={['#ff6d4c', '#418eeb', '#00a388']} dataSource={data}>
        <Size width={300} height={300} />
        <Series argumentField="source" valueField="count" innerRadius={0.78}>
          <Label visible={true} customizeText={(data) => data.value + '%'}>
            <Connector visible={true}></Connector>
          </Label>
        </Series>
        <Export enabled={false} />
        <Legend margin={0} horizontalAlignment="center" verticalAlignment="bottom" />
        {/*<Tooltip enabled={true} customizeTooltip={this.customizeTooltip}>
              <Format type="percent" />
              </Tooltip>*/}
      </PieChart>
    </StyledPieChart>
    </>
  );
};
