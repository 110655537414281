import withActionPageLoader from "../../../../../hoc/withActionPageLoader"
import { finishSubscription } from "./actions"
import { SubscriptionBase } from "../index"
import styled from 'styled-components';

const Banner = styled.div`
    
    color:white;
    padding: 0.6rem auto;
    font-size: 1.15rem;
    

    &.success{
        background-color: #027D6A;
        color: white;
        font-weight: bold;
        padding: 0.6rem 1rem;
        border-radius: 0.9rem;
    }

    &.failed{
        background: #861724;
        color: white;
        font-weight: bold;
        padding: 0.6rem 1rem;
        border-radius: 0.9rem;
    }
`;

export const Success = withActionPageLoader(finishSubscription,({loadedPageData, reloadPageData}) => {
    if(loadedPageData === null)
    {
        <h3>Invalid request</h3>
    }


    return (
        <>
            <Banner className="success">Success!</Banner>
            <SubscriptionBase loadedPageData={loadedPageData} reloadPageData={reloadPageData}/>
        </>
    )

})

export const Failed = withActionPageLoader(finishSubscription,({loadedPageData, reloadPageData}) => {
    if(loadedPageData === null)
    {
        <h3>Invalid request</h3>
    }


    return (
        <>
            <Banner className="failed">Subscription registration failed</Banner>
            <SubscriptionBase loadedPageData={loadedPageData} reloadPageData={reloadPageData}/>
        </>
    )

})