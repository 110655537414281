import { loadMedia } from './MainContent/Media/actions';
import { getHandlers } from './MainContent/actions/accessActions';
import { fetchMessages } from './MainContent/actions/messageActions';
import { loadLatestActivity } from './MainContent/actions/historyActions';
import { breachCategories, breachTypes } from '../../../../../../data/reportCase';

const statusCodeMap = { NEW: 'New', ONGOING: 'Ongoing', CLOSED: 'Closed', DELETED: 'Rejected'};
const sourceCodeMap = { PHONE: 'Phone', EMAIL: 'Email', SYSTEM: 'System', PLATFORM: 'Platform'};
const caseTypeCodeMap = { FRAUD: 'Fraud' };
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const formatDate = (inputDate) => {
  let date = new Date(inputDate);
  let half = 'AM';
  let hours = date.getHours();
  if (hours === 0) {
    hours = 12;
  } else if (hours === 12) {
    half = 'PM';
  } else if (hours > 12) {
    hours -= 12;
    half = 'PM';
  }

  return date.getDate().toString().padStart(2, '0') + ' ' + months[date.getMonth()] + ' ' + date.getFullYear() + ', ' + hours.toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') + ' ' + half;
};

const formatMediaDate = (inputDate) => {
    let date = new Date(inputDate);
    return (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getDate().toString().padStart(2, '0') + '/' + date.getFullYear();
};

const formatSize = (numBytes) => {
    if (numBytes < 1000) {
      return '1kb';
    } else if (numBytes < 1000000) {
      return Math.floor(numBytes / 100) / 10 + 'kb';
    } else {
      return Math.floor(numBytes / 100000) / 10 + 'mb';
    }
};

const processMessages = (loadedPageData, authId) => {
    let newMessageList = [];
    let prevId = 0;
    let message = null;

    for (let i = 0; i < loadedPageData.messages.length; i++)
    {
        message = loadedPageData.messages[i];

        newMessageList.push({
            isMe: authId === message.userId,
            text: message.text,
            dateTime: i + 1 >= loadedPageData.messages.length || message.userId !== loadedPageData.messages[i + 1].userId ? formatDate(message.dateTime) : null,
            unixTime: message.unixTime
        });
    }

    newMessageList.sort((a,b) => a.unixTime - b.unixTime)

    return newMessageList;
}


const formatActivityTime = (dateTime) => {
    let d = new Date(dateTime)
    return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}, ${d.getHours().toString().padStart(2,'0')}:${d.getMinutes().toString().padStart(2,'0')}`
}


export const exportCase = async (caseData, authId, fromToken) => {

    let mediaData = await loadMedia({caseData, fromToken});
    let accessData = await getHandlers({caseId: caseData._id, fromToken});
    let messagesData = await fetchMessages({caseData, fromToken});
    let messageList = processMessages(messagesData, (fromToken ? caseData._id : authId));

    let activityData = await loadLatestActivity({caseId: caseData._id, fromToken});

const confidentialityMap = {CONFIDENTIAL: 'Confidential', ANONYMOUS: 'Anonymous'};


const toReturn = `
<html>
<body>
<style>

html, body {
    width: 100%;
    margin: 0px;
    padding: 0px;
}

* {
    box-sizing: border-box;
}

.container {
    display: block;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    background: #ffffff;
    padding: 20px;
}

.caseMainInfo {
    padding-left: 60px;
    padding-right: 60px;
}

.caseInfoRow {
    display: grid;
    column-gap: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    row-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
}

.text-box label {
    color: #8a8a8a;
    font-weight: 500;
}

.text-box p {
    margin: 10px;
}

.caseDescriptionContainer p {
width: 100%;
}

.caseEvidenceContainer {
    min-height: 100vh;
    padding-left: 60px;
    padding-right: 60px;
}

.photosGrid {
    display: grid;
    column-gap: 12px;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 12px;
}

.filesGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 12px;
    row-gap: 12px;
}


.caseFileInfo {
    color: #858585;
}

.item-name {
    color: #333333;
}

.caseAccessContainer {
    padding-left: 60px;
    padding-right: 60px;
}

.accessTable{
    display: table;
    border-collapse: separate;
    border-color: grey;
    width: 100%;
}

.accessTable tr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    border-color: grey;
    line-height: 19.5px;
    margin-bottom: 4px;
    margin-top: 4px;
    padding-left: 15px;
    padding-right: 15px;
    vertical-align: middle;
}

.accessTable tbody tr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    border-color: grey;
    line-height: 19.5px;
    margin-bottom: 4px;
    margin-top: 4px;
    padding-left: 15px;
    padding-right: 15px;
    vertical-align: middle;
}

.caseMessagesContainer {
    padding-left: 60px;
    padding-right: 60px;
    margin-bottom: 60px;
}

.chat-header h3 {
    font-weight: bold;
    margin-left: 2rem;
}

.chat-main-box {
    padding-left: 60px;
    padding-right: 60px;
}

.chat-message{
    background: #f1f1f1;
    border-radius: 18px;
    font-size: 14px;
    line-height: 21px;
    margin-block-end: 14px;
    margin-block-start: 14px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 40px;
    padding-top: 10px;
    width: fit-content;
    position: relative;
}

.our-message {
    background: #418eeb;
    color: white;
    margin-left: auto;
}

.chat-main-box .timestamp{
    color: #a5a5a5;
    font-size: 11.5px;
    line-height: 17.25px;
    position: absolute;
    top: 53.3px;
    width: 100%;
}

.caseHistoryContainer{
    padding-left: 60px;
    padding-right: 60px;
}

.latestActivity {
    font-size: 12.8px;
    line-height: 19.2px;
    margin-bottom: 20px;
    margin-top: 20px;
    min-width: fit-content;
    width: 100%;
}

.li-wrapper {
    position: relative;
    border-left: solid 2px #dddddd;
    padding-left: 16px;
    padding-bottom: 30px;
}

.circle {
    position: absolute;
    left: -6.5px;
    display: inline-block;
    width: 12px;
    height: 12px;
    background: white;
    border: solid 2px #418eeb;
    border-radius: 50%;
}

.li-wrapper:first-of-type .circle {
    background: #418eeb;
}

.caseHistoryContainer p {
    margin: 0;
    transform: translateY(-4px);
}

.caseHistoryContainer .li-wrapper:first-of-type p.main-item {
    font-weight: bold;
}

.caseHistoryContainer p.timestamp {
    color: #a5a5a5;
}

.blue-status {
    background: rgba(221, 236, 255, 0.81);
    color: #418eeb;
  }

  .red-status {
    background: #fff0ed;
    color: #ff6d4d;
  }

  .green-status {
    background: #bfe8e1;
    color: #00a389;
  }
    
</style>

<div class="container">

<h2>Case main info</h2>
<div class="caseMainInfo">
    <div class="caseInfoRow">
        <div class="text-box">
            <label>Breach Category</label>
            <p>${caseData.breachCategory}</p>
        </div>
        <div class="text-box">
            <label>Breach type</label>
            <p>${breachTypes[caseData.caseType]?.text || '--error--'}</p>
        </div>
        <div class="text-box">
            <label>Created Date</label>
            <p>${formatDate(caseData.createdDate)}</p>
        </div>
        <div class="text-box">
            <label>Status</label>
            <p class="${`status-buttons ${caseData.statusCode === 'NEW' && 'blue-status'} ${caseData.statusCode === 'ONGOING' && 'red-status'} ${(caseData.statusCode === 'CLOSED' || caseData.statusCode === 'DELETED') && 'green-status'}`}">${statusCodeMap[caseData.statusCode]}</p>
        </div>

        <div class="text-box">
            <label>Confidentiality</label>
            <p>${caseData.anonymity ? confidentialityMap[caseData.anonymity] : (caseData.isAnonymous ? 'Anonymous' : 'Confidential')}</p>
        </div>
        <div class="text-box">
            <label>Source</label>
            <p>${sourceCodeMap[caseData.source]}</p>
        </div>
        <div class="text-box">
            <label>Created By</label>
            <p>${caseData.anonymity === "ANONYMOUS" ? '<i>Anonymous</i>' : (caseData.createdBy || '<i>Anonymous</i>')}</p>
        </div>
        <div class="text-box">
            <label>Company</label>
            <p>${caseData.company || ''}</p>
        </div>

        <div class="text-box">
            <div class="text-box">
                <label>Deadline</label>
                <p>${formatDate(caseData.deadline)}</p>
            </div>
        </div>
        <div class="text-box">
            <div class="text-box">
                <label>Priority</label>
                <p>${caseData.priority.toLowerCase().charAt(0).toUpperCase()+caseData.priority.slice(1).toLowerCase()}</p>
            </div>
        </div>
    </div>
</div>
<hr>
<h2>Case description</h2>
<div class="caseDescriptionContainer" style="padding-top: 2rem;">
    <div style="margin-top: 0px;">
        <div class="sc-bczRLJ hfpyDg">
            <div>
                <p class="description-dates" style="margin-top: 0px;">Created date : ${new Date(caseData.createdDate).toLocaleDateString('en-GB')}</p>
                <p class="description-dates">Last updated date : ${new Date(caseData.lastUpdateDate).toLocaleDateString('en-GB')}</p>
            </div>
        </div>
    </div>
    ${caseData.description.split('\n').map((text) => `
        <p>${text}</p>
    `).join('')}
    </div>
<hr>

<h2>Case evidence</h2>
<div class="caseEvidenceContainer">
    <div class="sc-eFWqGp dZgdEJ">
        ${(() => {
            if(mediaData.photos.length + mediaData.files.length > 0)
            {
                return `
                    <div>
                        <h3>Photos</h3>
                        <div class="photosGrid">
                            ${mediaData.photos.map(photo => `
                                <div class="sc-bWXABl frZRxL">
                                    <div class="caseFile">
                                        <span class="item-name">${photo.fileName}</span>
                                        <div class="caseFileInfo">
                                            <span class="item-date">${formatMediaDate(photo.uploadedAt)}</span>
                                            <span class="item-size">&nbsp;${formatSize(photo.fileSize)}</span>
                                        </div>
                                    </div>
                                </div>
                            `).join('')}
                            ${mediaData.photos.length === 0 ? '<div>No photos has been attached.</div>' : ''}
                        </div>
                    </div>
                    <div>
                        <h3>Files</h3>
                        <div class="filesGrid">
                            ${mediaData.files.map(file => `
                                <div class="sc-bWXABl frZRxL">
                                    <div class="caseFile">
                                        <span class="item-name">${file.fileName}</span>
                                        <div class="caseFileInfo">
                                            <span class="item-date">${formatMediaDate(file.uploadedAt)}</span>
                                            <span class="item-size">&nbsp;${formatSize(file.fileSize)}</span>
                                        </div>
                                    </div>
                                </div>
                            `).join('')}
                            ${mediaData.files.length === 0 ? '<div>No files has been attached.</div>' : ''}
                        </div>
                    </div>
                `
            }
            else
            {
                return `<p>No file has been attached</p>`
            }
        })()}
    </div>
</div>
<hr>
<h2>Case access</h2>
<div class="caseAccessContainer">
    <div class="sc-dFdIVH hySRSL">
        <table class="accessTable">
            <thead>
                <tr>
                    <th>Handler</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                ${accessData.map(data => `
                <tr>
                    <td>${data.name}</td>
                    <td class="status-buttons ${data.status === "ACTIVE" ? 'green-status' : 'red-status'}" style="width: 9.06rem;">${data.status}</td>
                </tr>
                `).join('')}
            </tbody>
        </table>
    </div>
</div>
<hr>
<h2>Case messages</h2>
<div class="caseMessagesContainer">
    <div class="sc-brCFrO dIwfwS">
        <div class="chat-header">
            <h3>${messagesData?.handler}</h3>
        </div>
        <div class="chat-main-box">
            ${messageList.map((message) => `
                <p class="${`chat-message ${message.isMe ? 'our-message' : 'not-our-message'}"`}>
                    ${message.text}
                    ${message.dateTime ? `<span class="timestamp">${message.dateTime}</span>` : ''}
                </p>
            `).join('')}
            ${messageList.length === 0 ? '<p>No message has been sent</p>' : ''}
        </div>
    </div>
    </div>
    <hr>
<h2>Case history</h2>
<div class="caseHistoryContainer">
    <div class="latestActivity">
        ${activityData.map(entry => `
            <div class="li-wrapper">
                <div class="circle"></div>
                <p class="main-item">${entry.text}</p>
                <p class="timestamp">${formatActivityTime(entry.dateTime)}</p>
            </div>
        `).join('')}
    </div>
</div>
</body>
</html>
`

return toReturn;

}