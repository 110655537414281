import api from '../../../../../utils/api';

export const getDepartments = async () => {
  const { data } = await api.get('departments/');
  return data;
};

export const changeDepartmentStatus = async (departmentId, statusCode) => {
  const { data } = await api.patch('departments/status', { departmentId, statusCode });
  return data;
};

export const makeNewDepartment = async (values) => {
  const { data } = await api.post('/departments', values);
  return data;
};

export const deleteDepartment = async (departmentId) => {
  const { data } = await api.delete('departments/'+departmentId);
  return data;
}

export const editDepartment = async (values, id) => {
  const { data } = await api.patch('/departments/'+id, values);
  return data;
}
