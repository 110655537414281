export const WhistlawLogoSvg = () => {
  return (
    <svg className='logo' width='173' height='39' viewBox='0 0 173 39' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M61.3356 0C56.6442 0 53.8981 3.64812 53.8981 7.77201C53.8981 12.3732 57.2319 15.513 61.3356 15.513H62.1068C63.111 15.5151 64.1058 15.3196 65.0342 14.9376C65.9626 14.5555 66.8064 13.9945 67.5173 13.2866C68.2282 12.5787 68.7922 11.7378 69.1771 10.8121C69.5621 9.88638 69.7603 8.89397 69.7605 7.89169V4.44748L73.0691 3.1812L73.3208 3.08516V0H61.3356ZM63.1446 8.64673V10.8631H60.696V8.64673C60.3042 8.3843 60.0072 8.00313 59.8488 7.55943C59.6905 7.11574 59.6792 6.63299 59.8166 6.1824C59.954 5.73181 60.2329 5.33722 60.612 5.05682C60.9912 4.77643 61.4506 4.62507 61.9225 4.62507C62.3944 4.62507 62.8539 4.77643 63.233 5.05682C63.6122 5.33722 63.8911 5.73181 64.0285 6.1824C64.1659 6.63299 64.1546 7.11574 63.9963 7.55943C63.8379 8.00313 63.5409 8.3843 63.149 8.64673H63.1446Z'
        fill='#418EEB'
      />
      <path
        d='M6.84533 12.1663L10.3746 29.2839H10.4471L14.3331 12.1663H19.5027L23.3176 29.3548H23.3902L26.9905 12.1663H33.8003L26.8114 38.501H20.8898L16.9357 21.4543H16.8646L12.9075 38.501H6.8098L0 12.1663H6.84533Z'
        fill='black'
      />
      <path
        d='M36.5479 12.1663H42.4694V20.5648H42.5405C43.5383 18.7917 45.5353 18.0736 47.639 18.0736C49.528 18.0736 51.7752 18.7503 53.0232 20.2456C54.699 22.2019 54.8766 23.9395 54.8766 27.4694V38.501H48.955V27.7531C48.955 26.7927 48.955 23.6248 45.7811 23.6248C42.4665 23.6248 42.4665 26.5799 42.4665 27.5404V38.501H36.5449L36.5479 12.1663Z'
        fill='black'
      />
      <path d='M58.9477 17.6274V38.501H64.8693V17.6274H58.9477Z' fill='black' />
      <path
        d='M77.9648 24.0312C77.9293 23.6041 77.8582 22.5373 76.1099 22.5373C74.8619 22.5373 74.4341 23.319 74.4341 23.8539C74.4341 24.9207 75.8967 25.5265 77.4659 25.9889C80.8175 26.9139 84.3823 27.9098 84.3823 32.1814C84.3823 36.3437 80.7095 38.9058 76.3246 38.9058C73.3297 38.9058 68.8012 37.5539 68.2668 32.3587H74.1499C74.3986 34.1732 76.1469 34.1732 76.3956 34.1732C77.3934 34.1732 78.4682 33.6043 78.4682 32.6439C78.4682 31.1131 77.007 30.9358 73.2276 29.476C70.6606 28.6574 68.5199 26.9848 68.5199 24.3163C68.5199 20.4023 72.0862 17.8047 76.1869 17.8047C78.7894 17.8047 83.2809 18.839 83.7813 24.0312H77.9648Z'
        fill='black'
      />
      <path d='M95.9293 19.5038H98.782V23.9882H95.9293V38.5334H90.0078V23.9926H87.084V19.5038H90.0078V12.2046H95.9293V19.5038Z' fill='black' />
      <path d='M101.91 12.1663H108.649V32.5936H116.456V38.5039H101.91V12.1663Z' fill='black' />
      <path
        d='M139.904 38.5453H133.983V35.9595H133.912C132.913 37.8804 130.452 38.7669 128.138 38.7669C121.863 38.7669 118.19 33.8555 118.19 28.1979C118.19 21.7217 122.861 17.6643 128.138 17.6643C131.383 17.6643 133.201 19.2305 133.912 20.4407H133.983V17.6702H139.904V38.5453ZM129.029 33.2556C132.73 33.2556 133.986 30.1232 133.986 28.2023C133.986 25.8175 132.346 23.1786 128.994 23.1786C125.607 23.1786 124.109 26.0259 124.109 28.2673C124.109 30.1232 125.465 33.2556 129.029 33.2556Z'
        fill='black'
      />
      <path
        d='M173 17.8047L167.671 38.4449H161.009L157.9 25.4349L154.701 38.4449H148.072L142.71 17.8047H148.739L151.525 32.7282L154.84 17.8047H161.222L164.572 32.6543L167.323 17.8003L173 17.8047Z'
        fill='black'
      />
    </svg>
  );
};
