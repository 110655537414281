import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import { DatePicker } from '../../../../../gui/input/date/DatePicker';
import { Form, FileInputWrapper, SubmitWrapper } from '../../../../../gui/input/Form';
import { FormGroup } from '../../../../../gui/input/Form(old)/FormGroup';
import { MainButton } from '../../../../../gui/input/MainButton';
import { UploadRectangle } from '../../../../../gui/input/UploadRectangle';

export const Step3 = ({ manager, formIndex, caseData }) => {
  const [selection, setSelection] = useState({});


  const onSubmit = (values) => {
    manager.setFormData(values)
    manager.setFormIndex(formIndex)
  }



  // const breachTypeSelection = (type) => {
  //   setSelection((state) => ({ ...state, breachType: type }));
  // };


  // useEffect(() => {
  //   if (selection.bedrooms > 0 && selection.bathrooms > 0) {
  //     manager.setFormData({ numBedrooms: selection.bedrooms, numBathrooms: selection.bathrooms });
  //     manager.setFormIndex(formIndex);
  //   }
  // }, [selection]);

  return (
    <Form onSubmit={onSubmit}>
      <h3>New Case</h3>
      
      <FormGroup margin="0rem 0">
          <FileInputWrapper label={<span>Evidence</span>} required={false}>
            <UploadRectangle />
          </FileInputWrapper>
        </FormGroup>

        <FormGroup>
        <SubmitWrapper>
          <MainButton margin="">Confirm</MainButton>
        </SubmitWrapper>
      </FormGroup>
    </Form>
  );
};
