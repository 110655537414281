import styled from 'styled-components';

export const StyledDashboard = styled.div`

  background: #fbfbfb;

  .statistics-section {
    display: block;
    flex-direction: column;
    margin-top: 5rem;
    

    h2 {
      margin-top: 0;
      font-size: 2rem;
    }
/* 
    > div {
      min-height: 10vh;
    } */

    .selects-group {
      display: flex;
      margin-right: auto;
      margin-top: auto;
      width: 30%;
      margin-bottom: 1.5rem;

      > * {
        margin-right: 2rem;
      }
    }
    .buttons-group {
      display: flex;
      margin-left: auto;
      align-items: flex-start;

      > * {
        margin-left: 1rem;
      }
    }

    .dropdownButtonLabel {
      display: block;
      cursor: pointer;
      background: #418eeb;
      color: white;
      padding: 1.95rem 4rem;
      border-radius: 9px;
      margin-right: 0.8rem;

      i.fa-solid.fa-down-to-bracket {
        margin-left: 0.7rem;
      }
      /* &:hover {
        a,
        button {
          background-color: rgb(7, 162, 229);
          color: white !important;
        }
      } */
    }
  }

  .dxl-marker rect {
    rx: 6;
    ry: 6;
  }
`;
