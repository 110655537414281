import styled from 'styled-components/macro';

export const StyledCaseInfo = styled.div`
  .page-header {
    display: flex;
    justify-content: space-between;
    margin: 2rem auto;
    margin-top: 0;

    .page-nav {
      font-size: 80%;
    }

    h1 {
      margin-top: 0;
    }

    .grey {
      color: #8a8a8a;
    }
  }
`;

export const MainContent = styled.div`
  padding: 0 4rem;
  min-height: 53vh;

  > div {
    margin: 2rem auto;
  }

  .text-box {
    label {
      color: #8a8a8a;
      font-weight: 900;
      letter-spacing: 0;
    }
    p {
      margin: 1rem 0;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #dcdcdc;
  }

  .description-dates {
    color: #555555;
    font-size: 1.6rem;
  }

  .p-1{
    padding:0rem;
  }

  textarea{
    border: 1px solid lightgrey;
    border-radius: 1.1rem;
    font-family:inherit;
    padding: 1rem;
    font-size: 1.6rem;
  }

`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 4rem;

  > * {
    margin: auto 0.62rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const UnreadCount = styled.span`
  background:#C6E0FF;
  color: #418EEB;
  border-radius: 7px;
  padding: 0.7rem 1.1rem;
  font-weight: bold;
`
