import styled from 'styled-components';

export const StyledDatePicker = styled.div.attrs(() => ({
  tabindex: '0',
}))`
  position: relative;
  max-width: 295px;
  min-width: 280px;
  width: fit-content;
  align-self: flex-start;

  .buttonContainer {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    background: #fcfcfc;

    border-radius: 12px;

    svg,
    i {
      margin: 0.2rem 1.6rem;
    }

    i {
      color: #d3d6e4;
    }

    .text-box {
      padding: 1rem 0;

      span {
        &:nth-child(1) {
          font-weight: 500;
          font-size: 1.5rem;
        }
        &:nth-child(2) {
          display: block;
          font-size: 1.2rem;
        }
      }
    }
  }

  .datePicker {
    position: absolute;
    top: 100%;
    right: 0;
  }
`;
