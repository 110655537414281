import api from '../../../../../utils/api';

export const getUserManagementData = async () => {
  //const { data } = await api.get('/users/case-handlers');
  const { data } = await api.get('/organizations/users');
  return data;
};

export const changeUserStatus = async (_id, isActive) => {
  const { data } = await api.patch('/users/status', { _id, isActive });
  return data;
};
