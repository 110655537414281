import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

*{
  box-sizing:border-box;
}

  html,
  body {
    scroll-behaviour: smooth;
    height: 100%;
    width: 100%;
    font-size: 62.5%;
    line-height: 1.5;
    margin:0;
    padding:0;
    font-family: inherit;

    /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  }

  body {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
  font-size: 1.6em
  }

  .bold{
    font-weight:bold;
  }

  .blue{
color:#418EEB;
  }

  .grey{
    color:#969BA0;
  }

  .small{
    font-size:small;
  }


  input{
    font-family:inherit;
  }

  button{
    border:0;
    background:transparent;
  }

  a{
    text-decoration:none;
    color: unset;
    margin:0 !important;
  }


  .status-buttons {
    border-radius: 10px;
    padding: 0.8rem 1.5rem;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;

    &.blue-status {
      background: rgba(221, 236, 255, 0.81);
      color: #418eeb;
    }

    &.red-status {
      background: #fff0ed;
      color: #ff6d4d;
    }

    &.green-status {
      background: #bfe8e1;
      color: #00a389;
    }
  }


  
`;

export default GlobalStyle;
