import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { StyledSideNavigationMenu } from './Style';
import {getOpenedCases} from './actions';

import List from '../../../components/gui/elements/List';
import ListItem from '../../../components/gui/elements/ListItem';
import { WhistlawLogoSvg } from '../WhistlawLogoSvg';

import { IconDashboard, IconAllCases, IconDepartments, IconCategories, IconQA, IconUserManagement, IconReports, IconSubscription, MenuBorderLeft } from './icons';

export const SideNavigationMenu = ({role = 'SUPER-ADMIN'}) => {
  const [navMenu, setNavMenu] = useState(1);
  const [roles, setRoles] = useState('SUPER-ADMIN');
  const auth = useSelector(state => state.auth)
  const openedCases = useSelector(state => state.dashboard.openedReportCases)
  // const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const loadOpenedCasesCount = async () => {
    if(auth.accountType !== "SUPER_ADMIN")
    {
      try
      {  
        let data = await getOpenedCases()
        dispatch({type: "OPENED_CASES", payload: {openedReportCases: data.count}})
      }
      catch(err)
      {
        dispatch({type: "OPENED_CASES", payload: {openedReportCases: -1}})
      }
    }
  }

  
  //   {
  //       return <SideNavigationMenu role={accountType} />
  //   }
  //   alert(accountType)
  // }

  useEffect(() => {

    if(auth.accountType === "ADMIN"){

      if (location.pathname === '/') {
        setNavMenu(1);
      }
      if (location.pathname === '/all-cases') {
        setNavMenu(2);
      }
      if (location.pathname === '/departments') {
        setNavMenu(3);
      }
      if (location.pathname === '/categories') {
        setNavMenu(4);
      }
      if (location.pathname === '/user-management') {
        setNavMenu(5);
      }
      if (location.pathname === '/reports') {
        setNavMenu(6);
      }
      if (location.pathname === '/subscription') {
        setNavMenu(7);

      }
    }

    else if(auth.accountType === "SUPER-ADMIN"){
      if (location.pathname === '/') {
        setNavMenu(1);
      }
      if (location.pathname === '/all-cases') {
        setNavMenu(2);
      }
    }

  }, [location, navMenu]);

  useEffect(() => {
    loadOpenedCasesCount()
  }, [])
  // useEffect(()=>{

  // }.[])


  if(auth.accountType === "ADMIN"){

    return (
      <StyledSideNavigationMenu>
        <WhistlawLogoSvg />
        <ul>
          <Link to="/" className={`${navMenu === 1 ? 'selected' : ''}`} onClick={() => setNavMenu(1)}>
            {navMenu === 1 ? <MenuBorderLeft /> : ''}
            <IconDashboard /> Dashboard
          </Link>
          <Link to="/all-cases" className={`${navMenu === 2 ? 'selected' : ''}`} onClick={() => setNavMenu(2)}>
            {navMenu === 2 ? <MenuBorderLeft /> : ''}
            <IconAllCases /> All Cases {openedCases !== 0 && <span className="notification-count">{openedCases > 0 ? openedCases : <i class="fa-solid fa-triangle-exclamation"></i>}</span>}
          </Link>
          <Link to="/departments" className={`${navMenu === 3 ? 'selected' : ''}`} onClick={() => setNavMenu(3)}>
            {navMenu === 3 ? <MenuBorderLeft /> : ''} <IconDepartments /> Departments
          </Link>
          <Link to="/categories" className={`${navMenu === 4 ? 'selected' : ''}`} onClick={() => setNavMenu(4)}>
            {navMenu === 4 ? <MenuBorderLeft /> : ''} <IconCategories /> Categories
          </Link>
          <Link to="/user-management" className={`${navMenu === 5 ? 'selected' : ''}`} onClick={() => setNavMenu(5)}>
            {navMenu === 5 ? <MenuBorderLeft /> : ''}
            <IconUserManagement />
            User Management
          </Link>
          {/* <Link to="/reports" className={`${navMenu === 6 ? 'selected' : ''}`} onClick={() => setNavMenu(6)}>
            {navMenu === 6 ? <MenuBorderLeft /> : ''} <IconReports /> Reports
          </Link> */}
          <Link to="/subscription" className={`${navMenu === 7 ? 'selected' : ''}`} onClick={() => setNavMenu(7)}>
            {navMenu === 7 ? <MenuBorderLeft /> : ''} <IconSubscription /> Subscription
          </Link>
        </ul>
      </StyledSideNavigationMenu>
    );
  }

  if(auth.accountType === "SUPER_ADMIN"){
    return (
      <StyledSideNavigationMenu>
        <WhistlawLogoSvg />
        <ul>
          <Link to="/" className={`${navMenu === 1 ? 'selected' : ''}`} onClick={() => setNavMenu(1)}>
            {navMenu === 1 ? <MenuBorderLeft /> : ''}
            <IconDashboard /> Dashboard
          </Link>
          {/*<Link to="/all-cases" className={`${navMenu === 2 ? 'selected' : ''}`} onClick={() => setNavMenu(2)}>
            {navMenu === 2 ? <MenuBorderLeft /> : ''}
            <IconAllCases /> All Cases {openedCases !== 0 && <span className="notification-count">{openedCases > 0 ? openedCases : <i class="fa-solid fa-triangle-exclamation"></i>}</span>}
          </Link>*/}
          {/* <Link to="/departments" className={`${navMenu === 3 ? 'selected' : ''}`} onClick={() => setNavMenu(3)}>
            {navMenu === 3 ? <MenuBorderLeft /> : ''} <IconDepartments /> Departments
          </Link>
          <Link to="/categories" className={`${navMenu === 4 ? 'selected' : ''}`} onClick={() => setNavMenu(4)}>
            {navMenu === 4 ? <MenuBorderLeft /> : ''} <IconCategories /> Categories
          </Link>
          <Link to="/user-management" className={`${navMenu === 5 ? 'selected' : ''}`} onClick={() => setNavMenu(5)}>
            {navMenu === 5 ? <MenuBorderLeft /> : ''}
            <IconUserManagement />
            User Management
          </Link>
          <Link to="/reports" className={`${navMenu === 6 ? 'selected' : ''}`} onClick={() => setNavMenu(6)}>
            {navMenu === 6 ? <MenuBorderLeft /> : ''} <IconReports /> Reports
          </Link>
          <Link to="/subscription" className={`${navMenu === 7 ? 'selected' : ''}`} onClick={() => setNavMenu(7)}>
            {navMenu === 7 ? <MenuBorderLeft /> : ''} <IconSubscription /> Subscription
          </Link> */}
          <Link to="/clients" className={`${navMenu === 8 ? 'selected' : ''}`} onClick={() => setNavMenu(8)}>
            {navMenu === 8 ? <MenuBorderLeft /> : ''} <IconSubscription /> Clients
          </Link>
        </ul>
      </StyledSideNavigationMenu>
    );

  }


  if(auth.accountType === "CASE_HANDLER")
  {
    return (
      <StyledSideNavigationMenu>
        <WhistlawLogoSvg />
        <ul>
          <Link to="/" className={`${navMenu === 1 ? 'selected' : ''}`} onClick={() => setNavMenu(1)}>
            {navMenu === 1 ? <MenuBorderLeft /> : ''}
            <IconDashboard /> Dashboard
          </Link>
          <Link to="/all-cases" className={`${navMenu === 2 ? 'selected' : ''}`} onClick={() => setNavMenu(2)}>
            {navMenu === 2 ? <MenuBorderLeft /> : ''}
            <IconAllCases /> All Cases {openedCases !== 0 && <span className="notification-count">{openedCases > 0 ? openedCases : <i class="fa-solid fa-triangle-exclamation"></i>}</span>}
          </Link>
        </ul>
      </StyledSideNavigationMenu>
    )
  }
};
