import api from "../../../../../utils/api"

export const getSubscriptionDetails = async () => {
    let {data} = await api.get('/organizations/subscription/finalized')
    return data
}

//type is either "MONTHLY" or "YEARLY"
export const newSubscription = async (type) => {
    const {data} = await api.post('/organizations/subscription/subscribe', {type})
    return data
}

export const registerOrganizationEmail = async () => {
    const {data} = await api.post('/organizations/subscription/register', {})
    return data
}

export const checkout = async (productCode) => {
    const {data} = await api.post('/organizations/subscription/checkout', {productCode})
    return data.sessionId 
}

export const switchToAnnual = async () => {
    const {data} = await api.patch('/organizations/subscription', {type: "ANNUAL"})
}

export const switchToMonthly = async () => {
    const {data} = await api.patch('/organizations/subscription', {type: "MONTHLY"})
}

export const cancelSubscription = async () => {
    const {data} = await api.post('/organizations/subscription/cancel', {})
    return data
}