import React, {useState} from 'react';
import { MainButton } from '../../../../../../gui/input/MainButton';
import { GridRow } from '../../../../../../gui/layout/GridRow';
import { FlexContainer, MainContent } from '../Style';
import { Table } from '../../../../../../gui/layout/Table';
import withActionPageLoader from '../../../../../../hoc/withActionPageLoader';
import styled from 'styled-components';
import { PaginationWrapper } from '../../../../../../gui/controls/PaginationWrapper';
import { getHandlers } from './actions/accessActions';
import { Pagination } from '../../../../../../gui/layout/Pagination';
import { ButtonLoadWrapper } from '../../../../../../gui/input/Button/ButtonLoadWrapper';
import { updateAccessStatus } from './actions/accessActions';

const StyledAccess = styled.div`
  table {
    min-height: 40vh;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    width: 85%;
  }
`;

const statusMap = {ACTIVE:"Active", INACTIVE:"Inactive"}


const LineItem = ({ row, onAccessChange, fromToken, caseId }) => {

  const updateAccessCode = async (accessCode) => {
    try
    {
      await updateAccessStatus({accessCode, caseId, accessId: row._id, accountId: row.accountId})
      onAccessChange((row._id ? row._id.toString() : null), (row.accountId ? row.accountId.toString() : null), accessCode)
    }
    catch(err)
    {
      alert("failed to updatre access: " + err.message)
      console.log("ERROR", err)
    }
  }

  return (
    <tr>
      <td>{row.name}</td>
      <td className={`status-buttons ${row.status === 'ACTIVE' ? 'green-status' : 'red-status'}`} style={{width: "9.06rem"}}>{statusMap[row.status]}</td>
      {!fromToken &&
        <td>
          {row.status === 'ACTIVE' && (
            <ButtonLoadWrapper type="empty" color="#861724" onClick={() => updateAccessCode('INACTIVE')}>
              Block Access
            </ButtonLoadWrapper>
          )}
          {row.status === 'INACTIVE' && (
            <ButtonLoadWrapper type="empty" color="#00A389" onClick={() => updateAccessCode('ACTIVE')}>
              Grant Access
            </ButtonLoadWrapper>
          )}
        </td>
      }
    </tr>
  );
};

const CustomPagination = (props) => {
  let newProps = { ...props };
  newProps.TextComponent = ({ showCount, totalCount }) => {
    return <span className="showing-count">Total access given: {totalCount}</span>;
  };

  return <Pagination {...newProps} />;
};

export const Access = withActionPageLoader(getHandlers, ({ loadedPageData, caseId, fromToken=null }) => {

  const [pageData, setPageData] = useState(loadedPageData)

  const onAccessChange = (id, accountId, accessCode) => {

    let newState = [...pageData];
    if(id)
    {
      for(let i=0;i<newState.length;i++)
      {
        if(newState[i]._id && newState[i]._id.toString() === id)
        {
          newState[i].status = accessCode
          break;
        }
      }
    }
    else
    {
      for(let i=0;i<newState.length;i++)
      {
        if(newState[i].accountId.toString() === accountId)
        {
          newState[i].status = accessCode
          break;
        }
      }
    }

    setPageData(newState)
  }

  let colHeaders = ['Handler', 'Status', 'Action']
  if(fromToken){colHeaders = ['Handler', 'Status']}

  return (
    <MainContent>
      {/*JSON.stringify(loadedPageData)*/}
      <StyledAccess>
        <PaginationWrapper PaginationControl={CustomPagination} dataProp="data" data={pageData}>
          <Table colHeaders={colHeaders} LineItem={LineItem} lineItemExtraProps={{onAccessChange, fromToken, caseId}}/>
        </PaginationWrapper>
      </StyledAccess>
    </MainContent>
  );
});
