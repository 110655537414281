import api from "../../../../../../../../utils/api";

export const getManageCaseInfo = async ({caseData}) => {
    console.log("caseData",caseData)
    const [priorityList, handlers, specialAccess] = await Promise.all([
        api.get('/lookup/priority'),
        api.get('/organizations/handlers/category/'+caseData.breachCategoryId),
        api.get('/report-cases/'+caseData._id+'/handlers')
    ])

    let handlerList = handlers.data;
    let specialAccessList = specialAccess.data
    for(let i=0;i<specialAccessList.length;i++)
    {
        if(specialAccessList[i].status === "ACTIVE")
        {
            handlerList.push({"_id":specialAccessList[i].accountId,"name":specialAccessList[i].name,"isActive":true})
        }
    }

    return {
        priority: priorityList.data,
        handlers: handlerList
    }
}

export const resolveCase = async (caseId) => {
    let {data} = await api.patch('/report-cases/resolve',{caseId})
    return data
}

export const deleteCase = async (caseId) => {
    let {data} = await api.delete('/report-cases/'+caseId)
    return data
}