import styled from 'styled-components/macro';

export const StyledSettings = styled.div`
  .form {
    background: #ffffff;
    box-shadow: 0px 7px 21px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    padding: 2rem;

    width: 75%;

    h2 {
      margin-top: 3rem;
    }
  }

  .form-input,
  .form-select {
    background: #f3f4f6;
    border-radius: 12px;
    border: none;
    width: 100%;
    padding: 1.4rem 1rem;
    margin-top: 0.4rem;
    font-size: 1.35rem;
  }

  hr {
    border: 0.5px solid #d6d6d6;
  }

  input[type='checkbox' i],
  .checkbox {
    border: 2px solid #858585;
    border-radius: 4px;
  }

  label {
    color: #858585;
    font-size: 1.25rem;
  }

  button {
    display: flex;
    width: fit-content;
    margin-left: auto;
    margin-top: 5rem;
    padding: 1.7rem 3.5rem;
  }
`;
