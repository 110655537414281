import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ButtonLoadWrapper } from '../../../../gui/input/Button/ButtonLoadWrapper';
import { registerOrganizationEmail, checkout, getSubscriptionDetails, switchToAnnual, switchToMonthly, cancelSubscription } from './actions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { MainButton } from '../../../../gui/input/MainButton';
import { Card } from '../../../../gui/layout/Card';
import { Row } from '../../../../gui/layout/Row';
import { GridRow } from '../../../../gui/layout/GridRow';
import { Col } from '../../../../gui/layout/Col';
import { SearchBar } from '../../../../gui/input/SearchBar';
import { circleCheckmark, mastercardLogo, threeDots, visaLogo } from './icons';

import { Table } from '../../../../gui/layout/Table';
import { PaginationWrapper } from '../../../../gui/controls/PaginationWrapper';
import { Pagination as PaginationControl } from '../../../../gui/layout/Pagination';
import { StyledSubscription } from './Style';
import { DefaultItem, DropdownSelect, Item } from '../../../../gui/input/Select/DropdownSelect';
import { statusIcon } from '../../../../../App/theme/icons';
import withActionPageLoader from '../../../../hoc/withActionPageLoader';
import {Loader} from "../../../../../App/AppComponents/Loader"
import { Dropdown, Header as DropdownHeader, List as DropdownList } from '../../../../gui/input/Select/Dropdown';
import { LoaderThree } from '../../../../gui/elements/LoaderThree';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const downloadIcon = (<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{verticalAlign: "text-bottom"}}>
<path d="M12.5573 8.1106L8.99986 11.6681L5.44238 8.1106" stroke="#418EEB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
<path d="M9.00021 0.996643V11.6681" stroke="#418EEB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
<path
  d="M17.7537 13.4457C17.7537 13.0315 17.4179 12.6957 17.0037 12.6957C16.5895 12.6957 16.2537 13.0315 16.2537 13.4457H17.7537ZM1.74707 13.4457C1.74707 13.0315 1.41128 12.6957 0.99707 12.6957C0.582857 12.6957 0.24707 13.0315 0.24707 13.4457H1.74707ZM16.2537 13.4457C16.2537 14.9963 14.9968 16.2532 13.4463 16.2532V17.7532C15.8252 17.7532 17.7537 15.8247 17.7537 13.4457H16.2537ZM13.4463 16.2532H4.55455V17.7532H13.4463V16.2532ZM4.55455 16.2532C3.00402 16.2532 1.74707 14.9963 1.74707 13.4457H0.24707C0.24707 15.8247 2.1756 17.7532 4.55455 17.7532V16.2532Z"
  fill="#418EEB"
/>
</svg>)


export const SubscriptionBase = ({ loadedPageData: {subscriptionData = null, activeUntil = null}, reloadPageData } = {}) => {

  const [paymentMethodSelected, setPaymentMethodSelected] = useState(0);
  const [subscribeSpinner, setSubscribeSpinner] = useState(false);
  const [subscriptionSwitchLoader, setSubscriptionSwitchLoader] = useState(false)
  const [cancelSpinner, setCancelSpinner] = useState(false)
  const email = useSelector((state) => state.auth.email);

  const makeDate = () => {
    let d = new Date(activeUntil)
    return months[d.getMonth()]+" "+d.getDate()+", "+d.getFullYear()
  }

  const onSubscribeClick = async (productCode) => {
    setSubscribeSpinner(true)
    try {
      let sessionId = await checkout(productCode);
      let awaited = await stripePromise;
      await awaited.redirectToCheckout({ sessionId });
    } catch (err) {
      console.log('stripe registration error', err);
      alert('failed to subscribe');
    }
    setSubscribeSpinner(false)
  }

  const Test = ({ stripePromise }) => {
    const [promiseResult, setPromiseResult] = useState({});

    const load = async () => {
      let awaited = await stripePromise;
      setPromiseResult(awaited);
      let sessionId = await checkout();
      awaited.redirectToCheckout({ sessionId });
    };

    useEffect(() => {
      load();
    }, []);

    return <></>;
  };

  const onSwitchToAnnualClick = async () => {
    setSubscriptionSwitchLoader(true)
    try
    {
      await switchToAnnual()
      reloadPageData()
    }
    catch(err)
    {
      console.log(err);
      alert(err.message)
    }
    setSubscriptionSwitchLoader(false)
  }

  const onSwitchToMonthlyClick = async () => {
    setSubscriptionSwitchLoader(true)
      try
      {
        await switchToMonthly()
        reloadPageData()
      }
      catch(err)
      {
        console.log(err);
        alert(err.message)
      }
      setSubscriptionSwitchLoader(false)
  }

  const onStatusChangeClick = () => {
    alert('TODO: link data to filter');
  };

  const calculateNextPaymentDate = (date) => {
  
    date = new Date(date)
    let numDays = Math.floor((date.getTime() - (new Date()).getTime())/(1000*60*60*24))
    let toReturn = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} (in ${numDays > 0 ? (numDays > 1 ? numDays+' days' : '1 day') : 'less than a day'})`

    return toReturn
  }

  const onCancelSubscription = async () => {
    setCancelSpinner(true)
    try
    {
      await cancelSubscription()
      reloadPageData()
    }
    catch(err)
    {
      console.log(err)
      alert(err.message)
    }
    setCancelSpinner(false)
  }

  if(subscriptionData === null)
  {
    return (
      <Elements stripe={stripePromise}>
        {subscribeSpinner && <Loader />}
        {!subscribeSpinner &&
          <>
          {activeUntil && <h3>{"Your account will remain active until: "+makeDate()}</h3>}
          {/*<MainButton onClick={() => onSubscribeClick("MONTHLY")}>Subscribe Monthly</MainButton>*/}
          <Card align="flex-start" className="card">
            <h3>
              Start plan of <span className="blue">USD $19.00/month</span>
            </h3>
            <br />
            {!subscriptionSwitchLoader && <MainButton width="200px" onClick={() => onSubscribeClick("MONTHLY")}>Select Plan</MainButton>}
            {subscriptionSwitchLoader && <Loader />}
          </Card>
          {/*<MainButton onClick={() => onSubscribeClick("ANNUAL")}>Subscribe Yearly</MainButton>*/}
          <Card align="flex-start">
            <h3>
              Start plan of <span className="blue">$198/year($16.50/month)</span>
            </h3>
            <br />
            {!subscriptionSwitchLoader && <MainButton width="200px" onClick={() => onSubscribeClick("ANNUAL")}>Select Plan</MainButton>}
            {subscriptionSwitchLoader && <Loader />}
          </Card>
          </>
        }
      </Elements>
    )
  }

  return (
    <StyledSubscription>
      <h1>Subscription</h1>

      <GridRow colTemplate="2fr 3fr">
        {subscriptionData.subscriptionCode === "MONTHLY" &&
          <>
          <Card align="flex-start" className="card">
            <h3>Monthly</h3>
            <hr />
            <span>Subscription renewal date</span>
            <span>{calculateNextPaymentDate(subscriptionData.current_period_end)}</span>
            <br />
            <span>What you'll be charged</span>
            <span>USD $19.00 (excluding tax)</span>
          </Card>

          <Card align="flex-start">
            <h3>
              Switch to annual payments and save 13% <span className="blue">$198/year($16.50/month)</span>
            </h3>
            <span>We will adjust the amount we charge you according to the remaining days on your current subscription.</span>
            <br />
            {!subscriptionSwitchLoader && <MainButton width="200px" onClick={onSwitchToAnnualClick}>Switch to annual</MainButton>}
            {subscriptionSwitchLoader && <Loader />}
          </Card>
          </>
        }
        {subscriptionData.subscriptionCode === "ANNUAL" &&
          <>
          <Card align="flex-start" className="card">
            <h3>
              Switch to monthly payments <span className="blue">USD $19.00/month</span>
            </h3>
            <span>We will adjust the amount we charge you according to the remaining days on your current subscription.</span>
            <br />
            {!subscriptionSwitchLoader && <MainButton width="200px" onClick={onSwitchToMonthlyClick}>Switch to monthly</MainButton>}
            {subscriptionSwitchLoader && <Loader />}
          </Card>

          <Card align="flex-start" className="card">
            <h3>Annual</h3>
            <hr />
            <span>Subscription renewal date</span>
            <span>{calculateNextPaymentDate(subscriptionData.current_period_end)}</span>
            <br />
            <span>What you'll be charged</span>
            <span>USD $198 (excluding tax)</span>
          </Card>
          </>
        }
      </GridRow>
      {!cancelSpinner && <span className="cancel-sub" onClick={onCancelSubscription}>Cancel subscription</span>}
      {cancelSpinner && <div><LoaderThree size="2.4rem" margin="0"/></div>}

      <div style={{ marginTop: '2rem' }}>
        <h3>Payment method</h3>
        <Card align="flex-start">
          <Row style={{ marginLeft: '0' }}>

            {/*JSON.stringify(subscriptionData.cards)*/}
            
            {subscriptionData.cards.map((card,index) => 
              <div className={`card-info ${card.isDefault ? 'selected' : ''}`} onClick={() => setPaymentMethodSelected(index)}>
                {card.card === "visa" ? <>{visaLogo}</> : <>{card.card}</>}

                <Col>
                  <span>Credit card</span>
                  <span className="cc-number">**** **** **** {card.lastDigits}</span>
                </Col>
                {card.isDefault && <div className='circle-checkmark'>{circleCheckmark}</div>}

                {/*<Dropdown width="fit-content">
                  <DropdownHeader>{threeDots}</DropdownHeader>
                  <DropdownList wrap={false}>
                    <div className="creditCardDropdownElement-top"><i className="fa-solid fa-check"></i> Set as Default</div>
                    <div className="creditCardDropdownElement-bottom"><i className="fa-light fa-trash-can"></i> Delete</div>
                  </DropdownList>
                </Dropdown>*/}
              </div>
            )}

            {/*<div className={`card-info ${paymentMethodSelected === 0 ? 'selected' : ''} `} onClick={() => setPaymentMethodSelected(0)}>
              {mastercardLogo}

              <Col>
                <span>Credit card</span>
                <span className="cc-number">**** **** **** 3109</span>
              </Col>
              {paymentMethodSelected === 0 && <>{circleCheckmark}</>}

              {threeDots}
            </div>

            <div className={`card-info ${paymentMethodSelected === 1 ? 'selected' : ''}`} onClick={() => setPaymentMethodSelected(1)}>
              {visaLogo}

              <Col>
                <span>Credit card</span>
                <span className="cc-number">**** **** **** 3109</span>
              </Col>
              {paymentMethodSelected === 1 && <>{circleCheckmark}</>}

              {threeDots}
            </div>*/}

          </Row>
        </Card>
      </div>

      <div style={{ marginTop: '2rem' }}>
        <h3>Billing history</h3>

        {/*<Row align="center" justify="space-between">
          <SearchBar />
          <DropdownSelect width="220px" type="all-status" HeaderStyle="fill" defaultValue={null} onChange={onStatusChangeClick}>
            <DefaultItem>{statusIcon}&nbsp;All Status</DefaultItem>
            <Item value={null}>{statusIcon}&nbsp;All Status</Item>
            <Item value="NEW">{statusIcon}&nbsp;New</Item>
            <Item value="ONGOING">{statusIcon}&nbsp;Ongoing</Item>
            <Item value="CLOSED">{statusIcon}&nbsp;Closed</Item>
          </DropdownSelect>
          </Row>*/}

        <PaginationWrapper data={subscriptionData.billingHistory} dataProp="data" PaginationControl={PaginationControl}>
          <Table colHeaders={['Billing month', 'Type', 'Date', 'Total amount', 'Action']} 
            LineItem={({ row }) => (
              <tr>
                <td>{row.billingMonth}</td>
                <td>Payment</td>
                <td>{row.date}</td>
                <td>USD {row.amount.toFixed(2)}</td>
                <td><a href={row.downloadLink} target="_blank"><MainButton type="empty" color="#418EEB" padding="1rem 1.5rem" border="1px solid"><strong style={{fontSize:"1.3rem", paddingRight:"0.6rem"}}>Download</strong>{downloadIcon} </MainButton></a></td>
              </tr>
            )}
          />
        </PaginationWrapper>
      </div>

      <Elements stripe={stripePromise}>
        {/* <Test stripePromise={stripePromise} /> */}
        {/* <ButtonLoadWrapper onClick={onSubscribeClick}>Subscribe</ButtonLoadWrapper> */}
      </Elements>

      {/* <div style={{marginTop: "5rem"}}>
        <textarea rows="20" cols="200">{JSON.stringify(subscriptionData.fullData)}</textarea>
      </div> */}
    </StyledSubscription>
  );
}

export const Subscription = withActionPageLoader(getSubscriptionDetails, SubscriptionBase);
