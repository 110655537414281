import React, { useState, useRef } from 'react';
import { MainButton } from '../../../../../../gui/input/MainButton';
import { MainContent } from '../Style';
import { saveDescription } from './actions/descriptionActions';
import {Loader} from "../../../../../../../App/AppComponents/Loader"
import { Row } from '../../../../../../gui/layout/Row';

export const Description = ({ caseData, fromToken }) => {
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [description, setDescription] = useState(caseData.description);
  const [lastUpdated, setLastUpdated] = useState(caseData.lastUpdateDate);
  const textArea = useRef();

  const onSaveEditClick = async () => {
    if (editMode) {
      setSaving(true);
      try {
        setLastUpdated(new Date());
        await saveDescription(caseData._id, textArea.current.value, fromToken);
        setDescription(textArea.current.value);
        caseData.description = textArea.current.value;
      } catch (err) {
        console.log(err);
        alert('failed to change description');
      }
      setSaving(false);
    }
    setEditMode(!editMode);
  };

  return (
    <MainContent>
      <div style={{ marginTop: '0' }}>
        <Row align='flex-start' justify='space-between'>
          <div>
            <p className='description-dates' style={{ marginTop: '0' }}>
              Created: {new Date(caseData.createdDate).toLocaleDateString('en-GB')}
            </p>
            <p className='description-dates'>Last updated: {new Date(lastUpdated).toLocaleDateString('en-GB')}</p>
          </div>
          {saving && <Loader />}
          {!saving && (
            <MainButton margin='0' type='empty' onClick={onSaveEditClick} className='main-button-custom-style'>
              {editMode ? 'Save' : 'Edit'}
              <svg width='1.3rem' height='1.3rem' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.41663 11.7512L9.34426 11.3944C9.54996 11.3695 9.74133 11.2776 9.88796 11.1331L16.9803 4.13965C17.7656 3.36438 17.7656 2.10783 16.9803 1.33256V1.33256C16.1946 0.557684 14.9211 0.557684 14.1354 1.33256L7.10903 8.26545C6.96673 8.40581 6.87443 8.58795 6.84601 8.7845L6.41663 11.7512Z'
            stroke='#418EEB'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path d='M10.1342 1.66364H4.55778C2.50459 1.66364 0.840149 3.30594 0.840149 5.33183V13.5853C0.840149 15.6112 2.50459 17.2535 4.55778 17.2535H12.9224C14.9756 17.2535 16.6401 15.6112 16.6401 13.5853V8.08298' stroke='#418EEB' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
            </MainButton>
          )}
        </Row>
      </div>
      {!editMode && (
        <div className='p-1'>
          {description.split('\n').map((text) => (
            <p>{text}</p>
          ))}
        </div>
      )}
      {editMode && (
        <textarea style={{ width: '100%', height: '100%', minHeight: '20rem' }} ref={textArea}>
          {description}
        </textarea>
      )}
      {/*
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque leo bibendum dui morbi. Tristique purus, adipiscing id risus nulla. Et enim pretium sed aliquam. Amet, cras morbi ut in non neque. Ipsum netus massa nunc mauris arcu. Massa nibh sed lobortis elit mauris nulla mattis amet, mi. Nunc massa proin lorem sit semper ut. </p>
      <p>Risus lectus arcu, adipiscing tortor pretium sociis tellus eget. Orci, purus gravida nibh consectetur. Ut donec sed maecenas suscipit sed magna. Eget a pharetra, imperdiet at eget ac. Lorem consequat suscipit nascetur diam eget sit ultricies. Diam eu nunc, vel neque, odio. Pharetra, tempus, urna ultricies velit mauris et egestas est.</p>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque leo bibendum dui morbi. Tristique purus, adipiscing id risus nulla. Et enim pretium sed aliquam. Amet, cras morbi ut in non neque. Ipsum netus massa nunc mauris arcu. Massa nibh sed lobortis elit mauris nulla mattis amet, mi. Nunc massa proin lorem sit semper ut. </p>
      <p>Risus lectus arcu, adipiscing tortor pretium sociis tellus eget. Orci, purus gravida nibh consectetur. Ut donec sed maecenas suscipit sed magna. Eget a pharetra, imperdiet at eget ac. Lorem consequat suscipit nascetur diam eget sit ultricies. Diam eu nunc, vel neque, odio. Pharetra, tempus, urna ultricies velit mauris et egestas est.</p>
      */}
    </MainContent>
  );
};
