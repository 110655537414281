import React from 'react';
import styled from 'styled-components';

const StyledProgressBar = styled.div`
  height: 12px;
  background: #f3f4f6;
  box-shadow: 2px 14px 15px -7px rgba(30, 166, 250, 0.36);
  border-radius: 50px;
  transition: all 0.5s;
  margin-top: 1rem;
`;

const Progress = styled.div`
  width: calc(${(props) => props.progress + '%' || '15px'} - 2px);
  height: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: start;
  margin-top: 1px;
  margin-left: 1px;

  /* padding: 20px; */
  background: ${(props) => props.theme.colors.primary};
  border-radius: 20px;
  box-shadow: 0px 10px 50px #abb7e9;
`;

export const ProgressBar = ({ progress }) => {
  return (
    <StyledProgressBar>
      <Progress progress={progress} />
    </StyledProgressBar>
  );
};
