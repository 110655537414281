import React, { useMemo } from 'react';

import { Chart, Legend, Series, Size, Tooltip } from 'devextreme-react/chart';
import styled from 'styled-components';

const StyledChart = styled.div`
  width: 100%;
  > div {
    width: 100%;
  }

  .dxc-series rect {
    fill: url(#sum-of-cases-bar-chart-gradient) #fff;
  }

  .dxc-val-grid {
    path {
      stroke-dasharray: 5, 5;
    }
  }

  .dxc-axes-group {
    display: none;
  }
`;

const dataSource = [
  {
    day: 'January',
    oranges: 3,
  },
  {
    day: 'February',
    oranges: 2,
  },
  {
    day: 'March',
    oranges: 3,
  },
  {
    day: 'April',
    oranges: 4,
  },
  {
    day: 'May',
    oranges: 6,
  },
  {
    day: 'June',
    oranges: 11,
  },
  {
    day: 'July',
    oranges: 3,
  },
  {
    day: 'August',
    oranges: 7,
  },
  {
    day: 'September',
    oranges: 4,
  },
  {
    day: 'October',
    oranges: 5,
  },
  {
    day: 'November',
    oranges: 6,
  },
  {
    day: 'December',
    oranges: 2,
  },
];

let monthIndexMap = {
  "0": "Jan", 
  "1": "Feb",
  "2": "Mar",
  "3": "Apr",
  "4": "May",
  "5": "Jun",
  "6": "Jul",
  "7": "Aug",
  "8": "Sep",
  "9": "Oct",
  "10": "Nov",
  "11": "Dec"
}

export const SumOfCases = ({data}) => {

  let chartData = useMemo(() => {

    if(data === null){return []}

    /*let start = (new Date()).getMonth() - 11
    if(start < 0){start += 12}

    let toReturn = []
    let runningCounter = oldCount

    for(let i=0; i < 12; i++)
    {
      runningCounter += data[start.toString()]
      toReturn.push({month: monthIndexMap[start.toString()], total: runningCounter})

      start++
      if(start >= 12){start = 0}
    }

    return toReturn*/

    let toReturn = []

    if(data.length > 12)
    {
      for(let i=0; i<data.length; i++)
      {
        toReturn.push({month: data[i].year+" "+monthIndexMap[data[i].month.toString()], count: data[i].count})
      }
    }
    else
    {
      for(let i=0; i<data.length; i++)
      {
        toReturn.push({month: monthIndexMap[data[i].month.toString()], count: data[i].count})
      }
    }

    return toReturn

  }, [data])

  return (
    <StyledChart>
      <Chart id="chart" dataSource={chartData}>
        <Size height={300} />
        <Series valueField="count" argumentField="month" name="" type="bar" color="#418eeb" barWidth={20} cornerRadius={10} />
        <Legend verticalAlignment="top" horizontalAlignment="" visible={false} />
        <Tooltip enabled={true} />
      </Chart>

      <svg style={{ position: 'absolute' }}>
        <defs>
          <linearGradient id="sum-of-cases-bar-chart-gradient" x2="0" y2="1">
            <stop offset="0%" stop-color="#408FEA" />
            <stop offset="100%" stop-color="#01A389" />
          </linearGradient>
        </defs>
      </svg>
    </StyledChart>
  );
};
