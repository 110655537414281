import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PaginationWrapper } from '../../../../gui/controls/PaginationWrapper';
import { Table } from '../../../../gui/layout/Table';
import { StyledClients } from './Style';
import { Pagination as PaginationControl } from '../../../../gui/layout/Pagination';
import { Row } from '../../../../gui/layout/Row';
import { MainButton } from '../../../../gui/input/MainButton';
import { SearchBar } from '../../../../gui/input/SearchBar';
import { Select } from '../../../../gui/input/Select';
import { RightPanelModal } from '../../../../gui/layout/RightPanelModal';
import { FormGroup } from '../../../../gui/input/Form(old)/FormGroup';
import styled from 'styled-components';
import { ToggleSwitchWithText } from '../../../../gui/input/ToggleSwitchWithText';
import { TextInput } from '../../../../gui/input/TextInput';
import withActionPageLoader, {clearPageLoaderCache} from '../../../../hoc/withActionPageLoader';
import { formatDate } from '../../../../../utils/dataManipulation/formatDate';
import { ButtonLoadWrapper } from '../../../../gui/input/Button/ButtonLoadWrapper';
import { Form, GridRow, TextField, SelectField, SubmitWrapper, EmailField, FileInputWrapper } from '../../../../gui/input/Form';
import { DropdownSearch, Item as SearchItem } from '../../../../gui/input/Select/DropdownSearch';
import {Loader} from "../../../../../App/AppComponents/Loader"
import { FilterWrapper } from '../../../../gui/controls/FilterWrapper';
import {changeOrgStatus, createNewClient, getOrganizations, manageOrganization, editClient } from './actions';
import { H1 } from '../../../../gui/elements/H1';
import { DefaultItem, DropdownSelect, Item } from '../../../../gui/input/Select/DropdownSelect';
import { statusIcon } from '../../../../../App/theme/icons';
import { FileInput } from '../../../../gui/input/FileInput';
import { countries } from '../../../../../data/countries';
import Joi from 'joi';
import { SortWrapper, SortControl } from '../../../../gui/controls/SortWrapper';
import { TableSortArrows } from '../../../../gui/layout/Table/TableSortArrows';
import { LoaderThree } from '../../../../gui/elements/LoaderThree';

const countryMap = {
  "CA": {value: "CA", "text":"canada"},
  "US": {value: "US", "text":"united-states"}
}

export const Clients = withActionPageLoader(getOrganizations, ({ loadedPageData, reloadPageData }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [pageData, setPageData] = useState(loadedPageData);
  const [viewNewClientModal, setViewNewClientModal] = useState(false);
  const [Status, setStatus] = useState(false);
  const [submitSpinner, setSubmitSpinner] = useState(false)
  const [modalType, setModalType] = useState('NEW')
  const [clientToEdit, setClientToEdit] = useState({})
  const [logoValidation, setLogoValidation] = useState(null)
  const [filters, setFilters] = useState({ status: null, name: () => true });

  const onStatusChangeClick = async (_id, status) => {
    try {
      await changeOrgStatus(_id, status);

      clearPageLoaderCache()

      for (let i = 0; i < pageData.length; i++) {
        if (pageData[i]._id === _id) {
          //await new Promise((resolve) => setTimeout(resolve, 1500))
          let newPageData = [...pageData];
          newPageData[i].status = status;
          setPageData(newPageData);
          break;
        }
      }
    } catch (err) {
      console.log(err);
      alert('failed to update status');
    }
  };

  const onNewClientSubmit = async (values) => {

    setSubmitSpinner(true)
    try
    {
      if(modalType === "NEW")
      {
        await createNewClient(values)
      }
      else
      {
        await editClient(values, clientToEdit._id)
      }
      setViewNewClientModal(false)
      reloadPageData()
    }
    catch(err)
    {
      console.log(err)
      alert(err.message)
    }
    setSubmitSpinner(false)
  }

  const onManageClick = async (_id) => {
    try
    {
      await dispatch(manageOrganization(_id, () => {
        navigate('/')
      }))
    }
    catch(err)
    {
      console.log(err)
      alert('failed to log in as organization')
    }
  }

  const onNewClick = () => {
    /*setLogoValidation(Joi.any().custom((value, helper) => {
        if(value === null)
        {
            return helper.message("Logo image file must be selected")
        }
    }))*/

    setLogoValidation(Joi.any().allow(null).allow(''))
    
    setModalType("NEW")
    setClientToEdit({})
    setViewNewClientModal(true)
  }

  const onEditClick = async (organization) => {
    setLogoValidation(Joi.any().allow(null).allow(''))
    setModalType("EDIT")
    setClientToEdit(organization)
    setViewNewClientModal(true)
  }

  const onSearchChange = (searchFilter) => {
    setFilters((prevState) => {
      let newFilterFunc = null;
      searchFilter = searchFilter.trim();

      if (searchFilter.length === 0) {
        newFilterFunc = (value) => true;
      } else {
        newFilterFunc = (value) => value.name.toLowerCase().indexOf(searchFilter) > -1;
      }

      return { ...prevState, name: newFilterFunc };
    });
  };

  const onStatusChanged = (newStatus) => {
    setFilters((prevState) => ({ ...prevState, status: newStatus }));
  };

  return (
    <StyledClients>
      <Row justify="space-between">
        <H1>Clients</H1>
        <MainButton onClick={onNewClick}>+ New clients</MainButton>
      </Row>
      <Row justify="space-between" margin="2rem 0">
        <SearchBar onChange={onSearchChange}/>
        <DropdownSelect width="180px" type="all-status" HeaderStyle="fill" defaultValue={null} onChange={onStatusChanged}>
          <DefaultItem>{statusIcon}&nbsp;All Status</DefaultItem>
          <Item value={null}>{statusIcon}&nbsp;All Status</Item>
          <Item value="ACTIVE">{statusIcon}&nbsp;Active</Item>
          <Item value="INACTIVE">{statusIcon}&nbsp;Inactive</Item>
        </DropdownSelect>
      </Row>


      <FilterWrapper data={pageData} filters={filters} dataProp="data">
        <SortWrapper sortKey="name">
          <PaginationWrapper dataProp="data" PaginationControl={PaginationControl}>
            <Table
              colCustomSize="1.8fr 1.1fr 1.8fr 1fr 1.2fr 1fr 3fr"
              colHeaders={[
                <>Name<SortControl sortKey="name"><TableSortArrows/></SortControl></>,
                <>Phone<SortControl sortKey="phone"><TableSortArrows/></SortControl></>,
                <>Email<SortControl sortKey="email"><TableSortArrows/></SortControl></>,
                <div style={{transform:"translate(-30%)"}}>Employees<SortControl sortKey="employees"><TableSortArrows/></SortControl></div>,
                <>Date joined<SortControl sortKey="dateJoined"><TableSortArrows/></SortControl></>,
                <>Status<SortControl sortKey="status"><TableSortArrows/></SortControl></>,
                <>Action</>
              ]}
              LineItem={({ row }) => (
                <tr>
                  <td>{row.name}</td>
                  <td>{row.phone}</td>
                  <td>{row.email}</td>
                  <td>{row.employees}</td>
                  <td>{row.dateJoined.substr(0,10)}</td>
                  <td className={`status-buttons ${row.status === "ACTIVE" ? 'blue-status' : 'red-status'}`} style={{width:"7.84rem"}}>{row.status === "ACTIVE" ? 'Active' : 'Inactive'} </td>
                  <td>
                    <Row margin="0">
                      <MainButton type="empty" padding="1rem" color="#1C829C" onClick={() => onEditClick(row)}>
                        Edit <i className="fa-solid fa-pen-to-square"></i>
                      </MainButton>
                      <ButtonLoadWrapper spinnerSize="2.5rem" type="empty" padding="1rem" color="#D44D2F" SpinnerElement={LoaderThree} onClick={async () => await onManageClick(row._id)}>
                        Manage <i className="fa-solid fa-pen-to-square"></i>
                      </ButtonLoadWrapper>
                      &nbsp;&nbsp;&nbsp;
                      {row.status === "ACTIVE" && (
                        <ButtonLoadWrapper spinnerSize="2.5rem" type="empty" padding="1rem" color="#861724" SpinnerElement={LoaderThree} width={"9.08rem"} onClick={() => onStatusChangeClick(row._id, "INACTIVE")}>
                          Deactivate <i className="fa-solid fa-ban"></i>
                        </ButtonLoadWrapper>
                      )}
                      {row.status !== "ACTIVE" && (
                        <ButtonLoadWrapper spinnerSize="2.5rem" type="empty" padding="1rem" color="#027D6A" SpinnerElement={LoaderThree} width={"9.08rem"} onClick={() => onStatusChangeClick(row._id, "ACTIVE")}>
                          Activate <i className="fa-solid fa-check"></i>
                        </ButtonLoadWrapper>
                      )}
                    </Row>
                  </td>
                </tr>
              )}
            />
          </PaginationWrapper>
        </SortWrapper>
      </FilterWrapper>


      <RightPanelModal shouldShow={viewNewClientModal} onRequestClose={() => setViewNewClientModal(false)}>
        <h3>{modalType === "NEW" ? 'Add New Client' : 'Edit Client'}</h3>
        <Form onSubmit={onNewClientSubmit}>
          <h4>Company Info</h4>
          <GridRow col={2} margin='0'>
            <TextField name="name" label="Name" defaultValue={clientToEdit.name || ""}/>
            <FileInputWrapper className='file-input-button' name="logo" label="Logo" defaultValue={clientToEdit.logo || null} validation={logoValidation}><FileInput /></FileInputWrapper>
            {/* <FormGroup justify="center" margin="0">
              <FileInputWrapper name="logo" label="Logo" required={false}><MediaBrowseButton /></FileInputWrapper>
            </FormGroup> */}
          </GridRow>

          <GridRow col={2} margin='0'>
            <EmailField name="email" label="Email" defaultValue={clientToEdit.email || ""} />
            <TextField name="phone" label="Phone" defaultValue={clientToEdit.phone || ""} required={false} />
          </GridRow>

          <TextField name="address" label="Address" defaultValue={clientToEdit.address || ""} />

          <GridRow col={2}>
            <TextField name="city" label="City" defaultValue={clientToEdit.city || ""}/>
            <TextField name="zip" label="Zip Code" defaultValue={clientToEdit.zip || ""}/>
          </GridRow>

          <GridRow col={2}>
              
              {/* <SelectField name="country" label="Country" defaultValue={clientToEdit.country || null}>
                <DropdownSearch defaultValue={countryMap[clientToEdit.country] || null}>
                  <SearchItem value="CA" text="canada">Canada</SearchItem>
                  <SearchItem value="US" text="united-states">United-States</SearchItem>
                </DropdownSearch>
              </SelectField> */}

              <SelectField name="country" label={<label>Country</label>} defaultValue={clientToEdit.country || null}>
                <DropdownSelect width="100%" HeaderStyle="fill" defaultValue={clientToEdit.country || null} maxHeight="40vh">
                  <DefaultItem>
                    <span>Select country</span>
                  </DefaultItem>
                  {countries.map(country => <Item key={"country_"+country.code} value={country.code} searchKey={country.name}>{country.name}</Item>)}
                </DropdownSelect>
              </SelectField>
              <TextField name="vat" label="VAT" defaultValue={clientToEdit.vat || ""} required={false} />
          </GridRow>

          {/*<TextField name="reportLink" label={<>Report Link: <i>https://whistlaw.com/report/</i><strong>company-name</strong></>} defaultValue={clientToEdit.publicLink || ""} 
            validation={Joi.string().regex(/^[a-z0-9-]+$/).message("Report link can only have lower case letters, numbers and dashes").required().messages({ 'string.empty': 'Report link is required.' })}
            placeholder="company-name"
            />*/}

          {submitSpinner && <Loader />}
          {!submitSpinner && <SubmitWrapper><MainButton>Save user</MainButton></SubmitWrapper>}

        </Form>
        
      </RightPanelModal>
    </StyledClients>
  );
});
