import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MainButton } from '../../../../../gui/input/MainButton';
import { FlexContainer, MainContent, StyledCaseInfo, UnreadCount } from './Style';
import { StyledTabControl } from './TabControl';
import { TabControlCustomCase } from './TabControlCustomCase';
import { Container } from '../../../../../gui/layout/Container';
import { GridRow } from '../../../../../gui/layout/GridRow';
import { MainInfo } from './MainContent/MainInfo';
import { Description } from './MainContent/Description';
import { Media } from './MainContent/Media/';
import { Access } from './MainContent/Access';
import { Messages } from './MainContent/Messages';
import { Notes } from './MainContent/Notes';
import { History } from './MainContent/History';
import { loadCaseData } from './action';
import withParamsPageLoader from '../../../../../hoc/withParamsPageLoader';
import { Link, useNavigate } from 'react-router-dom';
import { exportCase } from './exportCase';
import { LoaderThree } from '../../../../../gui/elements/LoaderThree';
import jsPDF from 'jspdf';
import { Row } from '../../../../../gui/layout/Row';

export const CaseInfoComponent = ({ loadedPageData, fromToken=null }) => {
  const [unreadCount, setUnreadCount] = useState(loadedPageData.unreadCount)
  const [exportingState, setExportingState] = useState(false)

  const navigate = useNavigate()
  const authId = useSelector(state => state.auth._id)

  const onExportCaseClick = async () => {
    setExportingState(true)
    try
    {
      let htmlString = await exportCase(loadedPageData, authId, fromToken)
      // htmlString = htmlString.replace(/<h2/g, '<div style="page-break-before:always !important;"></div><h2')
      const pdf = new jsPDF({
        orientation: "p", 
        unit:"px",
        format:"letter", 
        hotfixes: ["px_scaling"]
      });
      await pdf.html(htmlString,{
        width: 810,
        windowWidth: 810,
        autoPaging: "text"
      })
      pdf.save("case_"+loadedPageData._id+".pdf");
    }
    catch(err)
    {
      alert(err.message)
      console.log(err)
    }
    setExportingState(false)
  }


  return (
    <StyledCaseInfo>
      <Container>
        <div className="page-header">
          <div>
            <h1>Case Info</h1>
            {!fromToken &&
            <div className="page-nav">
              <Link to="/all-cases" className="grey">
                All Cases
              </Link>{' '}
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 9L5 5L1 1" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              &nbsp;{loadedPageData.subject}&nbsp;/&nbsp;{loadedPageData._id}
            </div>
            }
          </div>

          {/*<MainButton onClick={()=>navigate(`/all-cases/export-case/${loadedPageData._id}`)}>Export Case</MainButton>*/}
          
          {!fromToken &&
            <>
            {exportingState && <Row width='fit-content' margin='0' align='center'><LoaderThree size="2rem"/>&nbsp;Exporting...</Row>}
            {!exportingState && <MainButton onClick={onExportCaseClick}>Export case</MainButton>}
            </>
          }
          
        </div>

        {!fromToken &&
          <TabControlCustomCase menuTabs={['Main Info', 'Description', 'Media', 'Access', <span>Messages {unreadCount > 0 && <UnreadCount>{unreadCount}</UnreadCount>}</span>, 'History']} defaultTabIndex={0}>
            <MainInfo caseData={loadedPageData} fromToken={fromToken}/>
            <Description caseData={loadedPageData} fromToken={fromToken}/>
            <Media caseData={loadedPageData} fromToken={fromToken}/>
            <Access caseId={loadedPageData._id}/>
            <Messages caseData={loadedPageData} setUnreadCount={setUnreadCount} fromToken={fromToken}/>
            <History fromToken={fromToken} caseId={loadedPageData._id}/>
          </TabControlCustomCase>
        }
        {fromToken &&
          <TabControlCustomCase menuTabs={['Main Info', 'Description', 'Media', <span>Messages {unreadCount > 0 && <UnreadCount>{unreadCount}</UnreadCount>}</span>, 'History']} defaultTabIndex={0}>
            <MainInfo caseData={loadedPageData} fromToken={fromToken}/>
            <Description caseData={loadedPageData} fromToken={fromToken}/>
            <Media caseData={loadedPageData} fromToken={fromToken}/>
            <Messages caseData={loadedPageData} setUnreadCount={setUnreadCount} fromToken={fromToken}/>
            <History fromToken={fromToken} caseId={loadedPageData._id}/>
          </TabControlCustomCase>
        }

      </Container>
    </StyledCaseInfo>
  );
}


export const CaseInfo = withParamsPageLoader(loadCaseData, CaseInfoComponent);
