const initialState = {
    image: null,
    loaded: false,
    managedImage: null
}

 const profileReducer = (state = initialState, {type, payload}) => {

    switch(type)
    {
        case "LOAD_IMAGE":
            return {
                ...state,
                image: payload.image,
                loaded: payload.loaded
            }
        case "LOAD_MANAGED_IMAGE":
            return {
                ...state,
                managedImage: payload.managedImage
            }
        case "REMOVE_MANAGED_IMAGE":
            return {
                ...state,
                managedImage: null
            }
        default:
            return state
    }
}

export default profileReducer