import styled from 'styled-components';
import { TabControl, TabControlHeader } from '../../../../../gui/layout/TabControl';

export const StyledTabControl = styled(TabControl)``;

export const StyledTabControlHeader = styled(TabControlHeader)`
  li {
    border-color: blue;
  }
`;
