import styled from 'styled-components';

export const StyledAllCases = styled.div`
  height: 100%;

  .page-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.25rem;

    a {
      width: fit-content;
      margin-left: auto;
      margin-bottom: auto;
    }
  }

  .buttons-group {
    display: flex;
    margin-left: auto;
    align-items: flex-start;
    margin-bottom: 0.5rem;

    > * {
      margin-left: 1rem;
    }
  }

  .dropdownButtonLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background: #f4f5f9;
    color: #3e4954;
    /* padding: 1.15rem 4rem; */
    padding: 2rem;
    width: 17rem;
    height: 5rem;
    border-radius: 9px;
    /* margin-right: 0.8rem; */
    font-size: 1.4rem;
    font-weight: bold;

    svg {
      width: 1.9rem;

      &.caret-down {
      }
    }
  }
`;

export const MyCasesToggle = styled.div`
  /* // you already have a done toggle... */
`;
