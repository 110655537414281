import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0;
  /* border: 2px solid grey; */
  /* height: 120px; */
  padding: 0 2rem;
  min-height: 12vh;

  select {
    width: 120px;
    border: none;
    background: #f3f4f6;
    color: #484848;
    padding: 1rem;
    border-radius: 12px;
  }

  .hello-name {
    color: #10407a;
    margin-left: 4rem;
  }

  .bold {
    font-weight: bold;
  }

  svg {
    margin: auto 1rem;
    width: 45px;
    height: 45px;

    /*&:nth-child(1) {
      margin-right: 4rem;
    }*/
  }

  hr {
    /* margin: 0.5rem auto; */
    width: 100%;
    border-color: #fafafa;
    border: 0.5px solid #f2f3f4;
    margin-top: 3px;
    /* border: none; */
  }
  ul {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    margin-left: auto;
    padding: 0 !important;

    li {
      list-style: none;
      margin: auto 2rem;
      border: 2px solid #fafafa;
      /* border-radius: 12px 12px 0 0; */
      border-radius: 5px;
      border-bottom: 2px solid transparent;
      width: 170px;
      padding: auto;
      text-align: center;
      font-weight: 500;
      letter-spacing: 1px;
      color: lightgrey;
      cursor: pointer;
      font-size: 1.3rem;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.03), 0 8px 8px rgba(0, 0, 0, 0.03);

      &.selected {
        color: ${(props) => props.theme.colors.secondary};
        border-bottom: 2px solid ${(props) => props.theme.colors.secondary};
      }

      &:hover {
        border-bottom: 2px solid ${(props) => props.theme.colors.secondary};
        color: ${(props) => props.theme.colors.secondary};
      }
    }
  }

  .notification-widget {
    position: relative;
    cursor: pointer;
    transform: translateY(3px);
    z-index: 1;

    .notification-count {
      background: #027d6a;
      position: absolute;
      right: 0.2rem;
      top: -0.8rem;
      border-radius: 50%;
      color: white;
      font-weight: bold;
      /* width: 26px;
    height: 26px; */
      padding: 4px;

      width: 23px;
      height: 23px;
      line-height: 14px;
      border-radius: 50%;
      text-align: center;
      font-size: 12px;
    }
  }

  .settings-widget {
    position: relative;
    cursor: pointer;
    transform: translateY(3px);
  }

  .profile-option-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6em;
    padding: 1.2rem 0.5rem;
    /* margin: 0.8rem 0rem; */
    cursor: pointer;
    border-radius: 9px;

    &:hover {
      background: ${(props) => props.theme.colors.primary};

      > * {
        color: white;
      }
    }

    button {
      cursor: pointer;
    }
  }

.profile-dropdown{
  cursor:pointer;
}

  .profile-dropdown-list {
    background: white;
  }
`;
