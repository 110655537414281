import React, { useState, useEffect, useMemo } from 'react';
import { SideNavigationMenu } from '../../../../../App/theme/SideNavigationMenu';
import { AppMainLayout } from '../../../../gui/layout/AppMainLayout';
import { IconNewCases, IconOpenCases, IconClosedCases, IconTotalCases } from './icons';
import { StyledDashboard } from './Style';
import { H1 } from '../../../../gui/elements/H1';
import { H2 } from '../../../../gui/elements/H2';
import { StatsCard } from './StatsCard';
import { Row } from '../../../../gui/layout/Row';
import { MainButton } from '../../../../gui/input/MainButton';
import { Notifications } from './Notifications';
import * as htmlToImage from 'html-to-image';

import Toggle from '../../../../gui/input/Toggle';
import { ToggleOption } from '../../../../gui/input/ToggleOption';
import { Select } from '../../../../gui/input/Select';
import { RightPanelModal } from '../../../../gui/layout/RightPanelModal';
import { LatestActivity, StyledList } from './LatestActivity';
import { DatePicker, DatePickerCustom } from './DatePicker';
import { DatePickerNew } from './DatePickerNew';
import { GridRow } from '../../../../gui/layout/GridRow';
import { ChartCard } from './ChartCard';
import { SumOfCases } from './Charts/SumOfCases';
import { CSVDownload, CSVLink } from 'react-csv';
import { csvTestData } from './csvTestData.js';
import { DateRangePicker } from '../../../../gui/input/date/DateRangePicker';
import { Dropdown, Header, List } from '../../../../gui/input/Select/Dropdown';
import { DropdownItem } from '../../../../gui/input/Select/Style';
//import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import withActionPageLoader from '../../../../hoc/withActionPageLoader';
import {getDashboardData} from './actions.js'

// by default dashboard should show current year

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

const buildLatestActivityTime = (date) => {
  let d = new Date(date)
  return `${d.getDate()} ${months[d.getMonth()]}, ${d.getHours()}:${d.getMinutes().toString().padStart(2,"0")} ${d.getHours() >= 12 ? "PM" : "AM"}`
}

export const Dashboard = withActionPageLoader(getDashboardData,({loadedPageData}) => {
  const [ui, setUi] = useState({ latestActivityView: false, datePickerView: false });

  const chartData = useMemo(() => {

    let sumOfCasesChart = {}
    let orderdSumOfCasesChart = []
    let statusMap = {"UNASSIGNED": loadedPageData.UNASSIGNED, "ASSIGNED": loadedPageData.ASSIGNED, "CLOSED": loadedPageData.CLOSED, "TOTAL": loadedPageData.TOTAL}

    let statsByMonth = loadedPageData.statsByMonth
    let now = new Date()
    let year = now.getFullYear()
    let month = now.getMonth()-11
    if(month < 0)
    {
      month += 12;
      year--;
    }
    let runningTotal = loadedPageData.oldTotal
    let monthCode = "";

    for(let i=0;i<statsByMonth.length; i++)
    {
      sumOfCasesChart[statsByMonth[i]._id] = statsByMonth[i].total
    }
    for(let i=0; i<12; i++)
    {
      monthCode = year+"-"+month.toString().padStart(2,"0")
      runningTotal += (sumOfCasesChart[monthCode] ? sumOfCasesChart[monthCode] : 0)
      orderdSumOfCasesChart.push({month: months[month], total: runningTotal})

      month++
      if(month >= 12)
      {
        month = 0;
        year++;
      }
    }

    let reportCasesByStatus = loadedPageData.reportCasesByStatus

    return {sumOfCases: orderdSumOfCasesChart, statusMap}

  }, [loadedPageData])

  /*const downloadPDF = () => {
    htmlToImage.toPng(document.getElementById('chart_container')).then(function (blob) {
      const doc = new jsPDF()
      doc.addImage(blob,"PNG",0,0)
      doc.save("dashboard.pdf");
      //saveAs(blob, 'dashboard.png');
    });
  }*/

  const downloadPDF = () => {
    htmlToImage.toCanvas(document.getElementById('chart_container')).then(function (canvas) {
      let fraction = 210.0 / canvas.width;
      const doc = new jsPDF();
      doc.addImage(canvas, 'JPEG', 0, 0, canvas.width * fraction, canvas.height * fraction, 'FAST');
      doc.save('dashboard.pdf');
    });
  };


    return (
      <StyledDashboard id="chart_container">
        <H1>Dashboard</H1>
        <Row>
          <StatsCard content={{ number: chartData.statusMap.UNASSIGNED, text: 'Unassigned Cases' }}>
            <IconNewCases />
          </StatsCard>
          <StatsCard content={{ number: chartData.statusMap.ASSIGNED, text: 'Assigned Cases' }}>
            <IconOpenCases />
          </StatsCard>
          <StatsCard content={{ number: chartData.statusMap.CLOSED, text: 'Closed Cases' }}>
            <IconClosedCases />
          </StatsCard>
          <StatsCard content={{ number: chartData.statusMap.TOTAL, text: 'Total Cases' }}>
            <IconTotalCases />
          </StatsCard>
        </Row>
        {/* <Notifications /> */}

        <GridRow colTemplate="5fr 2fr">
          <ChartCard>
            <h3>Sum of cases</h3>
            <SumOfCases data={chartData.sumOfCases ? chartData.sumOfCases : []}/>
          </ChartCard>
          <ChartCard style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', height: 'auto', paddingBottom: '0' }}>
            <Row align="flex-start" justify="space-between" width="-webkit-fill-available" margin="0">
              <h3>Latest Activity</h3>
              {loadedPageData.recentActivity && loadedPageData.recentActivity.length > 4 && <button style={{ marginTop: '.8rem', marginLeft: 'auto', marginRight: 0 }} className="blue bold" onClick={() => setUi({ ...ui, latestActivityView: true })}>
                View more
              </button>}
            </Row>
            <StyledList>
              {loadedPageData.recentActivity[0] &&
                <div className="li-wrapper">
                  <div className="circle"></div>
                  <p className="main-item">{loadedPageData.recentActivity[0].text}</p>
                  <p className="timestamp">{buildLatestActivityTime(loadedPageData.recentActivity[0].dateTime)}</p>
                </div>
              }
              {loadedPageData.recentActivity[1] &&
                <div className="li-wrapper">
                  <div className="circle"></div>
                  <p className="main-item">{loadedPageData.recentActivity[1].text}</p>
                  <p className="timestamp">{buildLatestActivityTime(loadedPageData.recentActivity[1].dateTime)}</p>
                </div>
              }
              {loadedPageData.recentActivity[2] &&
                <div className="li-wrapper">
                  <div className="circle"></div>
                  <p className="main-item">{loadedPageData.recentActivity[2].text}</p>
                  <p className="timestamp">{buildLatestActivityTime(loadedPageData.recentActivity[2].dateTime)}</p>
                </div>
              }
              {loadedPageData.recentActivity[3] &&
                <div className="li-wrapper">
                  <div className="circle"></div>
                  <p className="main-item">{loadedPageData.recentActivity[3].text}</p>
                  <p className="timestamp">{buildLatestActivityTime(loadedPageData.recentActivity[3].dateTime)}</p>
                </div>
              }
            </StyledList>
          </ChartCard>
        </GridRow>

        {/* <div>
          <GridRow colTemplate={'4fr 1fr'}>
            <ChartCard>
              <h3>Active Cases</h3>
              <Cases />
            </ChartCard>
            <ChartCard>
              <h3>Sources</h3>
              <Sources2 />
            </ChartCard>
          </GridRow>
          <GridRow colTemplate={'1fr 5fr'}>
            <ChartCard>
              <h3>Closing code</h3>
              <ClosingCode />
            </ChartCard>
            <ChartCard>
              <h3>Sum of cases</h3>
              <SumOfCases />
            </ChartCard>
          </GridRow>
          <GridRow colTemplate={'5fr 1fr 3fr'}>
            <ChartCard>
              <h3>Amount of cases</h3>
              <AmountOfCases />
            </ChartCard>
            <ChartCard>
              <h3>Privacy breakdown</h3>
              <PrivacyBreakdown />
            </ChartCard>
            <ChartCard style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', height: 'auto', paddingBottom: '0' }}>
              <Row align="flex-start" justify="space-between" width="-webkit-fill-available" margin="0">
                <h3>Latest Activity</h3>
                <button style={{ marginTop: '.8rem', marginLeft: 'auto', marginRight: 0 }} className="blue bold" onClick={() => setUi({ ...ui, latestActivityView: true })}>
                  View more
                </button>
              </Row>
              <StyledList>
                <div className="li-wrapper">
                  <div className="circle"></div>
                  <p className="main-item">Lorem ipsum dolor sit amet, consectetuer </p>
                  <p className="timestamp">2 March 2021, 13:45 PM</p>
                </div>
                <div className="li-wrapper">
                  <div className="circle"></div>
                  <p className="main-item">Lorem ipsum dolor sit amet</p>
                  <p className="timestamp">2 March 2021, 13:45 PM</p>
                </div>
                <div className="li-wrapper">
                  <div className="circle"></div>
                  <p className="main-item">consectetuer adipiscing elit. </p>
                  <p className="timestamp">2 March 2021, 13:45 PM</p>
                </div>
                <div className="li-wrapper">
                  <div className="circle"></div>
                  <p className="main-item">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do </p>
                  <p className="timestamp">2 March 2021, 13:45 PM</p>
                </div>
              </StyledList>
            </ChartCard>
          </GridRow>
        </div> */}

        <div className="charts">
          {/* <Row> */}
          {/* <CasesChart />
              </Row>
              <SourcesChart />
              <ClosingCode />
              <SumOfCases />
              <AmountOfCases />
              <PrivacyBreakdown />
              <LatestActivity /> */}
        </div>
        <RightPanelModal shouldShow={ui.latestActivityView} onRequestClose={() => setUi({ ...ui, latestActivityView: false })}>
          <LatestActivity data={loadedPageData.recentActivity} buildLatestActivityTime={buildLatestActivityTime}/>
        </RightPanelModal>
      </StyledDashboard>
    );

});
