import React from 'react';

import { Chart, Legend, Series, Size, Tooltip } from 'devextreme-react/chart';
import styled from 'styled-components';

const StyledChart = styled.div`
  width: 100%;
  > div {
    width: 100%;
  }

  .dxc-series rect {
    fill: url(#sum-of-cases-bar-chart-gradient) #fff;
  }

  .dxc-val-grid {
    path {
      stroke-dasharray: 5, 5;
    }
  }

  .dxc-axes-group {
    display: none;
  }
`;

const dataSource = [
  {
    day: 'January',
    oranges: 3,
  },
  {
    day: 'February',
    oranges: 2,
  },
  {
    day: 'March',
    oranges: 3,
  },
  {
    day: 'April',
    oranges: 4,
  },
  {
    day: 'May',
    oranges: 6,
  },
  {
    day: 'June',
    oranges: 11,
  },
  {
    day: 'July',
    oranges: 3,
  },
  {
    day: 'August',
    oranges: 7,
  },
  {
    day: 'September',
    oranges: 4,
  },
  {
    day: 'October',
    oranges: 5,
  },
  {
    day: 'November',
    oranges: 6,
  },
  {
    day: 'December',
    oranges: 2,
  },
];

export const SumOfCases = () => {
  return (
    <StyledChart>
      <Chart id="chart" dataSource={dataSource}>
        <Size height={300} />
        <Series valueField="oranges" argumentField="day" name="" type="bar" color="#418eeb" barWidth={20} cornerRadius={10} />
        <Legend verticalAlignment="top" horizontalAlignment="" visible={false} />
        <Tooltip enabled={true} />
      </Chart>

      <svg style={{ position: 'absolute' }}>
        <defs>
          <linearGradient id="sum-of-cases-bar-chart-gradient" x2="0" y2="1">
            <stop offset="0%" stop-color="#408FEA" />
            <stop offset="100%" stop-color="#01A389" />
          </linearGradient>
        </defs>
      </svg>
    </StyledChart>
  );
};
