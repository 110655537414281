import api from "../../../../../utils/api";

export const getCategories = async() => {
    const {data} = await api.get('categories/statistics')
    return data
}

export const changeCategoryStatus = async (_id, isActive) => {
    const {data} = await api.patch('categories/status',{_id, isActive})
    return data
}

export const makeNewCategory = async (values) => {
    const {data} = await api.post('/categories',values)
    return data
}

export const getAccessRights = async ({id}) => {
    const {data} = await api.get('/categories/'+id+'/handlers')
    return data
}
export const updateAccessStatus = async ({categoryId, accessId, accessCode, accountId}) => {
    const {data} = await api.patch('/categories/handlers/access', {categoryId, accessId, accessCode, accountId})
    return data
}