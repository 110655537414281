const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const formatDate = (inputDate) => {
  let date = new Date(inputDate);
  let half = 'AM';
  let hours = date.getHours();
  if (hours === 0) {
    hours = 12;
  } else if (hours === 12) {
    half = 'PM';
  } else if (hours > 12) {
    hours -= 12;
    half = 'PM';
  }

  return date.getDate().toString().padStart(2, '0') + ' ' + months[date.getMonth()] + ' ' + date.getFullYear() + ', ' + hours.toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') + ' ' + half;
};
