const initialState = {
    openedReportCases: 0
}

 const dashboardReducer = (state = initialState, {type, payload}) => {

    switch(type)
    {
        case "OPENED_CASES":
            return {
                ...state,
                ...payload
            }
        case "ADD_CASES":
            return {
                ...state,
                openedReportCases: state.openedReportCases+payload.add
            }
        default:
            return state
    }
}

export default dashboardReducer