import 'react-dates/initialize';
//import 'react-dates/lib/css/_datepicker.css';

import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import GlobalStyle from '../App/theme/global-styles';

import HomePage from '../components/containers/HomePage/Loadable';
import LoginPage from '../components/containers/LoginPage/Loadable';
import { Header } from '../App/theme/Header';
// import PrivateRoute from '../utils/routing/PrivateRoute';
import Chat from '../components/containers/UserPages/CommonToAllPages/Chat';

import setAuthToken from '../utils/setAuthToken';
import { loadUser } from './actions';
import { PrivateRoute } from './PrivateRoute';

import { Body } from '../App/theme/Body';
import { Dashboard } from '../components/containers/UserPages/CommonToAllPages/Dashboard';
import { Dashboard as Dashboard2 } from '../components/containers/UserPages/EmployeeReporting/Dashboard';
import { Dashboard as Dashboard3 } from '../components/containers/UserPages/WhistlawUser/Dashboard';
import { AllCases } from '../components/containers/UserPages/CommonToAllPages/AllCases';
import { ResetStep1 } from '../components/containers/LoginPage/ResetPassword/ResetStep1';
import { ResetStep3 } from '../components/containers/LoginPage/ResetPassword/ResetStep3';
import { EmployeeReporting } from '../components/containers/UserPages/EmployeeReporting';
import { CaseInfo } from '../components/containers/UserPages/CommonToAllPages/AllCases/CaseInfo';
import { AppMainLayout } from '../components/gui/layout/AppMainLayout';
import { Sandbox } from '../components/gui/Sandbox';
import { Categories } from '../components/containers/UserPages/CommonToAllPages/Categories';
import { Departments } from '../components/containers/UserPages/CommonToAllPages/Departments';
import { UserManagement } from '../components/containers/UserPages/CommonToAllPages/UserManagement';
import { Settings } from '../components/containers/UserPages/CommonToAllPages/Settings';
import { Subscription } from '../components/containers/UserPages/CommonToAllPages/Subscription';
import { Success as SubscriptionSuccess, Failed as SubscriptionFailed } from '../components/containers/UserPages/CommonToAllPages/Subscription/Success';
import { Profile } from '../components/containers/UserPages/CommonToAllPages/Profile';
import { Clients } from '../components/containers/UserPages/CommonToAllPages/Clients';
import { Invoice } from '../components/containers/UserPages/CommonToAllPages/Invoice';
import { ExportCase } from '../components/containers/UserPages/CommonToAllPages/AllCases/ExportCase';
import { Step4 } from '../components/containers/UserPages/EmployeeReporting/Step4'
import { DashboardSelector } from '../components/containers/UserPages/CommonToAllPages/Dashboard/DashboardSelector';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      dispatch(loadUser());
    } else {
      dispatch({ type: 'LOGOUT' });
    }
  }, []);

  return (
    <Router>
      <GlobalStyle />

      {/* <Header layout={{ logo: [{ image: '/logo192.png', height: '75px' }, { text: '' }] }} /> */}
      {/* <Header /> */}
      {/* <Body> */}

      <Routes>
        {/* <Route exact path='/login' element={<LoginPage />} />
          <Route exact path='/forgot-password' element={<ResetStep1 />} />
          <Route exact path='/reset-password/:jwt' element={<ResetStep3 />} /> */}

        <Route exact path="/" element={<PrivateRoute><DashboardSelector/></PrivateRoute>} />

        <Route
          exact
          path="/dashboard-1"
          element={
            <PrivateRoute>
              <Dashboard role={'companyUser'} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/dashboard-2"
          element={
            <PrivateRoute>
              <Dashboard2 role={'companyUser'} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/dashboard-3"
          element={
            <PrivateRoute>
              <Dashboard3 role={'companyUser'} />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/all-cases"
          element={
            <PrivateRoute accountType={["ADMIN","CASE_HANDLER"]}>
              <AllCases />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/all-cases/:caseId"
          element={
            <PrivateRoute accountType={["ADMIN","CASE_HANDLER"]}>
              <CaseInfo />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/departments"
          element={
            <PrivateRoute accountType="ADMIN">
              <Departments />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/invoice"
          element={
            <PrivateRoute accountType="ADMIN">
              <Invoice />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/all-cases/export-case/:caseId"
          element={
            <PrivateRoute accountType="ADMIN">
              <ExportCase />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/categories"
          element={
            <PrivateRoute accountType="ADMIN">
              <Categories />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/user-management"
          element={
            <PrivateRoute accountType="ADMIN">
              <UserManagement />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/subscription"
          element={
            <PrivateRoute accountType="ADMIN">
              <Subscription />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/subscription/success"
          element={
            <PrivateRoute accountType="ADMIN">
              <SubscriptionSuccess />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/subscription/failed"
          element={
            <PrivateRoute accountType="ADMIN">
              <SubscriptionFailed />
            </PrivateRoute>
          }
        />

        <Route exact path="/chat" element={<Chat />} />


        <Route exact path="/report/:publicLink" element={<EmployeeReporting />} />
        <Route exact path="/report" element={<Step4 />} />

        <Route
          exact
          path="/settings"
          element={
            <PrivateRoute accountType="ADMIN">
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <PrivateRoute accountType={["ADMIN","CASE_HANDLER","SUPER_ADMIN"]}>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/clients"
          element={
            <PrivateRoute accountType="SUPER_ADMIN">
              <Clients />
            </PrivateRoute>
          }
        />

        <Route exact path="/sandbox" element={<Sandbox />} />
      </Routes>

      <Routes>
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/forgot-password" element={<ResetStep1 />} />
        <Route exact path="/reset-password/:jwt" element={<ResetStep3 />} />
      </Routes>
      {/* </Body> */}
    </Router>
  );
};

export default App;
