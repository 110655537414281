import api from "../../../../../utils/api"

export const createNewCase = async (values) => {

    /*
    const formData = new FormData();
    formData.append('mediaFile', mediaFile);
    const {data} = await api.post("/report-cases/media/"+caseId, formData, {})
    */
    const formData = new FormData();
    formData.append('anonymity', values.anonymity);
    formData.append('breachType', values.breachType);
    formData.append("breachCategory", values.breachCategory);
    formData.append('dateOfBreach', values.dateOfBreach);
    formData.append('sourceCode', values.sourceCode);
    for(let i=0;i<values.files?.length;i++)
    {
        formData.append('files', values.files[i]);
    }
    formData.append('followupActions', values.followupActions || null);
    formData.append('justification', values.justification || null);
    formData.append('otherBreachType', values.otherBreachType || null);
    formData.append('placeOfBreach', values.placeOfBreach || null);
    formData.append('timeOfBreach', values.timeOfBreach);
    formData.append('description', values.description);

    const {data} = await api.post('/report-cases', formData, {})
    return data.insertedId
}