import styled from 'styled-components/macro';

export const StyledSideNavigationMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
  box-shadow: 18px 4px 35px -2px rgba(0, 0, 0, 0.02);
  z-index: 2;
  /* clip-path: inset(0px -15px 0px 0px); */

  svg.logo {
    /* margin: 3rem auto; */
    min-height: 12vh;
  }

  svg {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .menu-border-left {
    position: absolute;
    left: 0;
    margin-left: 0;
    transform: scale(0.9);
  }

  ul {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;

    li,
    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: auto;
      width: 100%;
      padding: 2rem;
      font-weight: bold;
      color: #858585;
      cursor: pointer;
      text-decoration: none;

      &.selected {
        background: #fafbff;
        color: ${(props) => props.theme.colors.primary};
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.04);

        svg.icon path {
          /* fill: none; */
          fill: ${(props) => props.theme.colors.primary};
          /* stroke: ${(props) => props.theme.colors.primary} !important; */
        }

        &:nth-of-type(4) {
          svg.icon {
            fill: none !important;
            stroke: ${(props) => props.theme.colors.primary} !important;
          }
        }

        &:nth-of-type(6) {
          svg.icon path {
            fill: none !important;
            stroke: ${(props) => props.theme.colors.primary} !important;
          }
        }

        &:last-of-type {
          svg.icon path {
            fill: none !important;
            stroke: ${(props) => props.theme.colors.primary} !important;
          }
        }
      }

      .notification-count {
        background: ${(props) => props.theme.colors.primary};
        color: white;
        padding: 0.4rem 0.6rem;
        border-radius: 5px;
        font-weight: bold;
        margin-left: 1rem;
      }
    }
  }
`;
