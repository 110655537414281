export const themeSettings = () => ({
  colors: {
    /*body:"rgb(28, 28, 30)",
        lightBody:"#2c2c2d",
        text:"rgb(199,140,101)",
        fadedText:"#67666a",
        highlight:"rgb(239,198,55)",
        bright:"#FFFFFF",
        underline:"rgb(7,38,117)",
        error:"rgb(231,7,8)",
        success:"rgb(77,161,74)"*/

    primary: '#418EEB',
    secondary: '#08a2e5',
    tertiary: '#2c2c2d',

    body: '#1C1C1E',
    light: '#E5E5E5',
    //text:"#FFE07D",
    text: '#F78154',
    fadedText: '#67666a',
    //highlight:"#F78154",
    highlight: '#FFE07D',
    bright: '#F8C7CC',
    underline: '#247BA0',
    error: '#DA2C38',
    success: '#B3E59F',
  },
  transitionSpeed: '400ms',
});
