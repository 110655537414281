import React, { useRef, useState } from 'react';
import { PaginationWrapper } from '../../../../gui/controls/PaginationWrapper';
import { Table } from '../../../../gui/layout/Table';
import { StyledUserManagement } from './Style';
import { Pagination as PaginationControl } from '../../../../gui/layout/Pagination';
import { Row } from '../../../../gui/layout/Row';
import { MainButton } from '../../../../gui/input/MainButton';
import { SearchBar } from '../../../../gui/input/SearchBar';
import { Select } from '../../../../gui/input/Select';
import { RightPanelModal } from '../../../../gui/layout/RightPanelModal';
import { FormGroup } from '../../../../gui/input/Form(old)/FormGroup';
import styled from 'styled-components';
import { ToggleSwitchWithText } from '../../../../gui/input/ToggleSwitchWithText';
import { TextInput } from '../../../../gui/input/TextInput';
import withActionPageLoader from '../../../../hoc/withActionPageLoader';
import { formatDate } from '../../../../../utils/dataManipulation/formatDate';
import { ButtonLoadWrapper } from '../../../../gui/input/Button/ButtonLoadWrapper';

import { getUserManagementData, changeUserStatus } from './actions';
import { H1 } from '../../../../gui/elements/H1';
import { DefaultItem, DropdownSelect, Item } from '../../../../gui/input/Select/DropdownSelect';
import { statusIcon } from '../../../../../App/theme/icons';
import { AddNewUser } from './AddNewUser';
import { SortWrapper, SortControl } from '../../../../gui/controls/SortWrapper';
import { TableSortArrows } from '../../../../gui/layout/Table/TableSortArrows';
import { FilterWrapper } from '../../../../gui/controls/FilterWrapper';
import { GridRow } from '../../../../gui/input/Form';

const roleMap = {
  "ADMIN": "Admin",
  "CASE_HANDLER": "Case Handler",
  "WHISTLEBLOWER": "Whistleblower"
}

export const UserManagement = withActionPageLoader(getUserManagementData, ({ loadedPageData, reloadPageData }) => {
  const [pageData, setPageData] = useState(loadedPageData);
  const [viewNewUserModal, setViewNewUserModal] = useState(false);
  const [Status, setStatus] = useState(false);
  const [filters, setFilters] = useState({ isActive: null, email: () => true });
  const selectedUser = useRef(null)

  const onStatusChangeClick = async (_id, isActive) => {
    try {
      await changeUserStatus(_id, isActive);

      for (let i = 0; i < pageData.length; i++) {
        if (pageData[i]._id === _id) {
          //await new Promise((resolve) => setTimeout(resolve, 1500))
          let newPageData = [...pageData];
          newPageData[i].isActive = isActive;
          setPageData(newPageData);
          break;
        }
      }
    } catch (err) {
      console.log(err);
      alert('failed to update status');
    }
  };

  const onStatusChanged = (newStatus) => {
    setFilters((prevState) => ({ ...prevState, isActive: newStatus }));
  };

  const onSearchChange = (searchFilter) => {
    setFilters((prevState) => {
      let newFilterFunc = null;
      searchFilter = searchFilter.trim();

      if (searchFilter.length === 0) {
        newFilterFunc = (value) => true;
      } else {
        newFilterFunc = (value) => value.email.toLowerCase().startsWith(searchFilter);
      }

      return { ...prevState, email: newFilterFunc };
    });
  };

  const onNewUserClick = () => {
    selectedUser.current = null;
    setViewNewUserModal(true)
  }

  const edituser = (user) => {
    selectedUser.current = user;
    setViewNewUserModal(true)
  }

  return (
    <StyledUserManagement>
      <Row justify="space-between">
        <H1>Users</H1>
        <MainButton onClick={onNewUserClick}>+ New user</MainButton>
      </Row>
      <Row justify="space-between" margin="2rem 0">
        <SearchBar placeholder="Search email..." onChange={onSearchChange}/>
        <DropdownSelect width="180px" type="all-status" HeaderStyle="fill" defaultValue={null} onChange={onStatusChanged}>
          <DefaultItem>{statusIcon}&nbsp;All Status</DefaultItem>
          <Item value={null}>{statusIcon}&nbsp;All Status</Item>
          <Item value={true}>{statusIcon}&nbsp;Active</Item>
          <Item value={false}>{statusIcon}&nbsp;Inactive</Item>
        </DropdownSelect>
      </Row>

      <FilterWrapper data={pageData} filters={filters}>
        <SortWrapper>
          <PaginationWrapper PaginationControl={PaginationControl}>
            <Table
              colCustomSize="3fr 3fr 3fr 5fr 3fr 3fr 3fr 5fr"
              colHeaders={[
                <>First Name<SortControl sortKey="firstName"><TableSortArrows /></SortControl></>, 
                <>Last Name<SortControl sortKey="lastName"><TableSortArrows /></SortControl></>, 
                // <>Title<SortControl sortKey="title"><TableSortArrows /></SortControl></>, 
                <>Phone<SortControl sortKey="phone"><TableSortArrows /></SortControl></>, 
                <>Email<SortControl sortKey="email"><TableSortArrows /></SortControl></>, 
                <>Department<SortControl sortKey="department"><TableSortArrows /></SortControl></>, 
                <>Role<SortControl sortKey="role"><TableSortArrows /></SortControl></>, 
                <>Status<SortControl sortKey="isActive"><TableSortArrows /></SortControl></>, 
                <>Action</>
              ]}
              LineItem={({ row }) => (
                <tr>
                  <td>{row.firstName}</td>
                  <td>{row.lastName}</td>
                  {/* <td>{row.title}</td> */}
                  <td>{row.phone}</td>
                  <td>{row.email}</td>
                  <td>{row.department}</td>
                  <td>{roleMap[row.role]}</td>
                  <td className={`status-buttons ${row.isActive ? 'blue-status' : 'red-status'}`} style={{width:"7.84rem"}}>{row.isActive ? 'Active' : 'Inactive'} </td>
                  <td>
                    <GridRow col='2' margin="0" gridGap='0'>
                      <MainButton width="70px" type="empty" padding="1rem" color="#D44D2F" onClick={() => edituser(row)}>
                        Edit <i className="fa-solid fa-pen-to-square"></i>
                      </MainButton>
                      {row.isActive && (
                        <ButtonLoadWrapper width="100px" spinnerSize="2.5rem" type="empty" padding="1rem" color="#861724" onClick={() => onStatusChangeClick(row._id, false)}>
                          Deactivate <i className="fa-solid fa-ban"></i>
                        </ButtonLoadWrapper>
                      )}
                      {!row.isActive && (
                        <ButtonLoadWrapper width="100px" spinnerSize="2.5rem" type="empty" padding="1rem" color="#027D6A" onClick={() => onStatusChangeClick(row._id, true)}>
                          Activate <i className="fa-solid fa-check"></i>
                        </ButtonLoadWrapper>
                      )}
                    </GridRow>
                  </td>
                </tr>
              )}
            />
          </PaginationWrapper>
        </SortWrapper>
      </FilterWrapper>
      <RightPanelModal shouldShow={viewNewUserModal} onRequestClose={() => setViewNewUserModal(false)}>
        <AddNewUser reloadUserPage={reloadPageData} userData={selectedUser.current}/>
      </RightPanelModal>
    </StyledUserManagement>
  );
});
