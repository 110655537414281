import { Size } from 'devextreme-react/chart';
import PieChart, { Legend, Series, Tooltip, Format, Label, Connector, Export } from 'devextreme-react/pie-chart';
import { useMemo } from 'react';

/*const data = [
  { source: 'Received', value: '90' },
  { source: 'Rejected', value: '10' },
];*/

export const ClosingCode = ({data}) => {

  const chartData = useMemo(() => {
    let total = data["Received"] + data["Rejected"]
    if(total === 0)
    {
      return [
        { source: 'Received', value: 0 },
        { source: 'Rejected', value: 0 }
      ]
    }

    return [
      { source: 'Received', value: Math.round(100.0*data["Received"]/total) },
      { source: 'Rejected', value: Math.round(100.0*data["Rejected"]/total) }
    ]
  }, [data])

  return (
    <PieChart id="pie" type="doughnut" palette={['#418eeb', '#ff6d4c']} dataSource={chartData}>
      <Size width={300} height={300} />
      <Series argumentField="source" valueField="value" innerRadius={0.78}>
        <Label visible={true} customizeText={(data) => data.value + '%'}>
          <Connector visible={true} />
        </Label>
      </Series>
      <Export enabled={false} />
      <Legend margin={0} horizontalAlignment="center" verticalAlignment="bottom" />
      {/*<Tooltip enabled={true} customizeTooltip={this.customizeTooltip}>
            <Format type="percent" />
            </Tooltip>*/}
    </PieChart>
  );
};
