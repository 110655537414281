import React from 'react';
import styled from 'styled-components/macro';
import { ProgressSteps } from './ProgressSteps';
import { StyledLatestActivity } from './Style';

export const StyledList = styled.div`
  min-width: 900px;
  min-width: fit-content;
  font-size: 80%;

  .li-wrapper {
    position: relative;
    border-left: solid 2px #dddddd;
    padding-left: 1.6rem;
    padding-bottom: 3rem;

    &:last-of-type {
      border-left: none;
      padding-bottom: 0;
    }

    &:first-of-type {
      .circle {
        background: #418eeb;
      }

      p.main-item {
        font-weight: bold;
      }
    }
  }

  .li-last {
    position: relative;
    padding-left: 2rem;
  }

  .circle {
    position: absolute;
    left: -6.5px;
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background: white;
    border: solid 2px #418eeb;
    border-radius: 50%;
  }

  .circle-last {
    left: -6px;
  }

  .myborder {
    display: inline-block;
    width: 100px;
    height: 100px;
    background: red;
  }

  p {
    margin: 0;
    transform: translateY(-4px);

    &.timestamp {
      color: #a5a5a5;
      /* font-size: 1rem; */
    }
  }
`;

export const LatestActivity = () => {
  return (
    <StyledLatestActivity>
      <h2>Latest Activity</h2>
      <StyledList>
        <div className="li-wrapper">
          <div className="circle"></div>
          <p className="main-item">Lorem ipsum dolor sit amet, consectetuer</p>
          <p className="timestamp">2 March 2021, 13:45 PM</p>
        </div>
        <div className="li-wrapper">
          <div className="circle"></div>
          <p className="main-item">Lorem ipsum dolor sit amet</p>
          <p className="timestamp">2 March 2021, 13:45 PM</p>
        </div>
        <div className="li-wrapper">
          <div className="circle"></div>
          <p className="main-item">consectetuer adipiscing elit.</p>
          <p className="timestamp">2 March 2021, 13:45 PM</p>
        </div>
        <div className="li-wrapper">
          <div className="circle"></div>
          <p className="main-item">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
          <p className="timestamp">2 March 2021, 13:45 PM</p>
        </div>
        <div className="li-last">
          <div className="circle circle-last"></div>
          <p className="main-item">Lorem ipsum dolor sit amet</p>
          <p className="timestamp">2 March 2021, 13:45 PM</p>
        </div>
      </StyledList>

      {/*<ProgressSteps/>*/}
    </StyledLatestActivity>
  );
};
